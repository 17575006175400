import styled from "styled-components";

export const CardTopContainer=styled.div`
    display: flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    min-width: 100%;
    padding-bottom: 16px;

    border-bottom:  1px solid var(--grey-color-40);

    span{
        width: 100%;
    }
    
    .date-text{
        min-width: 130px;
        max-width: 130px;
    }

    .recognitions-text{
        min-width: 312px;
        max-width: 312px;
    }

    .role-text{
        min-width: 265px;
        max-width: 265px;
    }

    .wallet-text{
        min-width: 150px;
        max-width: 150px;
    }

    .manager-text{
        min-width: 200px;
        max-width: 200px;
    }

`