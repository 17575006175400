import { UpdateCardComponent } from '../../../components/updateCard';
import * as S from './history-pending.styled';
import { HistoryPendingType } from './history-pending.type';

export const HistoryPaddingTab = ({ recognitions, updateRecognitions }: HistoryPendingType) => {
    return (
        <S.HistoryPendingContainer>
            {recognitions?.length === 0 ?
                <span className='c3-regular empty-text'>Nenhuma recompensa pendente</span> :
                <>
                    {recognitions?.map((recognition) => (
                        <UpdateCardComponent width={338} recognition={recognition} updateRecognitions={() => updateRecognitions()} />
                    ))}
                </>

            }

        </S.HistoryPendingContainer>
    );

}