import styled from "styled-components";

export const ConfigContainer = styled.div`

    display: flex;

    justify-content:start;
    align-items:center;



`

export const SectionContainer = styled.div`

display:flex;
margin-top:24px;
flex-direction:column;
gap:32px;



`
export const Line = styled.div`
width: 611px;
height: 1px;
background: var(--grey-color-40);


`


export const SectionItems = styled.div`
display:flex;
padding:16px;
border-radius: 4px;
background: var(--minimal-color);
width: 643px;
flex-direction:column;

`

export const SectorHeader = styled.div`
display: flex;
flex-direction:row;
align-items:center;

gap:480px;


.manager{
    gap:175px;
}

& p{
    width:193px;
}



`




export const ConfigItems= styled.div`

display:flex;
flex-direction:column;
margin-top:16px;



`

export const Button = styled.div`

display:flex;
justify-content:end;
margin-top:16px;


`
