import styled from "styled-components";

export const EmployeeListContainer=styled.div`
    display: flex;
    margin-top:16px;
    width: 100%;
    min-height: 200px !important;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;
    border-radius: 4px;
    background-color: var(--minimal-color);
    overflow: scroll;

    .pagination-container{
        margin-left: auto;
    }

    .empty-employee{
        width: 100%;
        text-align: center;
    }
`;