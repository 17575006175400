import styled from "styled-components";

export const DialogOverlay = styled.div`
  background-color: var(--black-a9);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  pointer-events: none;
  z-index:10;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    .button-confirm-container{
        display: flex;
        gap: 10px;
    }

    .actions{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    
`;

export const Fieldset = styled.fieldset`
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 8px;
    align-self: stretch;   
    
    border-radius: 4px;
    background: var(--minimal-color);
    border: none;

    max-height: 130px;

    p{
        color: var(--grey-color-60);
    }

    span{
        width: 100%;
        height: 80px;
        
        overflow-y: scroll;
        padding: 8px 16px;
    }
`;

export const DialogClose = styled.div`
    cursor: pointer !important;
    button{
        border: none;
    }
`;

export const points = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    p{
        color: var(--blue-color-100);
    }
  
`;

export const info = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;

`;

export const ButtonDiv = styled.div`
     margin-top:12px;
    
   

` 
export const ConquistaWrapper = styled.div`
    border-radius: 4px;
    display: flex;
    padding: 2px 4px;
    align-items: center;
    gap: 4px;
    background-color: var(--blue-color-20);
    width: 50%;
    .image-icon{
        background-color: var(--blue-color-100);
        width: 17px;
        height: 17px;
        padding: 3px;
        border-radius: 50%;
    }
`;

export const GestorWrapper = styled.div`
    display: flex;
    padding: 2px 4px;
    
    align-items: center;
    gap: 4px;
    background-color: var(--grey-color-20);
    border-radius: 4px;
    width: 50%;
    p{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;