import { useQuery } from "react-query";
import { EmployeeDataType, EmployeeInterface } from "../../../../models/employee.interface";
import { responseStatus } from "../../../../api/api-request.service";
import { useEmployeeService } from "../../../../services/employee.service";
import { HankingHookType } from "./hanking.type";
import { useEffect, useState } from "react";
import { useManagerService } from "../../../../services/manager.service";
import { DropdownOptionsListType } from "../../../components/dropdownOptions/dropdown-options.type";

export function useHankingHook({reload} : HankingHookType){
    const employeeService = useEmployeeService();
    const managerService = useManagerService();
    const [managerSearch, setManagerSearch] = useState('');
    const [selectedManager, setSelectedManager] = useState('');
    const [managerOptions, setManagerOpntios] = useState<DropdownOptionsListType[]>()
    
    const {
        data : employeesData, 
        isLoading : isLoadingEmployeesData,
        isRefetching : isRefetchingEmployeesData,
        refetch: refetchEmployeesData
    } = useQuery<EmployeeDataType>('getEmployeesHanking', getAllEmployees);

    const {
        data : managerData, 
        isLoading : isLoadingManagersData,
        isRefetching : isRefetchingManagersData,
        refetch: refetchManagersData
    } = useQuery<EmployeeDataType>('getManagersHanking', getAllManagers);

    async function getAllEmployees(){
        const {status, data} = await employeeService.getAllEmployees({orderBy: "RECOGNITIONS", page : 1, fkManagerId: selectedManager});
        if(status === responseStatus.SUCCESS){
            return data;
        }
    }

    async function getAllManagers(){
        const {status, data} = await managerService.getAllManagers({orderBy: "NAME", page : 1, search: managerSearch});
        if(status === responseStatus.SUCCESS){
            return data;
        }
    }

    useEffect(()=>{
        const options = managerData?.rows.map((manager : EmployeeInterface)=>({key: manager.id!.toString(), value: manager.name!}));
        if(options)
            options.unshift({ key: '', value: 'Todos' });
        setManagerOpntios(options);
    }, [managerData]);

    useEffect(()=>{
        refetchEmployeesData();
    }, [reload, selectedManager]);

    return {
        employeesData,
        isLoadingEmployeesData,
        isRefetchingEmployeesData,
        managerData,
        managerOptions,
        isLoadingManagersData : isRefetchingManagersData || isLoadingManagersData,
        refetchEmployeesData,
        setManagerSearch,
        refetchManagersData,
        selectedManager,
        setSelectedManager
    }

    
}
