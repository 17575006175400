import * as S from './toast.styled';
import { ToastType } from './toast.type';

import error_icon from '../../../assets/icons/error.svg';

export const ToastComponent = ({message} : ToastType)=>{
    return (
        <S.ToastContainer data-active={!!message}>
            <img src={error_icon} alt="Icone de erro"/>
            <span className='b3-regular'>{message}</span>
        </S.ToastContainer>
    );
};