import styled from "styled-components";

export const RewardCardContainer = styled.div`
    display: flex;
    align-items: flex-start;
    width: 515px;
    height: 245px;

    border-radius: 8px;
    background: var(--minimal-color);

    .rewards-image{
        width: 300px;
        height: 245px;
        object-fit: cover;
        
    }
`;

export const RewardCardContent = styled.div`
    display: flex;
    width: 223px;
    padding: 24px 16px;
    flex-direction: column;
    align-items: flex-end;
    gap: 12px;
    align-self: stretch;
`;

export const RewardCardActions = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    justify-content: space-between;
    & img{
        cursor:pointer;
    }
  
`;

export const RewardCardPoints = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    align-self: stretch;

    .points{
        color: var(--grey-color-60);
    }

    .value{
        color: var(--blue-color-100);
    }
`;


export const RewardCardDate = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    align-self: stretch;

    span{
        color: var(--grey-color-60);
    }
`;

export const RewardCardEmployee = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    .count-employee{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        width: 40px;
        
        span{
            color: var(--white-color);
        }

        border-radius: 96px;
        border: 1px solid var(--blue-color-100, #1C7FC2);
        background: var(--blue-color-80, #4999CE);
    }

    p{
        color: var(--grey-color-60);
    }
`;

export const RewardCardEvolution = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 16px;
    padding: 12px;

    border-radius: 8px;
    border: 1px solid var(--grey-color-40);
    background: var(--minimal-color);

    .item{
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .up{
        color: var(--blue-color-100);
    }

    .down{
        color: var(--red-color-100);
    }
`;