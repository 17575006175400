import { LocalToken } from '../utils/local-token';
import axios from 'axios';

const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === "dev-reconhecer.chemtradebrasil.com.br";
export const baseUrl = isLocalhost ? 'https://dev-api.chemtradebrasil.com.br' : 'https://back.chemtradebrasil.com.br';


const api = axios.create({
  baseURL: `${baseUrl}/api/v1`,
});

api.interceptors.request.use(
  (config) => {
    const token = LocalToken.getStoredToken();
    config.headers.Authorization = `Bearer ${token}`;
    
    if (config.method?.toUpperCase() === 'OPTIONS') {
      return Promise.reject('Ignorando solicitação OPTIONS');
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);




export default api;