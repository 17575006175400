import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { RewardsInterface } from "../../../models/rewards.interface";
import { queryClient } from "../../../utils/query-client";
import { NewRewardsCheckType, getRewardsSchema, NewRewardsHookType, } from "./new-rewards.type";
import { responseStatus } from "../../../api/api-request.service";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRewardsService } from "../../../services/rewards.service";
import { z } from "zod";
import { RewardsTypeDataType } from "../../../models/rewards-type.interface";
import { DropdownOptionsListType } from "../dropdownOptions/dropdown-options.type";

import school_icon from '../../../assets/icons/school.svg';
import restaurant_icon from '../../../assets/icons/restaurant.svg';
import ticket_icon_money from '../../../assets/icons/ticketyellow.svg';
import ticket_icon from '../../../assets/icons/ticket.svg';


export const useNewRewardsHook = ({ onClose, initialData }: NewRewardsHookType) => {
    const newrewardService = useRewardsService();
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [selectExampleName, setSelectExampleName] = useState<{ exampleName: string, id?: string | number }[]>(initialData?.usageExamples || []);
    const categories = [
        {title: 'Instituições de ensino', icon: school_icon},
        {title: 'Restaurantes da região', icon: restaurant_icon},
        {title: 'Entretenimento & Lazer', icon: ticket_icon},
        {title: 'Saldo em conta', icon: ticket_icon_money}
    ];

    const [options, setOptions] = useState<DropdownOptionsListType[]>();
    const [dropdownSelectedItem, setDropdownSelectedItem] = useState<string | undefined>();

    function handleCheck({ e, exampleName }: NewRewardsCheckType) {
        if (e.target.checked) {
            const newExampleName = [...selectExampleName];
            newExampleName.push(exampleName);
            setSelectExampleName(newExampleName);
            append(exampleName);
        } else {
            const index = selectExampleName.findIndex(item => item.exampleName === exampleName.exampleName);
            setSelectExampleName(selectExampleName.filter(item => item.exampleName !== exampleName.exampleName));
            remove(index);
        }

    }

    const { mutate, isLoading: isLoadingSubmit } = useMutation(
        (data: NewRewardsSchemaType) => handleSubmitForm(data), {
        onSuccess: () => {
            queryClient.invalidateQueries('createRewards');
        },
    });

    const {
        data: rewardsTypeData,
        isLoading: isLoadingRewardsTypeData,
        refetch: refetchRewards
    } = useQuery<RewardsTypeDataType>('getRewardsType', getAllRewardsType);


    const rewardsSchema = getRewardsSchema(rewardsTypeData?.rows.find(rt=>rt.id?.toString() === dropdownSelectedItem)?.name);
    type NewRewardsSchemaType = z.infer<typeof rewardsSchema>;

    const { register, handleSubmit, control, formState: { errors } } = useForm<NewRewardsSchemaType>({
        resolver: zodResolver(rewardsSchema),
        defaultValues: initialData as NewRewardsSchemaType
    });

    const { append, remove, fields } = useFieldArray({
        control,
        name: 'usageExamples'
    });

    async function getAllRewardsType() {
        const { status, data } = await newrewardService.rewardsType();
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }
    async function handleSubmitForm(payload: NewRewardsSchemaType) {
        if(initialData?.id){
            await handleEditRewards(payload);
        }else{
            await handleCreaterRewards(payload);
        }
    };

    async function handleEditRewards(payload: NewRewardsSchemaType) {
        
        const rewards: RewardsInterface = {
            ...payload,
            id: initialData?.id,
            fkRewardTypeId: parseInt(dropdownSelectedItem!)
        };

        const { status } = await newrewardService.putRewards(rewards);
        if (status === responseStatus.SUCCESS) {
            setShowModalSuccess(true);
            if (onClose) {
                setTimeout(() => {
                    onClose();
                    queryClient.refetchQueries('getRewardsBenki');
                    queryClient.refetchQueries('getRewardsCard');
                }, 2000);
            }
        }
    }

    async function handleCreaterRewards(payload: NewRewardsSchemaType) {
        
        const rewards: RewardsInterface = {
            ...payload,
            fkRewardTypeId: parseInt(dropdownSelectedItem!),
            isActive: true
        };

        const { status } = await newrewardService.createRewards(rewards);
        if (status === responseStatus.SUCCESS) {
            setShowModalSuccess(true);
            if (onClose) {
                setTimeout(() => {
                    onClose();
                    queryClient.refetchQueries('getRewardsBenki');
                    queryClient.refetchQueries('getRewardsCard');
                }, 2000);
            }
        }
    }

    function onSubmit(data: NewRewardsSchemaType) {
        mutate(data);
    }

    useEffect(()=>{
        setOptions(rewardsTypeData?.rows.map(rewardsType=>({key: rewardsType.id!.toString(), value: rewardsType.name!})));
        if(initialData?.fkRewardTypeId){
            setDropdownSelectedItem(initialData.fkRewardTypeId.toString());
        }else{
            setDropdownSelectedItem(rewardsTypeData?.rows[0]?.id?.toString());
        }
            
    }, [rewardsTypeData]);

    return {
        append,
        errors,
        isLoadingRewardsTypeData,
        showModalSuccess,
        refetchRewards,
        options,
        handleSubmit,
        handleCheck,
        onSubmit,
        register,
        rewardsTypeData,
        isLoadingSubmit,
        control,
        fields,
        dropdownSelectedItem,
        categories,
        setDropdownSelectedItem,
        selectExampleName,
        rewardsSchema
    }
}



