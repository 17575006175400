import * as SA from '../../../../app.styled';
import * as S from './rewards.styled';

import { TabBarComponent } from '../../../components/tabBar';
import { useRewardsHook } from './rewards.hook';
import { ButtonComponent } from '../../../components/button/index';
import { DateCalendarComponent } from '../../../components/dateCalendar';
import { HeaderPageComponent } from '../../../components/headerPage';
import { DialogComponent } from '../../../components/dialog';
import { NewRewards } from '../../../components/NewRewards';

import add_icon from '../../../../assets/icons/add.svg';


export const RewardsPage = () => {
    const hook = useRewardsHook();
    return (

        <SA.AppContainerHome>
            <SA.AppTopBar>
                <S.RewardsTopBar>
                    <HeaderPageComponent
                        title='Recompensas'
                        onSearch={(value: string) => { hook.setSearch(value) }}
                        refetch={hook.onReload}
                        filters={hook.filters}
                        selectedFilter={hook.selectedFilter}
                        setSelectedFilter={hook.setSelectedFilter}
                    />

                    {hook.isManager &&
                        <S.RewardsActions>
                            <DialogComponent
                                title='Nova recompensa'
                                width={660}
                                content={<NewRewards onClose={hook.handleClose} />}
                                isOpen={hook.isOpenModal}
                                onClose={() => hook.setIsOpenModal(false)}
                                onOpen={() => hook.setIsOpenModal(true)}
                            >
                                <ButtonComponent buttonStyle='primary' width={175} onClick={() => hook.setIsOpenModal(true)}>
                                    <img src={add_icon} alt="Icone de adicionar" />
                                    Nova recompensa
                                </ButtonComponent>
                            </DialogComponent>

                            <DateCalendarComponent />
                            {/* <SwitchComponent label='Pausar todas' enabled={hook.switchEnabled} onClick={() => hook.setSwitchEnabled(!hook.switchEnabled)} /> */}

                        </S.RewardsActions>
                    }
                </S.RewardsTopBar>

            </SA.AppTopBar>

            <TabBarComponent tabs={hook.tabsList} selectedTab={hook.selectedTab} setSelectedTab={hook.setSelectedTab} />

            {hook.tabs[hook.selectedTab]}
        </SA.AppContainerHome>

    );
}
