import * as S from './styled';

import logo_image from '../../../assets/images/logo.png';
import { useDispatch } from 'react-redux';
import { ButtonComponent } from '../../components/button';
import { setToastMessage } from '../../../features/general-slice';
import { baseUrl } from '../../../api/api';

export const HelpPage = () => {
  const dispatch = useDispatch();

  function onSubmit(e: any) {
    e.preventDefault();
    dispatch(setToastMessage('Dúvidas e sugestões enviadas com sucesso. Entraremos em contato'));
    alert('Dúvidas e sugestões enviadas com sucesso. Entraremos em contato');
  }

  return (
    <S.LoginContainer>
      <S.LoginContent>
        <img src={logo_image} alt="Logo Chemtrade" />
        <div className='title'>
          <span className='b2-bold'>Dúvidas ou Sugestões</span>
          <span className='b3-regular'>Informe seu email, dúvida ou sugestão e entraremos em contato. </span>
        </div>
        <form onSubmit={onSubmit} action={'/'}>

          <S.LoginFormContainer>
            <div className='form-inputs'>
              <div className='form-group'>
                <label htmlFor="email" className="b2-bold">Email</label>
                <input required autoComplete="username" type="text" id="email" className="b2-regular" />
              </div>
            </div>
            <div className='form-inputs'>
              <div className='form-group'>
                <label htmlFor="email" className="b2-bold">Sugestões</label>
                <textarea autoComplete="duvida" id="duvida" className="b2-regular" ></textarea>
              </div>
            </div>
            <div className='form-inputs'>
              <div className='form-group'>
                <label htmlFor="email" className="b2-bold">Dúvidas</label>
                <textarea autoComplete="sugestoes" id="sugestoes" className="b2-regular" ></textarea>
              </div>
            </div>

            <div className='form-actions'>
              <ButtonComponent buttonStyle='primary' type='submit'>
                <span className="b3-bold">Enviar dúvidas e sugestões</span>
              </ButtonComponent>
            </div>
          </S.LoginFormContainer>
        </form>
      </S.LoginContent>
    </S.LoginContainer>
  );
};
