import { useMutation, useQuery } from 'react-query';
import { ResetPasswordSchemaType } from './type';
import { useAuthService } from '../../../../services/auth.service';
import { responseStatus } from '../../../../api/api-request.service';
import { useLocation, useParams } from 'react-router-dom';

export function useResetPasswordHook() {
    const authService = useAuthService();
    const {mutate, isLoading} = useMutation((data: ResetPasswordSchemaType) => handleResetPassword(data));
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    
    //functions
    function onSubmit(data: ResetPasswordSchemaType){
        mutate(data);
    };

    async function handleResetPassword(payload: ResetPasswordSchemaType){
        const {status} = await authService.passwordReset({...payload, token: token || ''});
        if(status === responseStatus.SUCCESS){
            window.location.href='/';
        }
    };

    return {
        handleResetPassword,
        onSubmit,
        isLoading
    };
}
