import * as S from './login.styled';

import { ButtonComponent } from '../../../components/button';
import { useForm } from 'react-hook-form';
import { useLoginHook } from './login.hook';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoginSchemaType, loginSchema } from './login.type';

import logo_small_image from '../../../../assets/images/logo_small.png';
import { Link } from 'react-router-dom';


export const LoginPage = () => {

    const { register, handleSubmit, formState: { errors } } = useForm<LoginSchemaType>({ resolver: zodResolver(loginSchema)});
    const { onSubmit, isLoading } = useLoginHook();
    
    return (
        <S.LoginContainer>
            <S.LoginContent onSubmit={handleSubmit(onSubmit)}>
                <img src={logo_small_image} alt="Logo"/>

                <S.LoginFormContainer>
                    <div>
                        <input required autoComplete="username" type="text" id="emailOrCpf" placeholder='Entre com Email ou CPF' className="b2-regular" {...register('emailOrCpf')}/>
                        <span className='c1-regular error'>{errors?.emailOrCpf?.message}</span>
                    </div>
                    <div>
                        <input required autoComplete='current-password' type="password" id="password" placeholder='Entre com a senha' className="b2-regular" {...register('password')}/>
                        <span className='c1-regular error'>{errors?.password?.message}</span>
                    </div>
                </S.LoginFormContainer>

                <S.LoginActionContainer>
                    <ButtonComponent buttonStyle='primary' height={36} type='submit' disabled={isLoading}>
                        <span className="b3-bold">{isLoading ? "Acessando..." : "Acessar"}</span>
                    </ButtonComponent>

                    <Link to='/esqueci-a-senha' className='b3-regular'>Esqueci a senha</Link>
                </S.LoginActionContainer>
            </S.LoginContent>
        </S.LoginContainer>
    );
};
