import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { useRecognitionService } from "../../../services/recognition.service";
import { useGeneralSettingsService } from "../../../services/general-settings.service";
import { useUserLoginService } from "../../../services/user-login.service";
import { RecognitionDataType } from "../../../models/recognition.interface";
import { EmployeeInterface } from "../../../models/employee.interface";
import { GeneralSettingsInterface } from "../../../models/general-settings.interface";
import { responseStatus } from "../../../api/api-request.service";
import { EmployeeInfoModalType } from "./employee-info-modal.type";
import { useAppSelector } from "../../../hooks/hooks";
import { CardEditOptionType } from "../cardEdit/cardEdit.type";

export function useEmployeeInfoHook({ user, type, onClose }: EmployeeInfoModalType){
    const recognitionService = useRecognitionService();
    const generalSettingsService = useGeneralSettingsService();
    const userLoginService = useUserLoginService();
    const authUser = useAppSelector(state => state.user);
    const [isOpenModal, setIsOpenModal] = useState<number | null>(null);
    const [isOpenEditModal, setIsOpenEditModal] = useState<number | null>(null);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<number | null>(null);

    const editOptions : CardEditOptionType[] = [
        {
            title : 'Editar Informações',
            action: ()=>{setIsOpenEditModal(user?.id!)}
        },
        {
            title : `Excluir ${type === 'EMPLOYEE' ? "Funcionário" : "Gestor"}`,
            action: ()=>{setIsOpenDeleteModal(user?.id!)}
        }
    ];

    const {
        data : recognitionsData, 
        isLoading : isLoadingRecognitionsData,
        isRefetching : isRefetchingRecognitionsData,
        refetch: refetchRecognitions
    } = useQuery<RecognitionDataType>('getRecognitionsByUserId', getRecognitionsByUserId);

    const {
        data : userData, 
        isLoading : isLoadingUserData,
        refetch: refetchUser,

    } = useQuery<EmployeeInterface>('getUserByUserId', getUserById);

    const {
        data : generalSettingsData, 
        isLoading : isLoadingGeneralSettingsData
    } = useQuery<GeneralSettingsInterface | undefined>('getUniqueGeneralSettings', getUniqueGeneralSettings);

    async function getRecognitionsByUserId(){
        const {status, data} = type === "EMPLOYEE" ? 
            await recognitionService.getRecognitionByUserId(user?.id!) : 
            await recognitionService.getRecognitionByAuthorId(user?.id!);

        if(status === responseStatus.SUCCESS){
            return data;
        }
    }

    async function getUserById(){
        const {status, data} = await userLoginService.getUserLogin(user?.id!.toString());
        if(status === responseStatus.SUCCESS){
            return data;
        }
    }

    async function getUniqueGeneralSettings(){
        if(type !== "EMPLOYEE" ){
            const {status, data} = await generalSettingsService.getUniqueGeneralSettings();
            if(status === responseStatus.SUCCESS){
                return data;
            }
        }
    }

    function handleReload(){
        // refetchRecognitions();
        refetchUser();
        setIsOpenModal(null);
        setIsOpenEditModal(null) ;
    }

    function handleReloadDelete(){
        onClose();
        setIsOpenDeleteModal(null) ;
    }
    
    return {
        recognitionsData,
        isLoadingRecognitionsData,
        isRefetchingRecognitionsData,
        generalSettingsData,
        userData,
        isOpenModal,
        authUser,
        editOptions,
        isOpenEditModal,
        isOpenDeleteModal,
        setIsOpenDeleteModal,
        setIsOpenModal,
        setIsOpenEditModal,
        handleReload,
        handleReloadDelete
    }

    
}
