import { baseUrl } from '../../../api/api';
import * as S from './team-profiles.styled';

import profile_icon from '../../../assets/icons/profile.svg';
import { TeamProfilesType } from './team-profiles.type';

export const TeamProfilesComponent = ({ team, imageWidth }: TeamProfilesType) => {
    return (
        <S.TeamProfilesContainer data-image-width={imageWidth}>
            {team && team.length > 0 ?
                <>
                    {team?.slice(0, 3).map(employee => (
                        <img 
                            key={`profile-image-${employee.id}`}
                            className="profile" 
                            src={
                                employee.profilePic?.content ? 
                                `${baseUrl}/${employee.profilePic?.content}` :
                                profile_icon
                            } 
                            alt="Perfil do funcionário" 
                        />

                    ))}
                </> :
                <span className='team-empty c1-regular'>
                    Nenhuma Equipe
                </span>
            }
        </S.TeamProfilesContainer>
    );
}