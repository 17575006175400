import { useMutation, useQuery } from "react-query";
import { ModalNewEmployeeHookType, getEmployeeSchema } from "./modalNewEmployee.type";
import { queryClient } from "../../../utils/query-client";
import { EmployeeDataType, EmployeeInterface } from "../../../models/employee.interface";
import { useEmployeeService } from "../../../services/employee.service";
import { responseStatus } from "../../../api/api-request.service";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ChangeEvent, useEffect, useState } from "react";
import { useManagerService } from "../../../services/manager.service";

import base64 from 'base64-encode-file';
import { z } from "zod";
import { useSectorService } from "../../../services/sector.service";
import { SectorDataType } from "../../../models/sector.interface";
import { formatDate } from "date-fns";

export function useModalNewEmployeeHook({ onClose, initialData}: ModalNewEmployeeHookType) {
    const employeeService = useEmployeeService();
    const managerService = useManagerService();
    const sectorsService = useSectorService();
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [search, setSearch] = useState('');
    const [showMSelectManager, setShowMSelectManager] = useState(false);
    const [selectedManager, setSelectedManager] = useState<EmployeeInterface>();
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const [file, setFile] = useState<unknown>();
    const employeeSchema = getEmployeeSchema(initialData?.id);
    type EmployeeSchemaType = z.infer<typeof employeeSchema>;

    const { mutate, isLoading: isLoadingSubmit } = useMutation(
        (data: EmployeeSchemaType) => handleSubmitForm(data), {
        onSuccess: () => {
            queryClient.invalidateQueries('createEmployee');
        },
    });

    const {
        data : sectorsData, 
        isLoading : isLoadingSectorsData,
        isRefetching : isRefetchingSectorsData,
        refetch: refetchsectorsData
    } = useQuery<SectorDataType>('getAllSectors', getAllSectors);

    const {
        data: managerData,
        isLoading: isLoadingManagersData,
        refetch: refetchManager,
    } = useQuery<EmployeeDataType>('getManagers', getAllManagers);

    const { register, handleSubmit, control, getValues, formState: { errors } } = useForm<EmployeeSchemaType>({
        resolver: zodResolver(employeeSchema),
        defaultValues: {
            ...initialData,
            admissionDate: initialData?.admissionDate ? new Date(initialData.admissionDate).toISOString().slice(0, 10) : '',
            sectorId: initialData?.sector?.id?.toString()
        } as EmployeeSchemaType
    });

    async function getAllManagers() {
        const { status, data } = await managerService.getAllManagers({ search: debouncedSearch });
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }

    async function handleTransformManager(employee: EmployeeInterface){
        const newEmployee = {...employee};
        newEmployee.groups = [...newEmployee.groups, {id: 4, name: 'MANAGER'}];

        await handleCreateEmployee(newEmployee as any)
    }

    async function getAllSectors(){
        const {status, data} = await sectorsService.getSector();
        if(status === responseStatus.SUCCESS){
            return data;
        }
    }

    async function handleSubmitForm(payload: EmployeeSchemaType) {
        await handleCreateEmployee(payload);
    };

    async function handleProfileImageChange(event: ChangeEvent<HTMLInputElement>){
        const file = event.target.files?.[0];
        if (file) {
            const newFile = await base64(file);
            setFile(newFile);
        }
    };

    async function handleCreateEmployee(payload: EmployeeSchemaType) {
        let employee: EmployeeInterface = {
            ...payload,
            isBlocked: false,
            id: initialData?.id,
            sector: {id: parseInt(payload.sectorId)}
        };

        if (file) {
            employee = {
                ...employee,
                profilePic: {
                    type: 'base64',
                    filetype: 'Image',
                    content: file.toString()
                }
            };
        }

        const { status } = initialData?.id ?
            await employeeService.editEmployee({ data: employee }) :
            await employeeService.createEmployee({ data: employee });
        if (status === responseStatus.SUCCESS) {
            setShowModalSuccess(true);
            if (onClose) {
                setTimeout(() => {
                    onClose();
                }, 2000);
            }
        }
    }

    function onSubmit(data: EmployeeSchemaType) {
        mutate(data);
    }

    function handleSelectManager(manager: EmployeeInterface) {
        setSelectedManager(manager);
        setShowMSelectManager(false);
    }

    function handleBlurSearch() {
        setTimeout(() => {
            setShowMSelectManager(false);
        }, 300);
    }

    function handleFocusSearch() {
        setShowMSelectManager(true);
    }

    function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
        setSelectedManager(undefined);
        setSearch(e.target.value);
    }

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedSearch(search);
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [search]);

    useEffect(() => {
        refetchManager();
    }, [debouncedSearch]);

    return {
        errors,
        isLoadingSubmit,
        showModalSuccess,
        managerData,
        selectedManager,
        showMSelectManager,
        search,
        handleTransformManager,
        file,
        sectorsData,
        register,
        getValues,
        handleSubmit,
        onSubmit,
        handleSelectManager,
        handleBlurSearch,
        handleFocusSearch,
        handleSearch,
        handleProfileImageChange
    }
}
