import styled from "styled-components";

interface SelectEmployeePopoverItemProps{
    'data-active' : boolean;
}

interface SelectEmployeePopoverProps{
    'data-open-top'? : boolean;
    'data-open-fixed'? : boolean;
}

export const SelectEmployeeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    position: relative;

    label{
        color: var(--grey-color-60);
    }

    .profile-icon{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 16px;
    }

    .search{
        display: flex;
        height: 44px;
        padding: 8px 16px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        border-radius: 8px;
        background: var(--white-color);

        input{
            width: 100%;
            border: none;
            padding: 8px 0;
            outline: none;
            z-index: 1000;
        }
    }
`;

export const SelectEmployeePopoverContent = styled.div`
    max-height: 200px;
    overflow-y: scroll;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;


export const SelectEmployeePopoverItem = styled.div<SelectEmployeePopoverItemProps>`
    width: 100%;
    border-radius: 8px;
    cursor: pointer;
    padding: 8px 16px;

    ${props=>props['data-active'] ?
        `
            background-color: var(--blue-color-100);
            p{
                color: var(--white-color);
            }
        ` : 
        `
            background-color: transparent;
        `
    };
    
    &:hover{
        background-color: var(--blue-color-20);
    }

`;

export const SelectEmployeePopover = styled.div<SelectEmployeePopoverProps>`
    width: 100%;
    padding: 8px;
    z-index: 999 !important;
    position: ${props=> props['data-open-fixed'] ? 'fixed' : 'absolute'};
    ${props=>  props['data-open-top'] && !props['data-open-fixed'] ? 'bottom: 60px' : 'top: 80px' };

    ${props=> props['data-open-fixed'] &&
        `
            left: 36px;
            width: 341px;
            top: 170px;
        `
    }

    border-radius: 8px;
    background: var(--white-color);
    box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.16);
`;