import { useQuery } from 'react-query'
import { NotificationDataType } from '../../../models/notification.interface';
import { responseStatus } from '../../../api/api-request.service';
import { useNotificationService } from '../../../services/notification.service';
import { useAppSelector } from '../../../hooks/hooks';

export function useNotificationHook() {
    const user = useAppSelector((state) => state.user);
    const notificationService = useNotificationService();
    const {
        data: notificationsData,
        isLoading: isLoadingNotifications,
    } = useQuery<NotificationDataType>('getAllNotifications', getAllNotifications);


    async function getAllNotifications() {
        const { status, data } = await notificationService.getNotificationByUserId(user.user_id);
        if (status === responseStatus.SUCCESS) {
            return data;
        }

    }

    return {
        notificationsData,
        isLoadingNotifications
    }
}