import styled from "styled-components";

export const Profile = styled.div`
    display:flex;
    flex-direction:row;
    gap:16px;
    justify-content:center;
    align-items:center;
    cursor:pointer;

    img{
        width: 32px;
        height: 32px;
        border-radius: 64px;
        object-fit: cover;
    }
`