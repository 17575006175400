import {configureStore} from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux';
import { userReducer} from './features/user-slice';
import { generalReducer } from './features/general-slice';

export const store = configureStore({
    reducer:{
        user: userReducer,
        general: generalReducer
    }

})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = useDispatch.withTypes<AppDispatch>()