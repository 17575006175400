import { formatDate } from "date-fns";
import { useHandleRequest } from "../api/api-request.service";
import { RecognitionCategoriesStatisticsDataType, RecognitionCategoriesStatisticsInterface } from "../models/recognition-category-statistics.interface";

export function useRecognitionCategoryAnaliticsService(){
    const handleRequestHook = useHandleRequest();
    async function getAllStatistics(fromDate:Date, toDate:Date) : Promise<{ status: string; data: RecognitionCategoriesStatisticsDataType }>{
        return handleRequestHook.handleRequest(`/recognition-categories/all-statistics?fromDate=${formatDate(fromDate, 'yyyy-MM-dd')}&toDate=${formatDate(toDate, 'yyyy-MM-dd')}`, 'GET');         
    }

    return {
        getAllStatistics
    }
}