import { ModalNewRecognitionType } from "./modalNewRecognition.type";
import * as S from './modalNewRecognition.styled';
import svg_icon from '../../../../../../assets/icons/iconAddSvg.svg';
import * as  Slider from "@radix-ui/react-slider";
import wallet from '../../../../../../assets/icons/Wallet.svg';
import { SwitchComponent } from "../../../../../components/switch";
import { useModalNewRecoginition } from "./modalNewRecognitio.hook";
import { ButtonComponent } from "../../../../../components/button";
import { baseUrl } from "../../../../../../api/api";
import { ModalSuccess } from "../../../../../components/modalSuccess";
import { DialogComponent } from "../../../../../components/dialog";
import { ModalDeletRecognitionCategory } from "../../../../../components/modalDeleteRecognition";


export const ModalNewRecognition = ({ onClose, recognition }: ModalNewRecognitionType) => {

    const hook = useModalNewRecoginition({ initialData: recognition, onClose })

    return (
        <>
            {hook.showModalSuccess ?
                <ModalSuccess message={`Reconhecimento ${recognition ? 'editado' : 'cadastrado'}!`} /> :
                <form onSubmit={hook.handleSubmit(hook.onSubmit)}>
                    <S.ModalContainer>
                        <S.ModalContent>
                            <div className="top-container">
                                <S.NomeContainer>
                                    <label htmlFor="input-file">
                                        <img
                                            className={(hook.file || recognition?.svg?.content) ? 'border' : ''}
                                            src={
                                                hook.file ?
                                                    hook.file :
                                                    recognition?.svg?.content ?
                                                        `${baseUrl}/${recognition?.svg?.content}` :
                                                        svg_icon
                                            }
                                            alt="Perfil do usuário"
                                        />

                                        <input
                                            className="file-input"
                                            hidden
                                            id="input-file"
                                            accept="image/svg+xml"
                                            type="file"
                                            {...hook.register('file', { onChange: hook.handleImageChange })}
                                        />
                                    </label>

                                    <div>
                                        <label htmlFor="name" className="c1-regular">Nome</label>
                                        <input id={'name'} type="text" {...hook.register('name')} />
                                        <span className="c1-regular error">{hook.errors.name?.message}</span>
                                    </div>

                                </S.NomeContainer>
                                <span className="c1-regular error">{hook.errors.file?.message}</span>
                                <p className="c1-bold info">Apenas imagens .SVG sem fundo na cor branca</p>
                            </div>

                            <S.PointsSlider>
                                <Slider.Root
                                    max={hook.generalSettingsData?.limitManagerRewards}
                                    min={1}
                                    step={1}
                                    defaultValue={[hook?.points]}
                                    onValueChange={(values) => hook.setPoints(values[0])}
                                >
                                    <S.SlideRoot>
                                        <Slider.Track className="SliderTrack">
                                            <Slider.Range className="SliderRange" />
                                        </Slider.Track>
                                        <Slider.Thumb className="SliderThumb" aria-label="Volume" />
                                    </S.SlideRoot>
                                </Slider.Root>

                                <S.Points>
                                    <img src={wallet} alt="icone de Wallet" />
                                    <p className='b3-regular'>{hook.points} pts</p>
                                </S.Points>

                            </S.PointsSlider>

                            <S.Enable>
                                <SwitchComponent enabled={hook.enable} label={''} onClick={() => hook.setEnable(!hook.enable)} />
                                <p className="b2-regular">Gestores também podem reconhecer</p>
                            </S.Enable>
                        </S.ModalContent>

                        <S.Actions>

                            <ButtonComponent type='submit' buttonStyle="primary" disabled={hook.isLoadingSubmit}>
                                {hook.isLoadingSubmit ? 'Salvando...' : recognition?.id ? 'Editar' : 'Confirmar'}
                            </ButtonComponent>
                            
                            {recognition?.id &&
                                <ButtonComponent onClick={()=>hook.setShowDeleteModal(true)} buttonStyle="secondary" disabled={hook.isLoadingSubmit}>
                                    Excluir
                                </ButtonComponent>
                            }
                        </S.Actions>

                    </S.ModalContainer>

                </form>
            }

            <DialogComponent
                title={'Excluir Reconhecimento'}
                isOpen={hook.showDeleteModal}
                onOpen={() => hook.setShowDeleteModal(true)}
                onClose={() => hook.setShowDeleteModal(false)}
                width={418}
                content={<ModalDeletRecognitionCategory onReload={hook.onReload} recognitionCategory={recognition!} onClose={hook.setShowDeleteModal} />}
            >
                <></>

            </DialogComponent>

        </>

    )
}