import * as S from './modalSuccess.styles';
import { ModalSuccessType } from './modalSucess.type';

import check_icon from '../../../assets/icons/check.svg';

export const ModalSuccess = ({ message }: ModalSuccessType) => {
    return (
        <S.Success>
            <img src={check_icon} alt="Icone de check" />
            <p className='s4-regular'>{message}</p>
        </S.Success>
    );
}