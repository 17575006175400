import styled from "styled-components";

export const SelectTeamContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
`;

export const SelectTeamList = styled.div`
    display: flex;
    width: 340px;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    height: 208px;

    border-radius: 8px;
    background: var(--white-color);

    .item{
        width: 100%;
        height: 200px;
        overflow-y: scroll;
        padding: 0 8px;
    }
`;

export const SelectTeamContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    padding: 16px;
    gap: 2px;
    
    border-radius: 4px;
    background: var(--minimal-color);
`;

export const SelectTeamContentItem = styled.label`
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    cursor: pointer;
    padding: 8px 0;

    input{
        width: 16px;
        height: 16px;

    }
`;
