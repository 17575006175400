import { useHandleRequest } from "../api/api-request.service";
import { RecognitionCategoryDataType, RecognitionCategoryInterface } from "../models/recoginition-category";

type CreateRecognitionCategoryType = {
    data : RecognitionCategoryInterface
 }

 type DeleteRecognitionCategoryType = {
    categoryId : number
 }

export function useRecognitionCategoryService() {
    const handleRequestHook = useHandleRequest();

    async function getAllRecognitionCategories() {
        return handleRequestHook.handleRequest(`/recognition-categories`, 'GET');
    }

    async function bulkRecognitionCategories(data: RecognitionCategoryInterface[]) : Promise<{ status: string; data: RecognitionCategoryDataType }>{
        return handleRequestHook.handleRequest(`/recognition-categories/update/bulk`, 'PUT', data);
    }

    async function createRecognitionCategory({data} : CreateRecognitionCategoryType) : Promise<{ status: string; data: RecognitionCategoryInterface }>{
        return handleRequestHook.handleRequest('/recognition-categories', 'POST', data);         
    }

    async function editRecognitionCategory({data} : CreateRecognitionCategoryType) : Promise<{ status: string; data: RecognitionCategoryInterface }>{
        return handleRequestHook.handleRequest(`/recognition-categories/${data.id}`, 'PUT', data);         
    }

    async function deleteRecognitionCategory({categoryId} : DeleteRecognitionCategoryType){
        return handleRequestHook.handleRequest(`/recognition-categories/${categoryId}`, 'DELETE');         
    }

    return {
        getAllRecognitionCategories,
        bulkRecognitionCategories,
        createRecognitionCategory,
        editRecognitionCategory,
        deleteRecognitionCategory
    }
}