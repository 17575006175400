import styled from "styled-components";


export const Divider = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    .line{
        height: 1px;
        background-color: var(--grey-color-40);
        width: 143px;

    }
`;  

export const DialogContent = styled.div`
    
    form{
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
`;

export const TeamContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 340px;
    overflow-x: scroll;
    padding-bottom: 4px;
    gap: 4px;

    .profile-image{
        width: 24px;
        height: 24px;
        border-radius: 50%;
        object-fit: cover;
    }
`;


export const Container = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    background: var(--minimal-color);
    margin-top:24px;

    & button{
        margin-top:16px;
    }
`;

export const NomeContairner = styled.div`

    display:flex;
    flex-direction:row;
    margin-top:4px;

    
    img{
        width: 64px;
        height: 64px;
        border-radius: 50%;
        object-fit: cover;
    }

    label{
        cursor: pointer;
    }

    .profile-input{
        display: none;
    }

    .nome{
        margin-left:8px;
    }   

    & input{
        margin-top:8px;
        display: flex;
        height: 44px;
        width:265px;
        padding: 8px 16px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        background: var(--white-color);
        border:none;
    }
`;

export const SelectManager = styled.div`
    width: 100%;
    margin-bottom: 8px;
`;

export const DadosContainer = styled.div`
    display:flex;
    flex-direction:column;
    margin-top:4px;

    & input, select{
        margin-top:8px;
        display: flex;
        height: 44px;
        width:333px;
        padding: 8px 16px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        background: var(--white-color);
        border:none;
        position: relative;
    }

    select{
        width: 162px;
    }


    input[type="date"]::-webkit-calendar-picker-indicator{
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        width: 18px; 
        height: 18px; 
        opacity: 1;
    }

    input[type="date"]::-moz-calendar-picker-indicator{
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        width: 18px; 
        height: 18px; 
        opacity: 1;
    }
`;

export const InputBuscaOptions = styled.div`
    display: flex;
    width: 100%;
    background-color: var(--white-color);
    border: 1px solid var(--grey-color-20);
    border-radius: 8px;
    bottom: 50px;
    position: absolute;
    flex-direction: column;
    gap: 8px;
    

    div{
        width: 100%;
        align-items: center;
        justify-content: start !important;
        padding: 8px 16px;

        img{
            margin: 0;
        }

        p{
            display: flex;
            align-items: center;
            margin-left: 40px;
            height: 32px;
        }

        &:hover{
            background-color: var(--blue-color-20);
        }
    }
`;

export const Row = styled.div`
    display: flex;
    gap: 8px;
    input{
        width: 162px !important;
    }
`;


export const Subtitulo = styled.div`

    .subtitulo{
        margin-top:10px;
        margin-bottom: 8px;
    }

`

export const EquipeImg = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;

    .imgPerfil{
        position:absolute;
        z-index:4;
        margin-left:45px;
    }

    .imgPerfil2{
        position:absolute;
        z-index:5;
        margin-left:95px;
    }
`;