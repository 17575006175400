import * as S from './hanking-card.styled';

import { CardNameEmployee } from '../cardNameEmployee';
import { HankingCardType } from './hanking-card.type';
import { baseUrl } from '../../../api/api';
import { useHankingCardHook } from './hanking-card.hook';
import { EmployeeInfoModal } from '../employeeInfoModal';
import { DialogComponent } from '../dialog';

export const HankingCardComponent = ({ employee, position }: HankingCardType) => {
    const hankingcardHook = useHankingCardHook();
    return (
        <DialogComponent
            className="w-100"
            title="Informações do Funcionário"
            isOpen={hankingcardHook.isOpenModal === employee.id}
            onOpen={() => hankingcardHook.setIsOpenModal(employee.id!)}
            onClose={() => hankingcardHook.setIsOpenModal(null)}
            width={1083}
            content={<EmployeeInfoModal user={employee} type={'EMPLOYEE'} onClose={()=>{}}/>}
        >
            <S.HankingCardContainer onClick={() => hankingcardHook.setIsOpenModal(employee.id!)}>

                <S.HankingCardTop>
                    <div className='left-content'>
                        <span className="s4-regular position">#{position}</span>
                        <CardNameEmployee url={employee.profilePic?.content} nome={employee.name!} />
                    </div>
                    <span className='b3-regular'>{employee.myTeam?.userManager?.name}</span>
                </S.HankingCardTop>
                <span className="s3-regular">{employee.totalAccumulatedPoints} pts</span>

                <S.HankingCardRewards>
                    {employee.recognitionCategoriesCount?.map(recognitionCategory => (
                        <S.HankingCardRewardsItem data-active={recognitionCategory.count > 0}>
                            <img src={`${baseUrl}/${recognitionCategory.category.svg?.content}`} alt="Icone da categoria" />
                            <span className="b3-regular">{recognitionCategory.count}</span>
                        </S.HankingCardRewardsItem>
                    ))}
                </S.HankingCardRewards>

            </S.HankingCardContainer>
        </DialogComponent>
    );
}