import * as S from './styled';

import logo_image from '../../../assets/images/logo.png';
import { ButtonComponent } from '../../components/button';
import reconhecer_apk from '../../../assets/reconhecer.apk';

export const DownloadAndroidPage = () => {

  return (
    <S.LoginContainer>
      <S.LoginContent>
        <img src={logo_image} alt="Logo Chemtrade" />
        <div className='title'>
          <span className='b2-bold'>Baixe agora</span>
          <span className='b3-regular'>Faça o download do Aplicativo Reconhecer e instale no seu dispositivo Android</span>
        </div>

        <a href={reconhecer_apk} className='form-actions'>
          <ButtonComponent buttonStyle='primary' type='button'>
            <span className="b3-bold">Download</span>
          </ButtonComponent>
        </a>

      </S.LoginContent>
    </S.LoginContainer>
  );
};
