import * as S from './styled';
import * as SA from '../../../../app.styled';

import { UpdateCardComponent } from '../../../components/updateCard';
import { InputSearchComponent } from '../../../components/inputSearch';
import { ManagerCardComponent } from '../../../components/managerCard';
import { ButtonComponent } from "../../../components/button/index";
import { useHomeHook } from './hook';
import { RecognitionInterface } from '../../../../models/recognition.interface';
import { HeaderPageComponent } from '../../../components/headerPage';
import { DialogComponent } from '../../../components/dialog';
import { RewardEmployeeModal } from '../rewardEmployeeModal';
import { LoadingComponent } from '../../../components/loading';
import { EmployeeInfoModal } from '../../../components/employeeInfoModal';
import { EmployeeListTab } from '../../../components/employeeList';


import historyc_icon from '../../../../assets/icons/historyc.svg';
import add_icon from '../../../../assets/icons/add.svg';

export const HomePage = () => {

    const hook = useHomeHook();

    return (
        <>
            <SA.AppContainerHome>
                <SA.AppTopBar>
                    <HeaderPageComponent title={"Atualizações"} onUpdate={() => hook.refetchRecognitions()} />

                    <ButtonComponent buttonStyle='primary' width={240} onClick={() => hook.setIsOpenModal(true)}>
                        <img src={add_icon} alt="Icone de adicionar" />
                        <span className='b3-bold'>Recompensar funcionário</span>
                    </ButtonComponent>

                </SA.AppTopBar>

                <SA.AppUpdatesContainer>
                    {hook.isLoadingRecognition ?
                        <LoadingComponent containerHeight={236}/> :
                        <>
                            <SA.AppUpdatesContent>
                                {hook.recognitionData?.rows.map((recognition: RecognitionInterface) => (
                                    <UpdateCardComponent recognition={recognition} updateRecognitions={hook.refetchRecognitions} />
                                ))}
                            </SA.AppUpdatesContent>

                            <S.HomeHistorycCard height={236}>
                                <img src={historyc_icon} alt={'Icone de histórico'} />
                                <div>
                                    <span className='b3-regular'>Histórico de atualizações</span>
                                    <ButtonComponent onClick={() => hook.navigate('historico')} buttonStyle='secondary'>
                                        Ver histórico
                                    </ButtonComponent>
                                </div>
                            </S.HomeHistorycCard>
                        </>
                    }
                </SA.AppUpdatesContainer>

                <S.HomeManagerContainer>
                    <InputSearchComponent
                        placeholder='Buscar'
                        setSearch={hook.setSearchManager}
                        refetch={hook.handleSearchReload}
                        filters={hook.filters}
                        setSelectedFilter={hook.setSelectedFilter}
                        selectedFilter={hook.selectedFilter}
                    />

                    {hook.isManager === null ?
                        <></> :
                        <>
                            {hook.isManager ?
                                <S.HomeManagerContent>
                                    {hook.isLoadingManager || hook.isRefetchhingManager ?
                                        <LoadingComponent containerHeight={200} /> :
                                        <>
                                            {hook?.managerData?.rows.map((manager) => (
                                                <ManagerCardComponent manager={manager} onClick={() => hook.setIsOpenModalEmployee(manager)} />
                                            ))}
                                        </>
                                    }

                                </S.HomeManagerContent> :
                                <EmployeeListTab reload={hook.reload} search={hook.searchManager} selectedFilter=''/>
                            }
                        </>
                    }
                </S.HomeManagerContainer>
            </SA.AppContainerHome >

            <DialogComponent
                content={<RewardEmployeeModal onClose={() => hook.setIsOpenModal(false)} />}
                title={"Recompensar funcionário"}
                width={418}
                isOpen={hook.isOpenModal}
                onClose={() => hook.setIsOpenModal(false)}
            />

            <DialogComponent
                isOpen={!!hook.isOpenModalEmployee}
                onClose={() => hook.setIsOpenModalEmployee(null)}
                title='Informações do Gestor'
                content={<EmployeeInfoModal user={hook.isOpenModalEmployee!} type='MANAGER' onClose={hook.handleSearchReload} />}
                width={1050}
            />
        </>
    );

};
