import * as S from './cardNameEmployee.styled';
import { baseUrl } from '../../../api/api';
import { CardNameEmployeeType } from './cardNameEmployee.type';

import profile_icon from '../../../assets/icons/profile.svg';

export const CardNameEmployee = ({ nome, url, onClick }: CardNameEmployeeType) => {
    return (
        <S.Profile onClick={onClick}>
            <img src={url ?
                `${baseUrl}/${url}` :
                profile_icon
            }
                alt="imagem de perfil" />
                <p className="b3-regular employee-name">{nome}</p>
        </S.Profile>
    )
}