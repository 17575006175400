import styled from "styled-components";

export const TabBarContainer = styled.div`
    display: flex;
    gap: 24px;

    .active{
        color: var(--black-color);
        border-bottom: 4px solid var(--blue-color-60);
    }
`;

export const TabBarItem = styled.div`
    cursor: pointer;
    color: var(--grey-color-40);
    display: flex;
    padding: 12px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-bottom: 4px solid transparent;
    &:hover{
        color: var(--black-color);
        border-bottom: 4px solid var(--blue-color-20);
    }

    .alert{
        display: flex;
        min-width: 20px !important;
        min-height: 20px !important;
        padding: 4px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        border-radius: 15px;
        border: 1px solid var(--yellow-color-100);
        background: var(--yellow-color-10);
        color: var(--yellow-color-100);
        font-weight: bold;
        

    }
`;