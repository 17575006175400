import { useMutation } from 'react-query';
import { LoginSchemaType } from './login.type';
import { queryClient } from '../../../../utils/query-client';
import { useAuthService } from '../../../../services/auth.service';
import { responseStatus } from '../../../../api/api-request.service';
import { LocalToken } from '../../../../utils/local-token';

export function useLoginHook() {
    const authService = useAuthService();
    const {mutate, isLoading} = useMutation((data: LoginSchemaType) => handleLogin(data), {
        onSuccess: () => {
            queryClient.invalidateQueries('login');
        },
    });
    
    
    //functions
    function onSubmit(data: LoginSchemaType){
        mutate(data);
    };

    async function handleLogin(payload: LoginSchemaType){
        const {status, data} = await authService.login(payload);
        if(status === responseStatus.SUCCESS){
            LocalToken.storeToken(data.access);
            window.location.href='/';
        }
    };

    return {
        handleLogin,
        onSubmit,
        isLoading
    };
}
