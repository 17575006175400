import { useHandleRequest } from "../api/api-request.service";
import { RewardCalendarInterface } from "../models/reward-calendar.interface";

export function useRewardCalendarService() {
  const handleRequestHook = useHandleRequest();

  async function getRewardsCalendar() {
    return handleRequestHook.handleRequest(`/reward-calendar`, 'GET');
  }

  async function putRewardsDate(payload:RewardCalendarInterface){
    return handleRequestHook.handleRequest(`/reward-calendar/${payload.id}`, 'PUT', payload);         
  }

  return {
    getRewardsCalendar,
    putRewardsDate
  }
}