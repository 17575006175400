import { useEffect, useState } from "react";
import { useRecognitionService } from "../../../services/recognition.service";
import { ButtonConfigType, UpdateCardHookType } from "./update-card.type";
import { responseStatus } from "../../../api/api-request.service";
import { useMutation } from "react-query";
import { RecognitionInterface } from "../../../models/recognition.interface";
import { queryClient } from "../../../utils/query-client";
import { useAppSelector } from "../../../hooks/hooks";

export function useUpdateCardHook({ updateRecognitions, recognition }: UpdateCardHookType) {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [showModalRefused, setShowModalRefused] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [showProgress, setShowProgress] = useState(false);
    const [buttonConfig, setButtonConfig] = useState<ButtonConfigType>();
    const recognitionService = useRecognitionService();
    const userAuth = useAppSelector(state => state.user);

    const { mutate, isLoading: isLoadingSubmit } = useMutation(
        () => userAuth.isManager ? handleSubmitFormRH() : handleSubmitFormManager(), {
        onSuccess: () => {
            queryClient.invalidateQueries('putRecognition');
        },
    }
    );

    async function handleSubmitFormRH() {
        const recognitionStatus = 'APPROVED';

        const payload = { 
            ...recognition, 
            fkApprovedByUserId: parseInt(userAuth.user_id), 
            approvedAt: new Date(),
            status: recognitionStatus 
        };
        
        if(recognition.status === "PENDING"){
            payload.fkConfirmedByUserId = parseInt(userAuth.user_id);
            payload.confirmedAt = new Date();
        }

        const { status } = await recognitionService.putRecognition(payload);
        if (status === responseStatus.SUCCESS) {
            setShowModalSuccess(true);
            setIsOpenModal(true);
            setTimeout(() => {
                setIsOpenModal(false);
                setShowModalSuccess(false);
                updateRecognitions();
            }, 2000);
        }
    }

    async function handleSubmitFormManager() {
        const recognitionStatus = 'CONFIRMED';
        
        const { status } = await recognitionService.putRecognition({
            ...recognition, 
            fkConfirmedByUserId: parseInt(userAuth.user_id), 
            status: recognitionStatus 
        });

        if (status === responseStatus.SUCCESS) {
            setShowModalSuccess(true);
            setIsOpenModal(true);

            setTimeout(() => {
                updateRecognitions();
                setShowModalSuccess(false);
                setIsOpenModal(false);
            }, 2000);
        }
    }

    async function onSubmit() {
        mutate();
    }

    function showModalDetails() {
        setIsOpenModal(true);
    } 

    function closeModal() {
        setIsOpenModal(false);
    }
    

    return {
        isOpenModal,
        isLoadingSubmit,
        showModalRefused,
        userAuth,
        buttonConfig,
        showModalSuccess,
        showProgress,
        setShowProgress,
        closeModal,
        showModalDetails,
        setShowModalSuccess,
        setShowModalRefused,
        setIsOpenModal,
        onSubmit,

    }

}


