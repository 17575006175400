import * as S from './header-page.styled';

import refresh_icon from '../../../assets/icons/refresh.svg';
import { formatDate } from 'date-fns';
import { useHeaderPageHook } from './header-page.hook';
import { HeaderPageType } from './header-page.type';
import { InputSearchComponent } from '../inputSearch';

export const HeaderPageComponent = (props : HeaderPageType)=>{
    const headerPageHook = useHeaderPageHook();
    return(
        <S.HeaderPageContainer>
            <span className="s3-bold">{props.title}</span>
            {props.onUpdate &&
                <S.HeaderPageUpdate>
                    <span className='b3-regular'>Hoje, {formatDate(headerPageHook.today, 'dd/MM/yyyy')}</span>
                    <button className='update-button' onClick={props.onUpdate}>
                        <img src={refresh_icon} alt="Icone de recarregar" />
                    </button>
                </S.HeaderPageUpdate>
            }

            {props.onSearch &&
                <InputSearchComponent 
                    placeholder='Busca' 
                    setSearch={props.onSearch} 
                    refetch={props.refetch} 
                    filters={props.filters} 
                    selectedFilter={props.selectedFilter}
                    setSelectedFilter={props.setSelectedFilter}
                />
            }
        </S.HeaderPageContainer>
    );
}