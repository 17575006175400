import { useQuery } from "react-query";
import { BenkiTab } from "../benki";
import { ReconhecerCardTab } from "../reconhecerCard";
import { responseStatus } from "../../../../api/api-request.service";
import { useRewardCalendarService } from "../../../../services/reward-calendar.service";
import { RewardCalendarInterface } from "../../../../models/reward-calendar.interface";
import { useState } from "react";
import { RewardsTabsType } from "./rewards.type";
import { useAppSelector } from "../../../../hooks/hooks";

export function useRewardsHook() {
    const isManager = useAppSelector(state => state.user.isManager);
    const [selectedTab, setSelectedTab] = useState<keyof RewardsTabsType>('Benki');
    const [switchEnabled, setSwitchEnabled] = useState(false);
    const [search, setSearch] = useState('');
    const [reload, setReload] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState('MONETIZATION-ASC');

    const filters =[
        {value: 'MONETIZATION-DESC', label: 'Maior valor'},
        {value: 'MONETIZATION-ASC', label: 'Menor valor'},
        {value: 'ISACTIVE-TRUE', label: 'Ativas'},
        {value: 'ISACTIVE-FALSE', label: 'Pausadas'},
    ];

    const tabs = {
        'Benki': <BenkiTab selectedFilter={selectedFilter} search={search} reload={reload}/>,
        'Cartão reconhecer': <ReconhecerCardTab selectedFilter={selectedFilter} search={search} reload={reload}/>
    };

    const tabsList = Object.keys(tabs);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);


    const rewardsCalendarService = useRewardCalendarService();
    
    const {
        data: rewardsCalendarData,
        isLoading: isLoadingrewardsCalendar,
    } = useQuery<RewardCalendarInterface>('getRewardsCalendar', getRewardsCalendar);

    async function getRewardsCalendar() {
        const { status, data } = await rewardsCalendarService.getRewardsCalendar();
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }
    
    function onReload(){
        setReload(!reload);
    }
    function handleClose(){
        setIsOpenModal(false);
        onReload();
    }

    return {
        tabs,
        tabsList,
        search,
        switchEnabled,
        selectedTab,
        rewardsCalendarData,
        isLoadingrewardsCalendar,
        isManager,
        setSelectedFilter,
        selectedFilter,
        filters,
        setSwitchEnabled,
        setSelectedTab,
        setSearch,
        onReload,
        isOpenModal,
        setIsOpenModal,
        handleClose,
        isLoadingSubmit,
     
    }
}

