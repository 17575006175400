import { ChangeEvent } from "react";
import { z } from "zod";
import { RewardsInterface } from "../../../models/rewards.interface";



export type NewRewardsType = {
    onClose: Function;
    reward?: RewardsInterface; 
}

export type NewRewardsHookType = {
    onClose: Function;
    initialData?: RewardsInterface;
}

export type NewRewardsCheckType ={
    e:ChangeEvent<HTMLInputElement>;
    exampleName: {id?: string, exampleName:string};
}


export function getRewardsSchema(rewardsType?: string){
    const usageExamplesAttrs =  z.object({
        exampleName: z.string(),
        id: z.union([
            z.string().optional(),
            z.number().optional()
        ]).optional()
    });

    const rewardsSchema = z.object({
        title: z.string().min(1, { message: "O campo 'Titulo' é obrigatório." }),
        points: z.union([
            z.number().min(1, { message: "O campo 'Pontos' é obrigatório." }), 
            z.string().min(1, { message: "O campo 'Pontos' é obrigatório." })
        ]),
        monetization : z.union([
            z.number().min(1, { message: "O campo 'Recompensa' é obrigatório." }),
            z.string().min(1, { message: "O campo 'Recompensa' é obrigatório." })
        ]),
        aboutText: z.string().min(1, { message: "O campo 'Descrição' é obrigatório." }),
        aboutTheBenefit: z.string().min(1, { message: "O campo 'Benefício' é obrigatório." }),
        usageExamples: rewardsType === 'BENKI' ? 
            z.array(usageExamplesAttrs).min(1, { message: "Pelo menos um exemplo de uso deve ser selecionado" }):
            z.array(usageExamplesAttrs).optional()
    });

    
    return rewardsSchema;
}








