import { useState } from 'react';
import { PerformanceFilterComponent } from '../../../components/performanceFilter';
import * as S from './given.styled';

import culture_icon from '../../../../assets/images/dev/culture 2.svg';
import { LineChartComponent } from '../../../components/lineChart';
import { useGivenHook } from './given.hook';
import { baseUrl } from '../../../../api/api';
import { GivenType } from './given.type';
import { LoadingComponent } from '../../../components/loading';

export const GivenTab = ({ reload }: GivenType) => {

    const hook = useGivenHook({ reload });
    return (
        <S.GivenContainer>
            {hook.isLoadingCategoriesStatisticsData || hook.isRefetchingCategoriesStatisticsData ?
                <LoadingComponent containerHeight={300} /> :
                <>
                    <S.GivenFilters>
                        <PerformanceFilterComponent selectedItem={hook.selectedFilter} setSelectedItem={hook.setSelectedFilter} />
                        <S.Points>{hook.userData?.totalRemainingPoints ?? 0}pts Disponíveis</S.Points>
                    </S.GivenFilters>
                    <S.GivenContent>
                        {hook.categoriesStatisticsData?.rows.map((item, index) => (
                            <S.GivenCard>
                                <S.GivenCardTitle>
                                    <img src={`${baseUrl}/${item.category.svg?.content}`} alt={`Icone de ${item.category.name}`} />
                                    <span className='b2-regular'>{item.category.name}</span>
                                </S.GivenCardTitle>
                                <S.GivenCardDetails>
                                    <span className="s3-regular">{item.totalOfPeriod || 0} pts</span>
                                    <span className="b3-regular">{item.relativePercentage > 0 && '+'}{item.relativePercentage || 0}% {hook.selectedFilter === 'Mensal' ? "esse mês" : "essa semana"}</span>
                                </S.GivenCardDetails>
                                {/* <LineChartComponent/> */}
                            </S.GivenCard>
                        ))}

                    </S.GivenContent>
                </>
            }
        </S.GivenContainer>
    );
}
