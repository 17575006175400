import { useState } from "react";
import { EmployeeInterface } from "../../../../models/employee.interface";
import { RewardEmployeeModalHookType } from "./reward-employee-modal.type";
import { useAppSelector } from "../../../../hooks/hooks";

export function useRewardEmployeeModalHook({onClose} : RewardEmployeeModalHookType){
    const  [selectedEmployee, setSelectedEmployee] = useState<EmployeeInterface>();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const userAuth = useAppSelector(state=> state.user);
    
    function handleClose(){
        onClose();
    }

    return{
        selectedEmployee,
        isOpenModal,
        userAuth,
        setIsOpenModal,
        setSelectedEmployee,
        handleClose
    }
}