import { z } from 'zod'
import { isEmailValid } from '../../../../utils/validations';

export type ForgoutPasswordType = {
    
}

export const forgoutPasswordSchema = z.object({
    email: z.string().refine((value) => {
        return isEmailValid(value);
    }, {
        message: "O campo deve ser um e-mail válido",
    }),
});


export type ForgoutPasswordSchemaType = z.infer<typeof forgoutPasswordSchema>;