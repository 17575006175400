import styled from "styled-components";

export const ManagerCardContainer = styled.div`
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    max-width: 342px;
    cursor: pointer;

    border-radius: 8px;
    background: var(--minimal-color);

    img{
        width: 64px;
        height: 64px;
        border-radius: 96px;
        object-fit: cover;
    }
`;

export const ManagerCardContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    width: 234px;
`;

export const ManagerCardDetails = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    .config-icon{
        width: 16px;
        height: 16px;
    }
`;

export const ManagerCardRewards = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding-bottom: 4px;
    overflow-x: scroll;
`;

export const ManagerCardRewardsItem = styled.div`
    display: flex;
    padding: 2px 4px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: 4px;
    background: var(--minimal);

    img{
        display: flex;
        width: 20px;
        height: 20px;
        padding: 3px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background: var(--grey-color-40);
        border-radius: 128px;
        
        
    }

    .active{
        background-color: var(--blue-color-100);
    }
   
  
    
`;

