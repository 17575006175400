import * as S from './reward-card.styled';
import { useState } from 'react';
import { SwitchComponent } from '../switch';
import { formatDate } from "date-fns";
import { ptBR } from "date-fns/locale";
import config_icon from '../../../assets/icons/config.svg';
import calendar_grey_icon from '../../../assets/icons/calendar_grey.svg';
import { RewardCardType } from './reward-card.type';
import { baseUrl } from '../../../api/api';
import { useRewardCardHook } from './reward-card.hook';
import { CardEdit } from '../cardEdit';
import { useRewardsHook } from '../../modules/rewards/home/rewards.hook';
import { formatPrice } from '../../../utils/validations';
import { responseStatus } from '../../../api/api-request.service';
import { DialogComponent } from '../dialog';
import { NewRewards } from '../NewRewards';
import { ModalDeleteReward } from '../modalDeleteReward';


export const RewardCardComponent = ({ rewards }: RewardCardType) => {
    const rewardCalendarData = useRewardsHook();
    const [rewardsState, setRewardsState] = useState(rewards);
    const rewardsHook = useRewardCardHook()

    const positivePercentage = (rewards.statistics?.countPositive ? (rewards.statistics.countPositive / 100) * 100 : 0).toFixed(1);
    const negativePercentage = (rewards.statistics?.countNegative ? (rewards.statistics.countNegative / 100) * 100 : 0).toFixed(1);



    return (
        <div>
            <S.RewardCardContainer>
                <img className='rewards-image' src={`${baseUrl}/${rewards.rewardType?.banner?.content}`} alt="Imagem da recompensa" />
                <S.RewardCardContent>
                    <S.RewardCardActions>
                        <SwitchComponent
                            label={rewardsState.isActive ? "Ativada" : "Desativada"}
                            enabled={!!rewardsState.isActive}
                            onClick={async () => {
                                const response = await rewardsHook?.handlerActive(!rewardsState.isActive, rewardsState)

                                setRewardsState(response.rewards)
                            }}
                        />

                        <CardEdit options={rewardsHook.editOptions} />

                    </S.RewardCardActions>
                    <S.RewardCardPoints>

                        <span className="s4-bold value">{rewards.monetization ? formatPrice(rewards.monetization) : ' '}</span>

                        <span className="b3-regular points">por {rewards.points} pts</span>
                    </S.RewardCardPoints>
                    <S.RewardCardDate>
                        <img src={calendar_grey_icon} alt="Icone de Calendário" />
                        <span className="b3-regular">{formatDate(new Date(rewardCalendarData?.rewardsCalendarData?.startActivationDay ?? ''), "dd MMMM ", { locale: ptBR })}</span>
                        -
                        <span className="b3-regular">{formatDate(new Date(rewardCalendarData?.rewardsCalendarData?.endActivationDay ?? ''), "dd MMMM ", { locale: ptBR })}</span>
                    </S.RewardCardDate>
                    <S.RewardCardEmployee>
                        <div className='count-employee'>
                            <span className="b3-regular">{rewards.statistics?.countAvaiableToCollect}</span>
                        </div>
                        <p className="c1-regular">Funcionários possuem saldo em conta para resgate</p>
                    </S.RewardCardEmployee>
                    <S.RewardCardEvolution>
                        <div className='item'>
                            <span className='c1-regular'>Positivo</span>
                            <span className='c1-bold up'>{positivePercentage}% </span>
                        </div>
                        <div className='item'>
                            <span className="c1-regular">Negativo</span>
                            <span className='c1-bold down'>{negativePercentage}%</span>
                        </div>
                    </S.RewardCardEvolution>
                </S.RewardCardContent>
            </S.RewardCardContainer >

            <DialogComponent
                title='Editar recompensa'
                width={660}
                content={<NewRewards onClose={()=>rewardsHook.setShowEditModal(false)} reward={rewards}/>}
                isOpen={rewardsHook.showEditModal}
                onClose={() => rewardsHook.setShowEditModal(false)}
                onOpen={() => rewardsHook.setShowEditModal(true)}
            >
                <></>
            </DialogComponent>

            <DialogComponent
                title='Excluir recompensa'
                width={450}
                content={<ModalDeleteReward onClose={()=>rewardsHook.setShowRemoveModal(false)} reward={rewards}/>}
                isOpen={rewardsHook.showRemoveModal}
                onClose={() => rewardsHook.setShowRemoveModal(false)}
                onOpen={() => rewardsHook.setShowRemoveModal(true)}
            >
                <></>
            </DialogComponent>
        </div>
    );
}
