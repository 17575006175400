import { useCardEditHook } from './cardEdit.hook'
import * as S from './cardEdit.styled'
import { CardEditType } from './cardEdit.type'

import edit_icon from '../../../assets/icons/Edit.svg';

export const CardEdit = ({options} : CardEditType) => {
    const cardEditHook = useCardEditHook();
    return (
        <S.CardEditContainer>
            <img src={edit_icon} alt='Icone editar' onClick={()=>cardEditHook.setIsOpen(true)}/>
            {cardEditHook.isOpen &&
                <S.CardEditContent ref={cardEditHook.cardEditRef}>
                    {options.map(option=>(
                        <span className="b2-regular" onClick={()=>option.action()}>{option.title}</span>
                    ))}
                </S.CardEditContent>
            }            

        </S.CardEditContainer>
    )


}