import * as S from "./new-rewards.styled";

import { ButtonComponent } from '../button/index';
import { ModalSuccess } from "../modalSuccess";
import { NewRewardsType } from './new-rewards.type';
import { DropdownComponent } from '../dropdown/index';
import { useNewRewardsHook } from "./new-rewards.hook";

export const NewRewards = ({reward, onClose}: NewRewardsType) => {
    const hook = useNewRewardsHook({onClose, initialData: reward});

    return (
        <>
            {hook.showModalSuccess ? (
                <ModalSuccess message={`Recompensa ${reward?.id ? 'editada' : 'cadastrada'} com sucesso!`} />
            ) : (
                <>
                    <form
                        onSubmit={hook.handleSubmit(hook.onSubmit)}
                    >
                        <S.ContainterRewards>

                            <S.Header>
                                <div>
                                    <p className="c1-regular">Tipo de Recompensa</p>
                                    <DropdownComponent onSelect={(value: string) => hook.setDropdownSelectedItem(value)}
                                        options={hook.options}
                                        placeholder="Escolha o tipo recompensa"
                                        value={hook.dropdownSelectedItem}
                                        bgColor="white"
                                        minHeight={44}
                                        minWidth={281}
                                    />
                                </div>

                                <div>
                                    <p className="c1-regular">Título</p>
                                    <input type="text" className="b2-regular inputTitulo" placeholder="Título" {...hook.register("title")} />
                                    <span className="c1-regular error">{hook.errors.title?.message}</span>
                                </div>
                            </S.Header>
                            <S.ContainerPoints>
                                <div>
                                    <p className="c1-regular">Pontuação</p>
                                    <input type="text" className="b2-regular " placeholder="Pontuação" {...hook.register("points")} />
                                    <span className="c1-regular error">{hook.errors.points?.message}</span>
                                </div>
                                <div>
                                    <p className="c1-regular">Recompensa</p>
                                    <input type="text" className="b2-regular " placeholder="Recompensas"  {...hook.register("monetization")} />
                                    <span className="c1-regular error">{hook.errors.monetization?.message}</span>
                                </div>
                            </S.ContainerPoints>

                            <S.ContainerDescribre>
                                <p className="c1-regular">Descrição</p>
                                <textarea className="b2-regular" placeholder="Descrição" {...hook.register("aboutText")}></textarea>
                                <span className="c1-regular error">{hook.errors.aboutText?.message}</span>
                            </S.ContainerDescribre>

                            <S.ContainerBenefit>
                                <p className="c1-regular">Sobre o benefício</p>
                                <textarea className="b2-regular" placeholder=" Sobre o benefício" {...hook.register('aboutTheBenefit')}></textarea>
                                <span className="c1-regular error">{hook.errors.aboutTheBenefit?.message}</span>
                            </S.ContainerBenefit>

                            {hook.rewardsTypeData?.rows.find(rt=>rt.id?.toString() === hook.dropdownSelectedItem)?.name === 'BENKI' &&
                                <>
                                    <p className="c1-regular">Troque por:</p>
                                    <S.Replacement>
                                        {hook.categories.map(category => (
                                            <label htmlFor={`check-${category.title}`}>
                                                <input id={`check-${category.title}`} type="checkbox" checked={!!hook.selectExampleName.find(en=>en.exampleName === category.title)} onChange={(e) => hook.handleCheck({ e, exampleName: { exampleName: category.title } })} />
                                                <img src={category.icon} alt={`Icone de ${category.title}`} />
                                                <p>{category.title}</p>
                                            </label>
                                        ))}
                                    </S.Replacement>
                                    <span className='c1-regular error'>{hook?.errors.usageExamples?.message}</span>
                                </>
                            }
                        </S.ContainterRewards>
                        <S.ButtonContainer>
                            <ButtonComponent
                                type="submit"
                                buttonStyle="primary"
                                disabled={hook.isLoadingSubmit}
                            >
                                {hook.isLoadingSubmit ? "Salvando..." : "Salvar Recompensa"}
                            </ButtonComponent>
                        </S.ButtonContainer>
                    </form>
                </>
            )}
        </>
    );
};
