import { SLIDER_LIMIT } from '../../../../utils/config';
import { CardConf } from '../../../components/cardConfig';
import { LoadingComponent } from '../../../components/loading';
import { useCardConfigPageHook } from './cardConfigPage.hook';
import * as S from './cardConfigPage.syled';

export const CardConfiPage = ()=>{
    const cardConfigPageHook = useCardConfigPageHook();

    return(
        <S.Container>
            {cardConfigPageHook.isLoading ? 
                <LoadingComponent containerHeight={300}/> :
                <>
                    <section>
                        <span className='s4-bold'>Orçamento por setor</span>
                        <CardConf 
                            title="Setor"   
                            subtitle="Valor"
                            maxValue={SLIDER_LIMIT}
                            minValue={100}
                            values={cardConfigPageHook.sectorsData?.rows?.map(sector=>({name: sector.name! , value: sector.limitEmployeeRewards!}))}
                            onChange={cardConfigPageHook.onChangeLimitSectors}
                            isLoading={cardConfigPageHook.isLoadingSubmit}
                        />
                    </section>
                    
                    <section>
                        <span className='s4-bold'>Limite de pontos por gestor</span>
                        <CardConf 
                            title="Gestores" 
                            subtitle=" Limite de reconhecimentos"
                            maxValue={SLIDER_LIMIT}
                            minValue={cardConfigPageHook.generalSettingsData?.limitEmployeeRewards}
                            values={[{name: 'Gestor' , value: cardConfigPageHook.generalSettingsData!.limitManagerRewards}]}
                            onChange={cardConfigPageHook.onChangeLimitManagerRewards}
                            isLoading={cardConfigPageHook.isLoadingSubmit}
                        />
                    </section>
                </>
            }
        </S.Container>
    )
}