import { createSlice } from '@reduxjs/toolkit';
import { UserInterface } from '../models/user.interface';


const initialState: UserInterface = {
    user_id: '',
    isManager: null,
    data: {
        position: '',
        name: '',
        profilePic: [],
        groups: [],
    }
}

const userSlice = createSlice({
    name: 'User',
    initialState,
    reducers: {
        setUser(state, action: { payload: UserInterface }) {
            let isManager = false;
            state.user_id = action.payload.user_id;
            state.data = action.payload.data;
            action.payload.data.groups.map(group => {
                if (group.name === 'HUMAN_RESOURCERS') {
                    isManager = true;
                }
            });
            state.isManager = isManager;
        },
    },
});

export const { setUser } = userSlice.actions;
export const userReducer = userSlice.reducer;
