import { useState } from "react";
import { useQuery } from "react-query";
import { useRecognitionCategoryService } from "../../../services/recognition-category.service";
import { RecognitionCategoryDataType, RecognitionCategoryInterface } from "../../../models/recoginition-category";
import { responseStatus } from "../../../api/api-request.service";


export function useSelectHook(){
    const [isOpen, setIsOpen] = useState(false);
    const recognitionCategoryService = useRecognitionCategoryService();

    const {
        data : recognitionCategoriesData, 
        isLoading : isLoadingRecognitionCategoriesData
    } = useQuery<RecognitionCategoryDataType>('getAllRecognitionCaegories', getRecognitionsByUserId);

    async function getRecognitionsByUserId(){
        const {status, data} = await recognitionCategoryService.getAllRecognitionCategories();
        if(status === responseStatus.SUCCESS){
            return data;
        }
    }

    function handleSelect(setSelected : Function, selected: RecognitionCategoryInterface){
        setIsOpen(false);
        setSelected(selected)
    }


    return{
        isOpen,
        setIsOpen,
        recognitionCategoriesData,
        isLoadingRecognitionCategoriesData,
        handleSelect
    }
}