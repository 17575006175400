import styled from "styled-components";

export const TopBarContainer = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    width: 100%;
    padding: 16px 24px 16px 32px;
    justify-content: space-between;
    align-items: center;
    
    background: var(--minimal-color);
    z-index: 1;
`;

export const TopBarTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    align-items: center;
    justify-content: center;

    span{
        color: var(--blue-color-100);
    }
`;

export const TopBarContent = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    position: relative;
`;

export const TopBarNotifications = styled.div`
    display: flex;
    width: 32px;
    height: 32px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    & img{
         cursor:pointer;
    }   
`;

export const TopBarProfile = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const TopBarProfilePic = styled.div`
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    border-radius: 96px;

    
    img{
        flex: 1 0 0;
        align-self: stretch;
        overflow: hidden;
        border-radius: 96px;
    }
`;
export const PopoverContent = styled.div`
   
    all: unset;
    border-radius: 4px;
    padding: 20px;
    border:none;
    z-index:7; 
    position:absolute;
    
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;


`;


export const FieldserPopover = styled.fieldset`
  background-color: var(--white-color);
  display: flex;
  flex-direction:column;
  border:none;
  transform: translateX(-250px);
  width:369px;
  height: 250px;
  overflow-y: scroll;
  
  position:absolute;

  padding: 12px;

  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 16px;
  background-color: var(--white-color);
  box-shadow: 2px 2px 3px rgba(0,0,0,0.2);


  .title{
    padding-left:16px;

  }

`;

export const PopoverArrow = styled.div`
   fill: var(--white-color);
`;



