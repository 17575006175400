import { ChangeEvent, useState } from "react";
import { CardConfiPage } from "../cardConfigPage";
import { ManagerListTab } from "../managerList";
import { EmployeeTabsType } from "./employee.type";
import { EmployeeListTab } from "../../../components/employeeList";
import { useEmployeeService } from "../../../../services/employee.service";
import { useAppDispatch } from "../../../../store";
import { responseStatus } from "../../../../api/api-request.service";
import { queryClient } from "../../../../utils/query-client";

export function useEmployeeHook() {
    const [search, setSearch] = useState('');
    const [reload, setRealod] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
    const [selectedTab, setSelectedTab] = useState<keyof EmployeeTabsType>('Funcionários');
    const [selectedFilter, setSelectedFilter] = useState('RECOGNITIONS-ASC');
    const employeeService = useEmployeeService();
    const [loadingUpload, setLoadingUploading] = useState(false);

    const filters = [
        { value: "RECOGNITIONS-DESC", label: "Mais reconhecimentos" },
        { value: "RECOGNITIONS-ASC", label: "Menos reconhecimentos" },
        { value: "POINTS-DESC", label: "Mais pontos" },
        { value: "POINTS-ASC", label: "Menos pontos" },
    ];

    const tabs = {
        'Funcionários': <EmployeeListTab search={search} reload={reload} selectedFilter={selectedFilter} />,
        'Gestores': <ManagerListTab search={search} reload={reload} selectedFilter={selectedFilter} />,
        'Configurações': <CardConfiPage />
    };
    const tabsList = Object.keys(tabs);

    function onReload() {
        setRealod(!reload);
    }

    function handleClose() {
        setIsOpenModal(false);
        onReload();
    }

    function handleSelectFile() {
        document.getElementById('upload-file')?.click();
    }

    async function handleChangeFile(e: ChangeEvent<HTMLInputElement>) {
        setLoadingUploading(true);
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];

            const response = selectedTab === 'Funcionários' ?
                await employeeService.uploadEmployees(file) :
                await employeeService.uploadManagers(file);

            const { status } = response;
            if (status === responseStatus.SUCCESS) {
                setIsOpenSuccessModal(true);
                setTimeout(() => {
                    setIsOpenSuccessModal(false);
                    queryClient.refetchQueries('getEmployees');
                    queryClient.refetchQueries('getManagers');
                }, 3000);

                
            }
        }
        setLoadingUploading(false);
        const input = document.getElementById('upload-file') as HTMLInputElement;
        if(input){
            input.value = '';
        }
    }


    return {
        tabs,
        tabsList,
        selectedTab,
        search,
        reload,
        isOpenModal,
        filters,
        selectedFilter,
        loadingUpload,
        isOpenSuccessModal,
        setIsOpenSuccessModal,
        handleChangeFile,
        handleSelectFile,
        setSelectedFilter,
        setIsOpenModal,
        onReload,
        setSearch,
        setSelectedTab,
        handleClose
    }
}
