import { useEffect, useRef, useState } from "react";
import { FilterType, InputSearchHookType } from "./type";

export function useInputSearchHook(props : InputSearchHookType){
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const [localSearch, setLocalSearch] = useState('');
    const [showPopOver, setShowPopOver] = useState(false);
    const popoverRef = useRef<HTMLDivElement>(null);

    

    function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
        setLocalSearch(e.target.value);
        if(props.setSearch){
            props.setSearch(e.target.value);
        }
    }

    function handleSelectFilter(selected: FilterType){
        props.setSelectedFilter!(selected.value);
        setShowPopOver(false);
    }

    function handleClickOutside(event: MouseEvent){
        if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
            setShowPopOver(false); 
        }
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedSearch(localSearch);
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [localSearch]);

    useEffect(() => {
        if(props.refetch){
            props.refetch();
        }
    }, [debouncedSearch]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popoverRef]);

    return{
        localSearch,
        handleSearch,
        showPopOver,
        popoverRef,
        setShowPopOver,
        handleSelectFilter
    }
}
