import { ButtonType } from "./button.type";
import * as S from './button.styled';

export const ButtonComponent = ({
    children,
    type,
    height,
    width,
    buttonStyle,
    onClick,
    disabled
} : ButtonType)=>{
    return (
        <S.ButtonContainer
            className="b3-bold"
            buttonStyle={buttonStyle}
            disabled={disabled}
            onClick={onClick} 
            type={type ?? 'button'} 
            height={height}
            width={width}
        >
            {children}
        </S.ButtonContainer>
    );
}