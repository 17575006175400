import styled from "styled-components";

interface AppContentProps{
    showPage : boolean;
}

export const AppContainer = styled.main`
    display: flex;

`;

export const AppMain = styled.main`
    background-color: var(--white-2-color);
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
`;

export const AppContent = styled.section<AppContentProps>`
    padding: 32px;
    transition: opacity 0.5s;
    opacity: ${props => props.showPage ? 1 : 0};

`;

export const AppContainerHome = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    .empty-card{
        width: 100%;
        height: 300px;
        align-items: center;
        justify-content: center;
        display: flex;
    }
`;

export const AppTopBar = styled.section`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;

    .actions{
        display: flex;
        gap: 16px;
    }
`;

export const AppUpdate = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    .search-container{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }
`;


export const AppUpdatesContainer = styled.section`
    display: flex;
    width: 100%;
    gap: 16px;
    align-items: center;
    justify-content: flex-start;
`;

export const AppUpdatesContent = styled.section`
    display: flex;
    align-items: center;
    gap: 16px;
    padding-bottom: 4px;

    overflow: scroll;
`;