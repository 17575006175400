import { z } from 'zod'
import { isEmailValid } from '../../../../utils/validations';

export type ResetPasswordType = {
    
}

export const resetPasswordSchema = z.object({
    email: z.string().refine((value) => {
        return isEmailValid(value);
    }, {
        message: "O campo deve ser um e-mail válido",
    }),
    password: z.string().min(8, 'A senha deve ter no minimo 8 caracteres'),
    confirmPassword: z.string().min(8, 'A senha deve ter no minimo 8 caracteres'),
});


export type ResetPasswordSchemaType = z.infer<typeof resetPasswordSchema>;