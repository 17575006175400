import { useState } from "react";
import { ModalDeleteUserHookType } from "./modalDeleteUser.type";
import { useMutation } from "react-query";
import { queryClient } from "../../../utils/query-client";
import { useEmployeeService } from "../../../services/employee.service";
import { responseStatus } from "../../../api/api-request.service";

export function useModalDeleteUserHook({ onClose, user, onReload}: ModalDeleteUserHookType) {
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const employeeService = useEmployeeService();

    const { mutate, isLoading: isLoadingSubmit } = useMutation(
        () => handleSubmitForm(), {
        onSuccess: () => {
            queryClient.invalidateQueries('deleteUser');
        },
    });

    async function handleSubmitForm() {
        const {status} = await employeeService.deleteUser({userId : user.id!});
        if(status === responseStatus.SUCCESS){
            setShowModalSuccess(true);
            setTimeout(()=>{
                onReload();
            }, 1000);
        }
    }

    async function onSubmit() {
        mutate();      
    }

    return{
        showModalSuccess,
        isLoadingSubmit,
        setShowModalSuccess,
        onSubmit
    }
}
