import { FETCH_LIMIT_TABLE } from "../../../utils/query-client";
import { PaginationType } from "./type";

export function usePaginationHook({page, setPage, totalItems} : PaginationType){
    
    function handleNextPage(){
        const totalPage = Math.ceil(totalItems / FETCH_LIMIT_TABLE);
        if(page < totalPage){
            setPage(page+1);
        }
    }

    function handlePreviusPage(){
        if(page > 1){
            setPage(page-1);
        }
    }

    return{
        handleNextPage,
        handlePreviusPage
    }
}