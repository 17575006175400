import styled from "styled-components";

interface UpdateCardContainerProps{
    'data-width'? : number;
}

export const UpdateCardContainer = styled.div<UpdateCardContainerProps>`
    display: flex;
    width: ${props=>props['data-width'] ? `${props["data-width"]}px` : '282px'};
    /* height: 236px; */
    padding: 16px 20px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;

    border-radius: 8px;
    background: var(--minimal-color);

    img{
        display: flex;
        width: 32px;
        height: 32px;
        padding: 2px;
        justify-content: center;
        align-items: center;
        gap: 10px;

        border-radius: 128px;
        /* background: var(--blue-color-100); */
    }

    .adm-name{
        color: var(--grey-color-60);
        height: 15px;
    }

`;


export const UpdateCardContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    .pending{
        width: 200px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
`;

export const UpdateCardActions = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

`;

export const UpdateCardPoints = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;   

    span{
        color: var(--blue-color-100);
    }

    img{
        display: flex;
        width: 32px;
        height: 32px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;

        border-radius: 4px;
        background: var(--Blue-B-20, #D2E5F3);
    }
`;