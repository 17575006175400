import { Route, Routes } from "react-router-dom";
import { HomePage } from "./views/modules/home/home";
import { HistoryPage } from "./views/modules/home/history";
import { EmployeePage } from "./views/modules/employee/home";
import { PerformancePage } from "./views/modules/performance/home";
import { RewardsPage } from "./views/modules/rewards/home";
import { useAppSelector } from "./hooks/hooks";

export function RoutesPath() {
    const isManager = useAppSelector(state => state.user.isManager);
    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/historico" element={<HistoryPage />} />
            {isManager && <Route path="/funcionarios" element={<EmployeePage />} />}
            <Route path="/desempenho" element={<PerformancePage />} />
            <Route path="/recompensas" element={<RewardsPage />} />
        </Routes>
    );
}