import { useState } from "react";
import { ModalDeleteRewardHookType } from "./modalDeleteReward.type";
import { useMutation } from "react-query";
import { queryClient } from "../../../utils/query-client";
import { useRewardsService } from "../../../services/rewards.service";
import { responseStatus } from "../../../api/api-request.service";

export function useModalDeleteRewardHook({ onClose, reward}: ModalDeleteRewardHookType) {
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const rewardService = useRewardsService();

    const { mutate, isLoading: isLoadingSubmit } = useMutation(
        () => handleSubmitForm(), {
        onSuccess: () => {
            queryClient.invalidateQueries('deleteReward');
        },
    });

    async function handleSubmitForm() {
        const {status} = await rewardService.deleteRewards(reward);
        if(status === responseStatus.SUCCESS){
            setShowModalSuccess(true);
            setTimeout(()=>{
                queryClient.refetchQueries('getRewardsBenki');
                queryClient.refetchQueries('getRewardsCard');
            }, 1000);
        }
    }

    async function onSubmit() {
        mutate();      
    }

    return{
        showModalSuccess,
        isLoadingSubmit,
        setShowModalSuccess,
        onSubmit
    }
}
