import styled from "styled-components";

export const HeaderPageContainer = styled.div`
    display: inline-flex;
    align-items: center;
    gap: 16px;

    .search-container{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }
`;

export const HeaderPageUpdate = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    .update-button{
        display: flex;
        width: 32px;
        height: 32px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;

        border-radius: 4px;
        background: var(--grey-color-20);
        border: none;
        outline: none;
        cursor: pointer;
    }
`;
