import * as S from './manager-list.styled';

import { CardSidebarTopPageEmployee } from '../../../components/cardSidebarTopPageEmployee';
import { ListEmployee } from '../../../components/listEmployee';
import { useManagerListHook } from './manager-list.hook';
import { LoadingComponent } from '../../../components/loading';
import { ManagerListType } from './manager-list.type';
import { PaginationComponent } from '../../../components/pagination';

export const ManagerListTab = (props: ManagerListType) => {
    const hook = useManagerListHook(props);
    return (
        <S.ManagerListContainer>
            <CardSidebarTopPageEmployee role='Gestor' />
            {hook.isLoadingManagersData || hook.isRefetchingManagersData ?
                <LoadingComponent containerHeight={200}/> :
                <>
                    {hook.managerData?.count === 0 ?
                        <span className="c3-regular empty-manager">Nenhum gestor encontrado</span> :
                        <>
                            {hook.managerData?.rows.map((manager) => (
                                <ListEmployee key={`gestor-${manager.id}`} user={manager} type="MANAGER" showAmountPoinst={true} onReload={hook.refetchManager}/>
                            ))}
                        </>
                    }

                    <div className='pagination-container'>
                        <PaginationComponent page={hook.page} setPage={hook.setPage} totalItems={hook.managerData?.count || 0}/>
                    </div>

                </>
            }
        </S.ManagerListContainer>

    );
}