import { useHandleRequest } from "../api/api-request.service";

export function useNotificationService(){
  const handleRequestHook = useHandleRequest();
  async function getNotificationByUserId(user_id:string){
    return handleRequestHook.handleRequest(`/notifications/by/user/${user_id}`, 'GET');         
}

return {
  getNotificationByUserId
}
}