import * as Dialog from '@radix-ui/react-dialog';
import * as S from './styled';

import check_icon from '../../../assets/icons/check.svg';
import error_icon from '../../../assets/icons/error.svg';
import coins_icon from '../../../assets/icons/coin.svg';

import { CardRewardProgressType } from './type';
import { baseUrl } from '../../../api/api';
import { ButtonComponent } from '../button';
import { useCardRewardProgressHook } from './hook';
import { formatDate } from 'date-fns';

export const CardRewardProgress = ({ recognition, onClose }: CardRewardProgressType) => {
    const hook = useCardRewardProgressHook();
    const authorIsRH = recognition.author?.groups.find((group: any) => group.name === "HUMAN_RESOURCERS");
    return (
        <S.Container>
            <S.Content>
                <div className="history">
                    {authorIsRH ?
                        <S.ProgressItem className={'active'}>
                            <S.ProgressNumber>
                                <div className='number'>1</div>
                                <div className='line'></div>
                            </S.ProgressNumber>
                            <S.ProgressStatus>
                                <span className='b2-regular'>Reconhecimento gerado pelo RH.</span>
                                <span className='b3-bold'> {recognition.author?.name}: Aprovação automática</span>
                                <span className='b3-regular date'>{formatDate(recognition.createdAt!, 'dd/MM/yyyy')}</span>
                            </S.ProgressStatus>
                        </S.ProgressItem> :
                        <>
                            <S.ProgressItem className={'active'}>
                                <S.ProgressNumber>
                                    <div className='number'>1</div>
                                    <div className='line'></div>
                                </S.ProgressNumber>
                                <S.ProgressStatus>
                                    <span className='b2-regular'>Solicitado para o segundo gestor</span>
                                    <span className='b3-bold'>Solicitação enviada para o gestor {recognition.author?.managerTeam?.userSecondaryManager?.name}</span>
                                    <span className='b3-regular date'>{formatDate(recognition.createdAt!, 'dd/MM/yyyy')}</span>
                                </S.ProgressStatus>
                            </S.ProgressItem>

                            <S.ProgressItem className={hook.getIsActive('CONFIRMED', recognition.status) || (hook.getIsActive('CANCELED', recognition.status)) ? 'active' : ''}>
                                <S.ProgressNumber>
                                    <div className='number'>2</div>
                                    <div className='line'></div>
                                </S.ProgressNumber>
                                <S.ProgressStatus>
                                    <span className='b2-regular'>Confirmação do Gestor</span>
                                    {hook.getIsActive('CONFIRMED', recognition.status) || hook.getIsActive('CANCELED', recognition.status) ?
                                        <>

                                            {recognition.author?.managerTeam?.userSecondaryManager ?
                                                <>
                                                    <span className='b3-bold'>
                                                        O usuário {(recognition.status === 'CANCELED' && recognition.fkCanceledByUserSecondaryManagerId) ? `${recognition.canceledByUserSecondaryManager?.name} rejeitou` : `${recognition.confirmedByUser?.name} aceitou`} a solicitação.
                                                    </span>
                                                    {(recognition.confirmedAt || recognition.canceledAt) && <span className='b3-regular date'>{formatDate(recognition.confirmedAt || recognition.canceledAt!, 'dd/MM/yyyy')}</span>}
                                                </>
                                                :
                                                <>
                                                    <span className='b3-bold'>
                                                        Solicitação aprovada automaticamente por não existir um segundo gestor
                                                    </span>
                                                    <span className='b3-regular date'>{formatDate(recognition.confirmedAt!, 'dd/MM/yyyy')}</span>
                                                </>
                                            }
                                            {(recognition.status === 'CANCELED' && recognition.fkCanceledByUserSecondaryManagerId) &&
                                                <span className='c1-regular'>
                                                    Motivo: "{recognition.rejectReason}"
                                                </span>
                                            }
                                        </> :
                                        <>
                                            <span className='b3-bold'>
                                                Aguardando a aprovação do gestor {recognition.author?.managerTeam?.userSecondaryManager?.name}.
                                            </span>
                                            <div className='empty'></div>
                                        </>
                                    }
                                </S.ProgressStatus>
                            </S.ProgressItem>



                            {(recognition.status !== 'CANCELED' || (recognition.status === 'CANCELED' && recognition.fkCanceledByUserRHId)) &&
                                <S.ProgressItem className={(hook.getIsActive('REDEEMED', recognition.status) || (hook.getIsActive('CANCELED', recognition.status))) ? 'active' : ''}>
                                    <S.ProgressNumber>
                                        <div className='number'>3</div>
                                        <div className='line'></div>
                                    </S.ProgressNumber>
                                    <S.ProgressStatus>
                                        <span className='b2-regular'>Confirmação do RH</span>
                                        {hook.getIsActive('REDEEMED', recognition.status) ?
                                            <>
                                                <span className='b3-bold'>O RH {recognition.approvedByUser?.name} confirmou a solicitação</span>
                                                <span className='b3-regular date'>{formatDate(recognition.redeemedAt!, 'dd/MM/yyyy')}</span>
                                            </> :
                                            recognition.status === 'CANCELED' ?
                                                <>
                                                    <span className='b3-bold'>O RH {recognition.canceledByUserRH?.name} recusou a solicitação</span>
                                                    <span className='c1-regular'>
                                                        Motivo: "{recognition.rejectReason}"
                                                    </span>
                                                    <span className='b3-regular date'>{formatDate(recognition.canceledAt!, 'dd/MM/yyyy')}</span>

                                                </> :
                                                <>
                                                    <span className='b3-bold'>
                                                        Aguardando a aprovação do RH.
                                                    </span>
                                                    <div className='empty'></div>
                                                </>
                                        }
                                    </S.ProgressStatus>
                                </S.ProgressItem>
                            }
                        </>
                    }
                </div>

                {(hook.getIsActive('REDEEMED', recognition.status) || hook.getIsActive('APROVED', recognition.status) || hook.getIsActive('CANCELED', recognition.status)) &&
                    <S.FinalContainer>
                        <div className="top">
                            <img src={recognition.status === 'CANCELED' ? error_icon : check_icon} alt="Icone do status" />
                            <div className="top-right">
                                <span className='b3-bold'>Reconhecimento {recognition.status === 'CANCELED' ? 'recusado' : 'aprovado'}</span>
                                <div className="top-right-details">
                                    <div className='icon'>
                                        <img src={`${baseUrl}/${recognition.category?.svg.content}`} alt="Iocne da categoria" />
                                        <span className="b3-bold">+1</span>
                                    </div>
                                    <span className='b3-regular category'>{recognition.category?.name}</span>
                                </div>
                            </div>
                        </div>
                        <div className='bottom'>
                            <span className='b3-bold'>+{recognition.points || 0}pts</span>
                            <img src={coins_icon} alt="Icone de moedas" />
                        </div>
                    </S.FinalContainer>
                }

            </S.Content>

            <ButtonComponent buttonStyle='primary' onClick={onClose}>
                Entendi
            </ButtonComponent>
        </S.Container>
    )

}
