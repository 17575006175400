import styled from "styled-components";

interface ToastContainerProps{
    "data-active" : boolean;
}

export const ToastContainer = styled.div<ToastContainerProps>`
    position: fixed;
    top: 24px;
    right: ${props=>props['data-active'] ? 24 : -400}px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    gap: 24px;
    border-radius: 16px;
    width: 360px;
    background-color: var(--alert-red-color);
    z-index: 999;
    span{
        color: var(--white-color);
    }

    transition: right 1s ;
`;