import { RecognitionsStatusType } from "../../../utils/config";

export function useCardRewardProgressHook(){
    function getIsActive(status : RecognitionsStatusType, recognitionStatus? : string){
        switch (status as RecognitionsStatusType){
            case 'CONFIRMED':
                return recognitionStatus === 'CONFIRMED' || recognitionStatus === 'REDEEMED';
            case 'REDEEMED':
                    return recognitionStatus === 'REDEEMED';
            case 'CANCELED':
                        return recognitionStatus === 'CANCELED';
            case 'CANCELED':
                return recognitionStatus === 'APPROVED';
            default:
                return false
        }
          
    }

    return {
        getIsActive
    }
}