import { useMutation } from 'react-query';
import { ForgoutPasswordSchemaType } from './type';
import { queryClient } from '../../../../utils/query-client';
import { useAuthService } from '../../../../services/auth.service';
import { responseStatus } from '../../../../api/api-request.service';
import { LocalToken } from '../../../../utils/local-token';

export function useForgoutPasswordHook() {
    const authService = useAuthService();
    const {mutate, isLoading} = useMutation((data: ForgoutPasswordSchemaType) => handleForgoutPassword(data));
    
    
    //functions
    function onSubmit(data: ForgoutPasswordSchemaType){
        mutate(data);
    };

    async function handleForgoutPassword(payload: ForgoutPasswordSchemaType){
        const {status} = await authService.requestPasswordReset(payload);
        if(status === responseStatus.SUCCESS){
            window.location.href='/';
        }
    };

    return {
        handleForgoutPassword,
        onSubmit,
        isLoading
    };
}
