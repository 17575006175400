import { useHandleRequest } from "../api/api-request.service";


export function useUserLoginService() {
  const handleRequestHook = useHandleRequest();

  async function getUserLogin(user_id?: string) {
    return handleRequestHook.handleRequest(`/user/${user_id}`, 'GET');
  }

  return {
    getUserLogin,

  }

}