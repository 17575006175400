import { useHandleRequest } from "../api/api-request.service";
import { EmployeeDataType, EmployeeInterface } from "../models/employee.interface";
import { getDefaultQuery } from "../utils/query-client";

type GetAllManagersType = {
    search? : string,
    order?: string,
    withoutManager?: boolean;
    orderBy?: string;
    page?: number;
}

type CreateManagerType = {
    data : EmployeeInterface
}

export function useManagerService(){
    const handleRequestHook = useHandleRequest();
    async function getAllManagers({search, order, orderBy, withoutManager, page} : GetAllManagersType) : Promise<{ status: string; data: EmployeeDataType }>{
        const filters = [{orderBy}, {withoutManager}, {name: search}, {order}];
        const query = getDefaultQuery({filters, page});
        return handleRequestHook.handleRequest(`/user-manager${query}`, 'GET');         
    }

    async function createManager({data} : CreateManagerType) : Promise<{ status: string; data: EmployeeInterface }>{
        return handleRequestHook.handleRequest('/user-manager', 'POST', data);         
    }

    async function editManager({data} : CreateManagerType) : Promise<{ status: string; data: EmployeeInterface }>{
        return handleRequestHook.handleRequest(`/user/${data.id}`, 'PUT', data);         
    }

    return {
        getAllManagers,
        createManager,
        editManager
    }
}