import { MouseEventHandler } from "react"
import { RecognitionInterface } from "../../../models/recognition.interface";
import { z } from "zod";

export type RefuseRecognitionType = {
    onClose : MouseEventHandler<HTMLButtonElement>;
    recognition: RecognitionInterface;
    onCloseDetails: Function;
}

export type RefuseRecognitionHookType = {
    recognition : RecognitionInterface;
    onClose : Function;
    onCloseDetails: Function;
}

export const refuseRecognitionSchema = z.object({
    rejectReason : z.string().min(1, { message: "O campo 'Por que a solicitação foi recusada?' é obrigatório." }),
});

export type RefuseRecognitionSchemaType = z.infer<typeof refuseRecognitionSchema>;