import { useQuery } from "react-query";
import { EmployeeDataType } from "../../../../models/employee.interface";
import { responseStatus } from "../../../../api/api-request.service";
import { useEmployeeService } from "../../../../services/employee.service";
import { useRecognitionCategoryAnaliticsService } from "../../../../services/recognition-category-statistics.service";
import { useEffect, useState } from "react";
import { RecognitionCategoriesStatisticsDataType, RecognitionCategoriesStatisticsInterface } from "../../../../models/recognition-category-statistics.interface";
import { GivenHookType } from "./given.type";
import { useAppSelector } from "../../../../hooks/hooks";
import { useUserLoginService } from "../../../../services/user-login.service";
import { UserInterface } from "../../../../models/user.interface";
import { ManagerInterface } from "../../../../models/manager.interface";

export function useGivenHook({reload} : GivenHookType){
    const [selectedFilter, setSelectedFilter] = useState('Semanal');
    const recognitionCategoryStatisticsService = useRecognitionCategoryAnaliticsService();
    const user = useAppSelector(state=>state.user);

    const userService = useUserLoginService();

    const {
        data : categoriesStatisticsData, 
        isLoading : isLoadingCategoriesStatisticsData,
        isRefetching : isRefetchingCategoriesStatisticsData,
        refetch: refetchStastisticData
    } = useQuery<RecognitionCategoriesStatisticsDataType | undefined>('getEmployeesHanking', getAllStatistics);

    const {
        data: userData
    } = useQuery<ManagerInterface>('getUserManager', getManegerData);

    async function getAllStatistics() {
        const today = new Date();
        let startDate : Date;
        let endDate : Date;
    
        if (selectedFilter === 'Semanal') {
            // Encontrar o primeiro e último dia da semana atual
            const currentDay = today.getDay(); // 0 (Domingo) a 6 (Sábado)
            const firstDayOfWeek = new Date(today);
            firstDayOfWeek.setDate(today.getDate() - currentDay); // Definir para o início da semana
            const lastDayOfWeek = new Date(today);
            lastDayOfWeek.setDate(today.getDate() + (6 - currentDay)); // Definir para o final da semana
            startDate = firstDayOfWeek;
            endDate = lastDayOfWeek;
        } else if (selectedFilter === 'Mensal') {
            // Encontrar o primeiro e último dia do mês atual
            const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
            startDate = firstDayOfMonth;
            endDate = lastDayOfMonth;
        }
    
        const { status, data } = await recognitionCategoryStatisticsService.getAllStatistics(startDate!, endDate!);
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }

    async function getManegerData() {

        const { status, data } = await userService.getUserLogin(user.user_id.toString());
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }

    useEffect(()=>{
        refetchStastisticData();
    }, [reload, selectedFilter]);

    return {
        categoriesStatisticsData,
        isLoadingCategoriesStatisticsData,
        isRefetchingCategoriesStatisticsData,
        selectedFilter,
        userData,
        setSelectedFilter
    }

    
}
