import * as Dialog from '@radix-ui/react-dialog';
import * as S from './cardEmployeeReward.styled';

import coins from '../../../assets/icons/coin.svg';
import receipt from '../../../assets/icons/receipt.svg';

import { CardEmployeeRewardType } from './cardEmployeeReward.type';
import { Profile } from '../profile';
import { baseUrl } from '../../../api/api';
import { ButtonComponent } from '../button';
import { useCardEmployeeRewardHook } from './cardEmployeeReward.hook';
import { CardRewardProgress } from '../cardRewardProgress';
import { DialogComponent } from '../dialog';
import { RefuseRecognitionModal } from '../refuseRecognition';

export const CardEmployeeReward = ({ recognition, onClose, onSubmit, isLoading }: CardEmployeeRewardType) => {

    const hook = useCardEmployeeRewardHook({ recognition });

    return (
        <>
            <S.Content>

                <Profile user={recognition.user!} type={'EMPLOYEE'} height={178} />

                <S.points>
                    <p className='s3-bold'>+{recognition?.points}</p>
                    <img src={coins} alt="icone de moeda" />
                </S.points>
                <S.info>
                    <S.GestorWrapper>
                        <img src={receipt} alt="icone de receipt" />
                        <p className='c1-regular'>{recognition.author?.name}</p>
                    </S.GestorWrapper>

                    <S.ConquistaWrapper>
                        <img className='image-icon' src={`${baseUrl}/${recognition.category?.svg?.content}`} alt="Icone da categoria" />
                        <p className='c1-regular'>{recognition.category?.name}</p>
                    </S.ConquistaWrapper>
                </S.info>

                <S.Fieldset className='variant'>
                    <p className='c1-regular'>Motivo do reconhecimento</p>
                    <span className='b2-regular'>{recognition.reason}</span>
                </S.Fieldset>

                <div className='actions'>

                    <ButtonComponent buttonStyle='terciary' disabled={isLoading} onClick={() => hook.setShowProgress(true)}>
                        Ver andamento da recompensa
                    </ButtonComponent>
                    {
                        hook.isConfirmable &&
                        <div className='button-confirm-container'>
                            <ButtonComponent buttonStyle='primary' onClick={onSubmit} disabled={isLoading}>
                                {isLoading ? "Confirmando..." : "Confirmar"}
                            </ButtonComponent>

                            <ButtonComponent buttonStyle='secondary'  onClick={()=>hook.setShowReject(true)} disabled={isLoading}>
                                Rejeitar
                            </ButtonComponent>
                        </div>
                    }

                    <ButtonComponent buttonStyle={hook.isConfirmable ? 'secondary' : 'primary'} onClick={onClose}>
                        Voltar
                    </ButtonComponent>
                </div>



            </S.Content>

            <DialogComponent
                isOpen={hook.showProgress}
                onClose={() => hook.setShowProgress(false)}
                width={482}
                title='Progresso da recompensa'
                content={
                    <CardRewardProgress recognition={recognition} onClose={() => hook.setShowProgress(false)} />
                }
            >
            </DialogComponent>

            <DialogComponent
                isOpen={hook.showReject}
                onClose={() => hook.setShowReject(false)}
                width={400}
                title='Recusar a recompensa'
                content={
                    <RefuseRecognitionModal onCloseDetails={onClose!} recognition={recognition} onClose={() => hook.setShowReject(false)} />
                }
            >
            </DialogComponent>


        </>
    )

}
