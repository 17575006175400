import * as S from './select-employee.styled';

import { EmployeeCard } from '../employeeCard';
import { useSelectEmployeeHook } from './select-employee.hook';
import { LoadingComponent } from '../loading';
import { SelectEmployeeType } from './select-employee.type';
import { baseUrl } from '../../../api/api';

import search_icon from '../../../assets/icons/search.svg';
import profile_icon from '../../../assets/icons/profile.svg';

export const SelectEmployeeComponent = ({ id, selectedEmployee, setSelectedEmployee, type, openTop, openFixed, setListEmployees, withoutManager, withoutTeam, managerId }: SelectEmployeeType) => {
    
    const selectEmployeeHook = useSelectEmployeeHook({id, setSelectedEmployee, setListEmployees, type, withoutManager, withoutTeam, managerId});

    return (
        <S.SelectEmployeeContainer>
            <label className="c1-regular">Escolha o {type === 'EMPLOYEE' ? 'funcionário' : 'gestor'}</label>
            <div className='search'>
                {selectedEmployee &&
                    <img
                        className='profile-icon'
                        src={selectedEmployee?.profilePic?.content ?
                            `${baseUrl}/${selectedEmployee?.profilePic?.content}` :
                            profile_icon
                        }
                        alt="Perfil do usuário"
                    />
                }
                <input
                    type="text"
                    placeholder={`Nome do ${type === 'EMPLOYEE' ? 'funcionário' : 'gestor'}`}
                    onFocus={selectEmployeeHook.handleFocus}
                    onBlur={selectEmployeeHook.handleBlur}
                    value={selectedEmployee?.name || selectEmployeeHook.search}
                    onChange={selectEmployeeHook.handleSearch}
                />
                <img src={search_icon} alt="Icone de busca" />
            </div>

            

            {selectEmployeeHook.isPopoverOpen && setSelectedEmployee &&
                <S.SelectEmployeePopover data-open-top={openTop} data-open-fixed={openFixed} >
                    {selectEmployeeHook.isLoadingEmployeesData || selectEmployeeHook.isRefetchingEmployeesData ?
                        <LoadingComponent /> :
                        <>
                            {selectEmployeeHook.employeesData?.count === 0 ?
                                <span>Nenhum {type === 'EMPLOYEE' ? 'funcionário' : 'gestor'} encontrado</span>
                                :
                                <S.SelectEmployeePopoverContent>
                                    {selectEmployeeHook.employeesData?.rows.map(employee => (
                                        <S.SelectEmployeePopoverItem data-active={employee === selectedEmployee}>
                                            <EmployeeCard user={employee} onSelect={() => setSelectedEmployee(employee)} />
                                        </S.SelectEmployeePopoverItem>
                                    ))}
                                </S.SelectEmployeePopoverContent>
                            }
                        </>
                            
                    }
                </S.SelectEmployeePopover>
            }

        </S.SelectEmployeeContainer >
    );
};
