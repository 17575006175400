import { ButtonComponent } from '../button';
import { useExitModalHook } from './exit-modal.hook';
import * as S from './exit-modal.styled';
import { ExitModalType } from './exit-modal.type';

export const ExitModal = ({onClose} : ExitModalType)=>{
    const exitModalHook = useExitModalHook();
    return(
        <S.ExitModalContainer>
            <span className='b2-regular'>Tem certeza que deseja sair do Sistema Reconhecer?</span>

            <S.ExitModalActions>
                <ButtonComponent buttonStyle='primary' onClick={exitModalHook.handleExit} disabled={exitModalHook.isLoading}>
                    {exitModalHook.isLoading ? 'Saindo...' : 'Sair'}
                </ButtonComponent>
                <ButtonComponent  buttonStyle='secondary' onClick={onClose}  disabled={exitModalHook.isLoading}>
                    Voltar
                </ButtonComponent>
            </S.ExitModalActions>
        </S.ExitModalContainer>
    )
}