import * as S from './cardHistoryReward.styled';
import * as Dialog from '@radix-ui/react-dialog';

import { CardEmployeeReward } from '../cardEmployeeReward';
import { CardHistoryRewardType } from './cardHistoryReward.type';
import { baseUrl } from '../../../api/api';
import { ArrowComponent } from '../arrow';

import coins from '../../../assets/icons/coin-sack.svg';
import aprovacao from '../../../assets/icons/aprovacao.svg';
import { DialogComponent } from '../dialog';
import { useCardHistoryRewardHook } from './cardHistoryReward.hook';
import { RecognitionsStatus, RecognitionsStatusType } from '../../../utils/config';

export const CardHistoryReward = ({ recognition }: CardHistoryRewardType) => {
    const cardHistoryRewardHook = useCardHistoryRewardHook();
    return (
        <S.CardHistory>
            <img src={coins} alt="icone de moeda" />
            <S.Container>
                <S.Title>
                    <p className='b2-regular'>Reconhecimento</p>
                    <span className='b2-bold'>+{recognition?.points} pts </span>
                </S.Title>
                <S.ReconhecimentoContainer>
                    <S.ReconhecimentoContent>
                        <div className='icone'>
                            <img className='image-icon' src={`${baseUrl}/${recognition.category?.svg?.content}`} alt="icone de perfil" />
                            <p className='c1-regular'>{recognition.category?.name}</p>
                        </div>
                        <div>
                            <p className='c1-regular'>{recognition.author?.name}</p>
                        </div>
                        <div className='icone'>
                            <img className='category-icone' src={RecognitionsStatus[recognition.status as RecognitionsStatusType]?.icon} alt="icone de analise" />
                            <p className='c1-regular'>{RecognitionsStatus[recognition.status as RecognitionsStatusType].status}</p>
                        </div>
                    </S.ReconhecimentoContent>

                    <DialogComponent
                        isOpen={cardHistoryRewardHook.isOpenModal === recognition.id}
                        onOpen={()=>cardHistoryRewardHook.setIsOpenModal(recognition.id!)}
                        onClose={()=>cardHistoryRewardHook.setIsOpenModal(null)}
                        title='Histórico de reconhecimento'
                        width={418}
                        content={<CardEmployeeReward recognition={recognition} onClose={()=>cardHistoryRewardHook.setIsOpenModal(null)}/>}
                    >
                        <button className='trigger' onClick={()=>cardHistoryRewardHook.setIsOpenModal(recognition.id!)}>
                            <ArrowComponent className='seta' direction='right' color='black' />
                        </button>
                    </DialogComponent>

                </S.ReconhecimentoContainer>
            </S.Container>
        </S.CardHistory>
    );
};
