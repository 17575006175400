import { useQuery } from "react-query";
import { responseStatus } from "../../../../api/api-request.service";
import { RewardsDataType } from "../../../../models/rewards.interface";
import { useRewardsService } from "../../../../services/rewards.service";
import { ReconhecerCardHookType } from "./reconhecer-card.type";
import { useEffect } from "react";


export function useRewardsHook(props: ReconhecerCardHookType) {
    const rewardsService = useRewardsService();
    const {
        data: rewardsData,
        isLoading: isLoadingRewards,
        isRefetching: isRefetringRewards,
        refetch

    } = useQuery<RewardsDataType>('getRewardsCard', getRewards);


    async function getRewards() {
        let order, orderBy;
        let [item, value] = props.selectedFilter!.split('-');
        let isActive;

        if(item === 'ISACTIVE'){
            isActive = value === 'TRUE';
        }else{
            order = value;
            orderBy = item;
        }

        const { status, data } = await rewardsService.getRewardsByCategoryName({rewardsName : "CARTÃO RECONHECER", search: props.search, orderBy, isActive, order});
        
        if (status === responseStatus.SUCCESS) {
            return data;

        }

    }

    
    useEffect(()=>{
        refetch()
    }, [props.selectedFilter, props.reload]);

    return {
        rewardsData,
        isLoadingRewards,
        isRefetringRewards
    }
}








