import { useState } from "react";
import { useMutation } from "react-query";
import { queryClient } from "../../../utils/query-client";
import { RefuseRecognitionHookType, RefuseRecognitionSchemaType, refuseRecognitionSchema } from "./refuse-recognition.type";
import { RecognitionInterface } from "../../../models/recognition.interface";
import { useRecognitionService } from "../../../services/recognition.service";
import { responseStatus } from "../../../api/api-request.service";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useAppSelector } from "../../../hooks/hooks";

export function useRefuseRecognitionHook({recognition, onClose, onCloseDetails} : RefuseRecognitionHookType){
    const recognitionService = useRecognitionService();
    const userAuth = useAppSelector(state=>state.user);
    const [showModalSuccess, setShowModalSuccess] = useState(false);

    const { mutate, isLoading: isLoadingSubmit } = useMutation(
        (data: RefuseRecognitionSchemaType) => handleSubmitForm(data), {
        onSuccess: () => {
            queryClient.invalidateQueries('createRefuseRecognition');
        },
    });

    const { register, handleSubmit, formState: { errors } } = useForm<RefuseRecognitionSchemaType>({
        resolver: zodResolver(refuseRecognitionSchema)
    });

    async function handleSubmitForm(payload: RefuseRecognitionSchemaType) {
        await handleRefuseRecognition(payload);
    };

    async function handleRefuseRecognition(payload: RefuseRecognitionSchemaType) {
        const recognitionData = {...recognition};
        if(userAuth.isManager){
            recognitionData.fkCanceledByUserRHId  = parseInt(userAuth.user_id);
        }else{
            recognitionData.fkCanceledByUserSecondaryManagerId = parseInt(userAuth.user_id);
        }

        const newRecognition: RecognitionInterface = {
            ...recognitionData,
            status : 'CANCELED',
            rejectReason: payload.rejectReason,
            canceledAt: new Date()

        };

        const { status } = await recognitionService.putRecognition(newRecognition);
        if (status === responseStatus.SUCCESS) {
            setShowModalSuccess(true);
            if (onClose) {
                setTimeout(() => {
                    onClose();
                    onCloseDetails();
                    queryClient.refetchQueries('getAllUpdateCardHome');
                }, 2000);
            }
        }
    }

    function onSubmit(data: RefuseRecognitionSchemaType) {
        mutate(data);
    }

    return{
        isLoadingSubmit,
        errors,
        showModalSuccess,
        register,
        handleSubmit,
        onSubmit
    }
}