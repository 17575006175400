import * as S from "./dropdown.styled"
import { DropdownType } from "./dropdown.type"
import { useState } from "react";
import { DropdownOptionsComponent } from "../dropdownOptions";
import { ArrowComponent } from "../arrow";


export const DropdownComponent = ({
    placeholder,
    value,
    options,
    onSelect,
    minWidth,
    minHeight,
    bgColor,
    borderColor,
    onSearch,
    onRefetchSearch,
    isLoading
} : DropdownType)=>{

    const [showOptions, setShowOptions] = useState(false);
    return(
        <S.DropdownContainer className="b3-regular">
            <S.DropdownSelect 
                minWidth={minWidth} 
                minHeight={minHeight} 
                bgColor={bgColor}
                onClick={()=>setShowOptions(!showOptions)} 
                borderColor={borderColor}
            >
                {value || value === ''? 
                    <span>{options?.find(option=>option.key === value)?.value}</span> : 
                    <span className="placeholder-style">{placeholder}</span>
                }
                <ArrowComponent direction={showOptions ? "up" : "down"} color={'black'}/> 
            </S.DropdownSelect>

            {showOptions && 
                <DropdownOptionsComponent
                    options={options}
                    value={value}
                    onSelect={onSelect}
                    setShowOptions={setShowOptions}
                    onSearch={onSearch}
                    onRefetchSearch={onRefetchSearch}
                    isLoading={isLoading}
                />
            }

        </S.DropdownContainer>
    )   
}