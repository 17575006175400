import styled from "styled-components";

export const ResponsabilityTermContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--blue-color-100);
`;

export const Logo = styled.img`
  position: fixed;
  top: 20px;
  left: 20px;
`;

export const Content = styled.div`
  max-width: 800px;
  max-height: 90vh;
  overflow-y: scroll;
  font-family: 'Lato';

  padding: 20px 40px;
  font-size: 18px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  li{
    margin-bottom: 10px;
  }

  .tab{
    margin-left: 56px;
  }

  @media screen and (max-width : 900px){
      max-width: 90vw;
      max-height: 85vh;
      margin-top: 5vh;
  }
`;