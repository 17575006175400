import * as S from "./modalDeleteUser.styled";

import { ButtonComponent } from "../button";
import { useModalDeleteUserHook } from "./modalDeleteUser.hook";
import { ModalSuccess } from "../modalSuccess";
import { ModalDeleteUserType } from "./modalDeleteUser.type";

export const ModalDeleteUser = ({ onClose, onReload, user, type }: ModalDeleteUserType) => {
    const hook = useModalDeleteUserHook({ onClose, onReload, user });
    return (
        <S.DialogContent>
            {hook.showModalSuccess ? (
                <ModalSuccess message={`${type === "EMPLOYEE" ? "Funcionário" : "Gestor"} excluído com sucesso!`} />
            ) : (
                <>

                    <span className="c3-regular">{`Tem certeza que deseja excluir o ${type === "EMPLOYEE" ? "funcionário" : "gestor"}`}  <b>{user.name}</b>?</span>
                    <S.DialogActions>
                        <ButtonComponent buttonStyle="primary" type="submit" onClick={hook.onSubmit} disabled={hook.isLoadingSubmit}>
                            {hook.isLoadingSubmit ? 'Excluindo...' : `Excluir ${type === "EMPLOYEE" ? "Funcionário" : "Gestor"}`} 
                        </ButtonComponent>

                        <ButtonComponent buttonStyle="secondary" type="submit" onClick={()=>onClose()}>
                            Cancelar
                        </ButtonComponent>
                    </S.DialogActions>
                </>
            )}
        </S.DialogContent >
    );
};
