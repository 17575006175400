import * as S from './sidebar.styled';

import { useSidebarHook } from './sidebar.hook';
import { DialogComponent } from '../dialog';
import { ExitModal } from '../exitModal';

import logo_image from '../../../assets/images/logo.png';
import rh_image from '../../../assets/images/rh.png';
import manager_image from '../../../assets/images/manager.png';
import logout_icon from '../../../assets/icons/logout.svg';

export const SidebarComponent = () => {
    const sidebarHook = useSidebarHook();
    return (
        <S.SidebarContainer className='b2-regular'>
            <S.SidebarHeader>
                <S.SidebarLogo>
                    <img src={logo_image} alt="Logo Reconhecer" />
                    <img src={sidebarHook.isManager ? rh_image : manager_image} alt="Logo tipo de acesso" />
                </S.SidebarLogo>

                <S.SidebarItems>
                    {sidebarHook.sidebarItems.map((sidebarItem, index) => (
                        <>
                            {((!sidebarHook.isManager && sidebarItem.showManager) || sidebarHook.isManager)  &&
                                <S.SidebarItem
                                    key={sidebarItem.name}
                                    selected={sidebarItem.paths.includes(sidebarHook.path)}
                                    onMouseEnter={() => sidebarHook.handleMouseEnter(index)}
                                    onMouseLeave={() => sidebarHook.handleMouseLeave()}
                                    className={sidebarHook.hoveredItem === index || sidebarItem.paths.includes(sidebarHook.path) ? 'active' : ''}
                                    onClick={() => sidebarHook.handleNavigate(sidebarItem.link)}
                                >
                                    <img src={sidebarItem.activeIcon} alt={"Icone do sidebar"} className={sidebarHook.hoveredItem === index || sidebarItem.paths.includes(sidebarHook.path) ? '' : 'd-none'} />
                                    <img src={sidebarItem.icon} alt={"Icone do sidebar"} className={sidebarHook.hoveredItem === index || sidebarItem.paths.includes(sidebarHook.path) ? 'd-none' : ''} />

                                    <span>{sidebarItem.name}</span>
                                </S.SidebarItem>

                            }
                        </>
                    ))}
                </S.SidebarItems>
            </S.SidebarHeader>


            <S.SidebarFoot>
                <DialogComponent
                    title='Sair'
                    isOpen={sidebarHook.isOpenModal}
                    onOpen={() => sidebarHook.setIsOpenModal(true)}
                    onClose={() => sidebarHook.setIsOpenModal(false)}
                    width={380}
                    content={<ExitModal onClose={() => sidebarHook.setIsOpenModal(false)} />}
                >
                    <S.SidebarItem onClick={() => sidebarHook.setIsOpenModal(true)}>
                        <img src={logout_icon} alt={"Icone do sidebar"} />
                        <span>Sair</span>
                    </S.SidebarItem>
                </DialogComponent>
            </S.SidebarFoot>

        </S.SidebarContainer >
    );
}