import styled from "styled-components";

interface IconProps{
    "data-active" : boolean;
}

export const ListEmploeeContainer = styled.div`
    width: 100%;
    .w-100{
        width: 100%;
    }   
`;

export const Container = styled.div`
    display : flex;
    width: 100%;
    flex-direction : row;
    margin-top : 8px;
    justify-content : space-between;
    align-items : center;
    cursor : pointer;

    

`

export const Profile = styled.div`
    display : flex;
    flex-direction : row;
    gap : 16px;
    align-items : center;
    min-width: 265px;
    max-width: 265px;
    .position{
        color: var(--blue-color-100);
    }

`

export const Icons = styled.div`
    min-width: 280px;
    max-width: 280px;
    margin-right: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    padding-bottom: 4px;
    margin-top: 4px;
    overflow-x: scroll;

    button{
        border:none;
    }

`

export const Icon = styled.div<IconProps>`
    display : flex;
    justify-content : center;
    align-items : center;
    flex-direction : row;
    gap : 4px;
    .image-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        padding: 0;
        background-color: ${props=>props['data-active'] ? 'var(--blue-color-100)' : 'var(--grey-color-40)'};
        img{
            width: 60%;
            margin: 0;
        }
    }
`;

export const Points = styled.div`
    display: flex;
    gap: 4px;
    min-width: 150px;
    max-width: 150px;
    align-items: center;
`
export const Gestor = styled.div`
    min-width: 200px;
    max-width: 200px;
`
export const Data = styled.div`
    display: flex;
    gap: 4px;
    min-width: 120px;
    max-width: 120px;

`
