import styled from "styled-components";

export const CardEditContainer = styled.div`
    position: relative;
    img{
        cursor : pointer;
    }
`;

export const CardEditContent = styled.div`
    position: absolute;
    top: 15px;
    right: 0;
    display: inline-flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    min-width: 200px;

    border-radius: 4px;
    background: #FEFEFE;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);



    span{
        width: 100%;
        cursor: pointer;
        
        &:not(:last-child) {
            border-bottom: 1px solid var(--grey-color-40);
            padding-bottom: 8px;
        }
    }

`;
