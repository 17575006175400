import * as S from './switch.styled';
import { SwitchType } from './switch.type';

export const SwitchComponent = ({enabled, onClick, label} : SwitchType)=>{
    return(
        <S.SwitchContainer onClick={onClick}>
            <S.SwitchContent data-enabled={enabled} >
                <S.SwitchCircle></S.SwitchCircle>
            </S.SwitchContent>

            <span className='be-regular'>{label}</span>
        </S.SwitchContainer>
    );
}