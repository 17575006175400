import * as S from './selectComponent.styled';
import profile from '../../../assets/icons/perfil.svg'
import { useState } from 'react';
import { ArrowComponent } from '../arrow';
import { useSelectHook } from './selectComponent.hook';
import { SelectType } from './selectComponent.type';
import { baseUrl } from '../../../api/api';


export const SelectComponent = ({selected, setSelected, error} : SelectType) => {
    const selectHook = useSelectHook();

    return (
        <S.SelectContainer>
            <button type={"button"} className='b2-regular' onClick={()=>selectHook.setIsOpen(!selectHook.isOpen)}>
                <div>
                    { selected && <img className={'icon-image'} src={`${baseUrl}/${selected.svg?.content}`} alt="icone de perfil" /> }
                    <p>{selected?.name || 'Selecione um item'}</p>
                </div>
                <ArrowComponent direction={selectHook.isOpen ? 'up' : 'down'} color='black'/>
            </button>
            <span className='c1-regular error'>{error}</span>

    
            <S.ItemsContainer className={selectHook.isOpen ? '' : 'd-none'}>
                {selectHook.recognitionCategoriesData?.rows?.map(recognitionCategory=>(
                    <div onClick={()=>selectHook.handleSelect(setSelected, recognitionCategory)}>
                        <img className={'icon-image'} src={`${baseUrl}/${recognitionCategory.svg?.content}`} alt="icone" />
                        <p className='b3-regular '>{recognitionCategory.name}</p>
                    </div>
                ))}
                
            </S.ItemsContainer>
        
        </S.SelectContainer>

    )

}