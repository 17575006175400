import aprovation_icon from '../assets/icons/aprovation.svg';
import received_icon from '../assets/icons/received.svg';
import in_calendar_icon from '../assets/icons/inCalendar.svg';
import canceled_icon from '../assets/icons/error.svg';

export const SLIDER_LIMIT = 20000;

export const RecognitionsStatus = {
    PENDING : {
        status : 'Aguardando segundo gestor',
        icon : aprovation_icon
    },
    CONFIRMED : {
        status : 'Aguardando RH',
        icon : aprovation_icon
    },

    APROVED : {
        status : 'Confirmado',
        icon : received_icon
    },

    REDEEMED: {
        status : 'Confirmado',
        icon : received_icon
    },

    CANCELED:{
        status : 'Rejeitado',
        icon : canceled_icon 
    }
}

export type RecognitionsStatusType = keyof typeof RecognitionsStatus;