import * as S from './select-team.styled';
import { useSelectTeamHook } from "./select-team.hook";
import { SelectEmployeeComponent } from '../selectEmployee';
import { EmployeeCard } from '../employeeCard';
import { ButtonComponent } from '../button';
import { SelectTeamType } from './select-team.type';

export const SelectTeam = ({onSubmit, onClose, selecteds, onAdd, onRemove, initialData} : SelectTeamType) => {
    const hook = useSelectTeamHook({onSubmit, onClose, selecteds, onAdd, onRemove, initialData});
    return (
        <S.SelectTeamContainer>
            <S.SelectTeamContent>
                <SelectEmployeeComponent 
                    id='selectTeam' 
                    type={'EMPLOYEE'} 
                    setListEmployees={hook.handleSetEmployees}
                    withoutManager={false}
                    withoutTeam={true}
                />
                <S.SelectTeamList>
                    <div className='item'>
                        {hook.emplyees?.map(employee=>(
                            <S.SelectTeamContentItem key={`employee-check-${employee.id}`} htmlFor={`check-${employee.id}`}>
                                <input 
                                    defaultChecked={!!selecteds.find(item=>item.employeeId === employee.id)} 
                                    id={`check-${employee.id}`} 
                                    type='checkbox' 
                                    onChange={(e)=>hook.handleCheck({e, employee})}
                                />
                                <EmployeeCard user={employee} />
                            </S.SelectTeamContentItem>
                        ))}
                    </div>
                </S.SelectTeamList>
            </S.SelectTeamContent>
                <ButtonComponent buttonStyle='primary' onClick={hook.handleSubmit} disabled={hook.selectedsEmployees.length === 0}>
                    Concluir
                </ButtonComponent>
        </S.SelectTeamContainer>
    );
}
