import * as SA from '../../../../app.styled';

import { TabBarComponent } from '../../../components/tabBar';
import { HeaderPageComponent } from '../../../components/headerPage';
import { usePerformanceHook } from './performance.hook';
import { DialogComponent } from '../../../components/dialog';
import { ModalNewRecognition } from '../config/Components/modalNewRecognition';
import { ButtonComponent } from '../../../components/button';

import add_icon from '../../../../assets/icons/add.svg';

export const PerformancePage = () => {
    
    const performanceHook = usePerformanceHook();
    return (
        <SA.AppContainerHome>
            <SA.AppTopBar>
                <HeaderPageComponent 
                    title='Desempenho' 
                    onUpdate={performanceHook.handleUpdate}
                />

                {performanceHook.selectedTab === 'Configurações' && 
                    <DialogComponent
                        title={'Novo Reconhecimento'}
                        isOpen={performanceHook.isOpenModal}
                        onOpen={()=>performanceHook.setIsOpenModal(true)}
                        onClose={()=>performanceHook.setIsOpenModal(false)}
                        width={418}
                        content= {<ModalNewRecognition onClose={performanceHook.handleClose}/>}
                    >
                        <ButtonComponent buttonStyle='primary' width={240} onClick={()=>performanceHook.setIsOpenModal(true)}>
                                <img src={add_icon} alt="Icone de adicionar" />
                                <span className='b3-bold'>Criar novo reconhecimento</span>
                        </ButtonComponent>

                    </DialogComponent>
                }
            </SA.AppTopBar>

            <TabBarComponent tabs={performanceHook.tabsList} selectedTab={performanceHook.selectedTab} setSelectedTab={performanceHook.setSelectedTab}/>

            {performanceHook.tabs[performanceHook.selectedTab]}
        </SA.AppContainerHome>
    );
}
