import styled from "styled-components";

export const RefuseRecognitionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    form{
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
`;


export const RefuseRecognitionActions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const RefuseRecognitionInputContainer = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    border-radius: 4px;
    background: var(--minimal-color);

    textarea{
        display: flex;
        padding: 8px 16px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        height: 80px;
        border: none;
        background-color: var(--minimal-color);
        resize: none;
        outline: none;
    }
`;