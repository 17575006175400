import styled from "styled-components";


export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width:370px;
  max-height: 280px;
  
  border-radius:4px;
    
  background : var(--minimal-color);
  padding:16px;

  .top-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap:24px;



  .info{
      color: var(--grey-color-60)
  }

`

export const NomeContainer = styled.div`

  display:flex;
  flex-direction:row;
  gap: 8px;



 img{
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
  }

  .border{
    
    background-color: var(--blue-color-100);
    padding: 10px;
  }

  label{
    cursor: pointer;
  }

  div{
    display: flex;
    flex-direction: column;
    gap: 4px;

    p{
      color: var(--grey-color-60) !important;
    }

    & input{
        outline:none;
        border:none;
        width:272px;
        height:44px;
        background: var(--white-color);
        padding:8px;
        border-radius: 8px;

    }
}

.svg-input{
    display: none;
}


`

export const PointsSlider = styled.div`
display: flex;
flex-direction:row;


`
export const Points = styled.div`
display:flex;
flex-direction:row;
align-items:center;
gap:4px;
margin-left:16px;


`


export const SlideRoot = styled.div`

position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 248px;
  height: 24px;
  
  cursor:pointer;



  .SliderTrack {
    background-color: var(--blue-color-20);
    position: relative;
    flex-grow: 1;
    border-radius: 9999px;
    height: 3px;
  }
  
  .SliderRange {
    position: absolute;
    background-color: var(--blue-color-100);
    border-radius: 9999px;
    height: 100%;
  }
  
  .SliderThumb {
    display: block;
    width: 20px;
    height: 20px;
    background-color: var(--blue-color-100);
    box-shadow: 0 2px 10px v;
    border-radius: 10px;
  }
  .SliderThumb:hover {
    background-color:  var(--blue-color-100);
  }
  .SliderThumb:focus {
    outline: none;
 
  }
  
  `

export const Enable = styled.div`
  display:flex ;
  flex-direction:row;
  gap:16px;

  p{
    color:var(--grey-color-60);
  }
  
  
  `

  export const Actions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `;