import styled from "styled-components";

interface DialogContentProps {
    "data-width" : number;
    "data-is-open" : boolean;
}

interface DialogOverlayProps{
    "data-is-open" : boolean;
}


export const DialogContainer = styled.div`
    
`;

export const DialogOverlay= styled.div<DialogOverlayProps>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #31313155;
    opacity: ${props=>props["data-is-open"] ? 1 : 0};
    transition: opacity 0.2s ease;
    z-index: 3;
`;

export const DialogHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    width: 100%;
    z-index: 4 !important;

    .close-button{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-right: 8px;

        border-radius: 8px;
        background: var(--minimal);
        border: none;
        outline: none;
        cursor: pointer;
    }
`;

export const DialogContent = styled.div<DialogContentProps>`
    display: flex;
    width: ${props=>props['data-width']}px;
    padding: 24px 13px 24px 21px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    flex-shrink: 0;
    position: fixed;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 95vh;
    border-radius: 12px;
    background: var(--white-color, #FFF);
    box-shadow: 0px 4px 32px 0px rgba(61, 70, 112, 0.08);
    opacity: ${props=>props["data-is-open"] ? 1 : 0};
    transition: opacity 0.2s ease;
    z-index: 4;


    .dialog-content{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        z-index: 4;
    }
`;  

export const DialogContentData = styled.div`
    overflow-y: scroll;
    max-height: calc(100vh - 130px);
    padding-right: 8px;
    
`;