import styled from "styled-components";

type TeamProfilesContainerProps = {
    'data-image-width' : number;
}

export const TeamProfilesContainer = styled.div<TeamProfilesContainerProps>`
    display: flex;
    flex-direction: row;

    .team-empty{
        margin-left: 2px;
    }

    .profile-empty, .profile{
        width: ${props=>props['data-image-width']}px;
        height: ${props=>props['data-image-width']}px;
        border-radius: 96px;
        object-fit: cover;
        background-color: var(--grey-color-40);
        border: 1px solid var(--white-color);

        &:not(:first-child){
            margin-left: -4px;
        }
        
    }

`;