import { useEffect, useState } from "react";
import { DialogHookType } from "./type";

export function useDialogHook({isOpen} : DialogHookType){
    const [localIsOpen, setLocalIsOpen] = useState(isOpen);

    useEffect(()=>{
        setTimeout(()=>{
            setLocalIsOpen(isOpen);
        },100);
    }, [isOpen])

    return{
        localIsOpen
    }
}