import styled from "styled-components";

export const  EmployeeCardContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .employee-name{
        max-width: 140px;
    }
`;

export const EmployeeCardPoints = styled.div`
    display: flex;
    padding: 4px 8px;
    align-items: start;
    gap: 4px;

    border-radius: 4px;
    background: var(--minimal-color);
`;



