import styled from "styled-components";


interface DivContainerProps{
    height?: number,
    width?: number,
   
}

export const ProfileContainer = styled.div<DivContainerProps>`
    display: flex;
    border:none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    min-height: ${props=>props.height ? props.height : 180}px;
    max-height: ${props=>props.height ? props.height : 180}px;
    width: ${props=>props.width ? `${props.width}px` : '100%'};
    border-radius: 16px;
    gap: 8px;
    background: var(--minimal-color);
    color:var(--grey-color-60);
    
    img{
        width: 96px;
        height: 96px;
        border-radius: 50%;
        object-fit: cover;
        flex-shrink: 0;
    }

    .name{
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    }
    
`;