import styled from "styled-components";

interface IconeProps{
    "data-active" : boolean;
}

interface HistoryProps{
    "data-height" : number
}

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
    align-self: stretch;
`;

export const LimitContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 32px;

    .limits{
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 286px;
    }

    .pontos{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
`;

export const ContainerLeft = styled.div`
    display:flex;
    flex-direction:column;
    width:512px;
    height: 495px;
    gap:24px;

    .empty-input{
        height: 36px;
    }

    .info-time{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: flex-start;

    }
 
    .tempo{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    .data{
        background-color:var(--minimal-color);
        display: flex;
        height: 24px;
        padding: 10px 4px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 160px;
    }  

    .divHeader{
        all:unset;
        display: flex;
        padding: 4px;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    
        
        img{
            all:unset;
            height: 24px;
            width: 24px;
        }

    }
`;

export const InfoPessoal = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
    background-color: var(--minimal-color);
    border-radius: 4px;

    .edit{
        display: flex;
        justify-content: flex-end;
        width: 100%;
        position: relative;

        .h-10{
            height: 20px;
        }
    }

    .cpf{
        display: flex;
        width: 169px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
    }

    .email{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;
    }

    .info-user{
        display: flex;
        align-items: center;
        gap: 16px;
        align-self: stretch;
    }


`;

export const TeamContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    
    .team{
        display: flex;
        width: 169px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        .team-list{
            display: flex;
            padding: 0px 16px;
            align-items: center;
            gap: -4px;
            align-self: stretch;
            
            img{
                width: 32px;
                height: 32px;
                border-radius: 50%;
                object-fit: cover;
                border: 1px solid var(--white-color);
            }
        }
    }


`;

export const Limite=styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    div{
        display: flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        gap: 4px;
    }
`;

export const SlideRoot = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;
    touch-action: none;
    width: 180px;
    height: 4px;
    justify-content:flex-start;
    margin-top:16px;
    cursor:pointer;

    .SliderTrack {
        background-color: var(--blue-color-20);
        position: relative;
        flex-grow: 1;
        border-radius: 9999px;
        height: 3px;
        cursor:pointer;
    }
 
    .SliderRange {
        position: absolute;
        background-color: var(--blue-color-100);
        border-radius: 9999px;
        height: 100%;
        cursor:pointer;
    }
 
    .SliderThumb {
        display: block;
        width: 20px;
        height: 20px;
        background-color: var(--blue-color-100);
        box-shadow: 0 2px 10px v;
        border-radius: 10px;
    }

    .SliderThumb:hover {
        background-color:  var(--blue-color-100);
    }

    .SliderThumb:focus {
        outline: none;
    }
`;

export const ManagerGestor = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
`;

export const Gestor = styled.div`
    display:flex;
    justify-content: flex-start;
    gap:2px;
    margin-top: 4px;
    margin-left:32px;

`;

export const ContainerRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
`;

export const Icones = styled.div`
    display:flex;
    justify-content:flex-start;
    align-items:center;
    gap:16px;
    width: calc(100% - 40px);
    overflow-x: scroll;
    padding-bottom: 8px;
`;

export const Icone = styled.div<IconeProps>`
    display: flex;
    padding: 2px 4px;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    border-radius: 4px;
    background-color:var(--minimal-color);

    img{
        width: 20px;
        height: 20px;
        padding: 4px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${props=>props["data-active"] ? "var(--blue-color-100)" : "var(--grey-color-40)"};
    }
`;

export const Points = styled.div`
    width:455px;
    display:flex;
    height: 64px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    background-color:var(--minimal-color);
    margin-top:2px;
`;

export const History = styled.div<HistoryProps>`
    padding-right: 4px;
    overflow-y: scroll;
    height: ${props=>props['data-height']}px;
`;

export const HistoryContent = styled.div`  
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    padding: 16px;
    background: var(--minimal-color);
    min-height: 100%;
    width: 455px ;
    gap: 16px;

    .span-empty{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const EmployeeInfoModalContainer = styled.div`
    
`;

export const EmployeeInfoModalContent = styled.div`

`;

export const DivOpenModal = styled.div`
    display:flex;
    flex-direction:column;
`;



export const Line = styled.div`
    width: 143px;
    height: 1px;
    background:var(--grey-color-40);
    flex-shrink: 0;
`;