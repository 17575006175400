import * as Dialog from '@radix-ui/react-dialog';
import * as S from './styled';

import { DialogType } from './type';
import { useDialogHook } from './hook';

import close_icon from '../../../assets/icons/close.svg';

export const DialogComponent = (props: DialogType) => {
    const hook = useDialogHook(props);
    return (
        <S.DialogContainer className={props.className}>
            <Dialog.Root open={props.isOpen}>
                <Dialog.Trigger asChild>
                    {props.children}
                </Dialog.Trigger>
                <Dialog.Portal>
                    <S.DialogOverlay data-is-open={hook.localIsOpen}>
                        <Dialog.Overlay className='dialog-overlay' />
                    </S.DialogOverlay>
                    <S.DialogContent data-width={props.width} data-is-open={hook.localIsOpen}>
                        <Dialog.Content className='dialog-content'>
                            {props.title &&
                                <S.DialogHeader>
                                    <Dialog.Title className="b1-regular">{props.title}</Dialog.Title>
                                    <Dialog.Close asChild>
                                        <button className="close-button" aria-label="Close" onClick={props.onClose}>
                                            <img src={close_icon} alt="Icone de close" />
                                        </button>
                                    </Dialog.Close>
                                </S.DialogHeader>
                            }
                            <S.DialogContentData>
                                {props.content}
                            </S.DialogContentData>
                            
                        </Dialog.Content>
                    </S.DialogContent>
                </Dialog.Portal>
            </Dialog.Root>
        </S.DialogContainer>
    );
}