import styled from "styled-components";

export const LoginContainer = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;

    background-color: var(--blue-color-100);
`;


export const LoginContent = styled.form`
    display: flex;
    padding: 32px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;

    border-radius: 12px;
    background: var(--white-color);

    box-shadow: 0px 4px 32px 0px rgba(61, 70, 112, 0.08);
`;

export const LoginFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    input{
        display: flex;
        width: 344px;
        height: 44px;
        padding: 8px 16px;
        align-items: center;
        gap: 8px;

        border-radius: 8px;
        background: var(--minimal-color);

        border: none;

    }
`;

export const LoginActionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    
    a{
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-decoration: none;
    }
`;