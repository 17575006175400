import { useHandleRequest } from "../api/api-request.service";
import { SectorDataType, SectorInterface } from "../models/sector.interface";

export function useSectorService() {
    const handleRequestHook = useHandleRequest();

    async function getSector() : Promise<{ status: string; data: SectorDataType }>{
        return handleRequestHook.handleRequest(`/sectors`, 'GET');
    }

    async function putSector(data: SectorInterface) : Promise<{ status: string; data: SectorInterface }>{
        return handleRequestHook.handleRequest(`/sectors/${data.id}`, 'PUT', data);
    }

    async function bulkSector(data: SectorInterface[]) : Promise<{ status: string; data: SectorDataType }>{
        return handleRequestHook.handleRequest(`/sectors/update/bulk`, 'PUT', data);
    }

    

    return {
        getSector,
        putSector,
        bulkSector
    }
}