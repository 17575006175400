import * as S from "./modalDeleteReward.styled";

import { ButtonComponent } from "../button";
import { useModalDeleteRewardHook } from "./modalDeleteReward.hook";
import { ModalSuccess } from "../modalSuccess";
import { ModalDeleteRewardType } from "./modalDeleteReward.type";

export const ModalDeleteReward = ({ onClose, reward}: ModalDeleteRewardType) => {
    const modalDeleteRewardHook = useModalDeleteRewardHook({ onClose, reward });
    return (
        <S.DialogContent>
            {modalDeleteRewardHook.showModalSuccess ? (
                <ModalSuccess message={`Recompensa excluída com sucesso!`} />
            ) : (
                <>

                    <span className="c3-regular">{`Tem certeza que deseja excluir a recompensa`}  <b>{reward.title}</b>?</span>
                    <S.DialogActions>
                        <ButtonComponent disabled={modalDeleteRewardHook.isLoadingSubmit} buttonStyle="primary" type="submit" onClick={modalDeleteRewardHook.onSubmit}>
                            {modalDeleteRewardHook.isLoadingSubmit ? 'Excluindo...' : 'Excluir recompensa'}
                        </ButtonComponent>

                        <ButtonComponent buttonStyle="secondary" type="submit" onClick={()=>onClose()}>
                            Cancelar
                        </ButtonComponent>
                    </S.DialogActions>
                </>
            )}
        </S.DialogContent >
    );
};
