import * as S from './styled';

import { InputSearchType } from './type';
import { useInputSearchHook } from './hook';

import search_icon from "../../../assets/icons/search.svg";
import filter_icon from "../../../assets/icons/filter.svg";

export const InputSearchComponent = (props: InputSearchType) => {

    const hook = useInputSearchHook(props);

    return (
        <S.InputSearch >
            <div className='input-container'>
                <img src={search_icon} alt="Icone de busca" />
                <input className='c1-regular' placeholder={props.placeholder} value={hook.localSearch} onChange={hook.handleSearch} />
            </div>

            {props.filters && (
                <div className={`filter-container`}>
                    <img className={hook.showPopOver ? 'active' : ''} src={filter_icon} alt="Icone de filtro" onClick={()=>hook.setShowPopOver(true)}/>
                    {hook.showPopOver &&
                        <div className='filter-popover' ref={hook.popoverRef}>
                            {props.filters.map(filter => (
                                <span 
                                    className={props.selectedFilter === filter.value ? 'selected' : ''} 
                                    onClick={()=>hook.handleSelectFilter(filter)}
                                >
                                    {filter.label}
                                </span>
                            ))}
                        </div>
                    }
                </div>

            )}
        </S.InputSearch>
    )
}