import styled from "styled-components";


export const ContainterRewards = styled.div`

    width:610px;
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    background: var(--minimal-color);


    .inputTitulo{
        
        display: flex;
        height: 44px;
        padding: 8px 16px;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        align-self: stretch;
        border-radius: 8px;
        background: var(--white-color);
        width:281px;
        border:none;
        outline:none;
    }

`

export const ContainerUpload = styled.div`
    display: flex;
    height: 64px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    background: var(--grey-color-20);
    resize:none;

    .profile-input{
        display:none;
    }

`

export const ContainerPoints = styled.div`

    display:flex;
    flex-direction:row;
    gap: 16px;

    & div {
        display:flex;
        flex-direction:column;
    }

    & input{
    
        display: flex;
        height: 44px;
        padding: 8px 16px;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        align-self: stretch;
        border-radius: 8px;
        background: var(--white-color);
        width:281px;
        height:44px;
        border:none;
        outline:none;
        margin-top:4px;
    
    }
`

export const ContainerDescribre = styled.div`
width: 100%;

& textarea{
    margin-top:4px;
    display: flex;
padding: 8px 16px;
align-items: center;
gap: 8px;
align-self: stretch;

    width:100%;
    height:48px;
    border-radius: 8px;
background: var(--white-color);
resize:none;
border:none;
outline:none;

}




`


export const ContainerBenefit = styled.div`
width: 100%;
& textarea{
    margin-top:4px;
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    width:100%;
    height:88px;
    border-radius: 8px;
    background: var(--white-color);
    resize:none;
    border:none;
    outline:none;

}




`

export const ButtonContainer = styled.div`
    width: 100%;
    margin-top: 24px;
`;

export const Replacement=styled.div`
    width:100%;
    height:144px;
    padding: 8px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    gap:8px;

    background: var(--white-color);
     & label{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
     }

     & input{
       width:16px;
       height:16px;
       margin-top:4px;
  }


  

`

export const Header = styled.div`

    display:flex;
    flex-direction:row;
    gap:16px;
    
    & div {

        & p {
            margin-bottom:4px;
        }
    }



`



