import styled from "styled-components";

interface SidebarItemProps {
    selected?: boolean;
}

export const SidebarContainer = styled.div`
    display: flex;
    width: 217px;
    height: 100vh;
    padding: 32px 32px 32px 24px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    overflow-y: scroll;

    background: var(--minimal-color);

`;

export const SidebarItems =  styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    .active{
        background-color: var(--blue-color-20);
    }
`;

export const SidebarItem =  styled.div<SidebarItemProps>`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 2px 8px;
    width: 100%;
    gap: 8px;
    border-radius: 4px;

    span{
        ${(props) =>
            props.selected &&
            `
                color: var(--blue-color-100) !important;
                font-weight: 700;
            `
        }
    }

    &:hover{ 
        span{
            color: var(--blue-color-100);
            font-weight: 700;
        }        
    }
`;

export const SidebarLogo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 48px;
`;

export const SidebarHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
    align-self: stretch;
`;

export const SidebarFoot = styled.div`
    margin-top: 48px
`;