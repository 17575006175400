
import { RewardsInterface } from '../../../../models/rewards.interface';
import { LoadingComponent } from '../../../components/loading';
import { RewardCardComponent } from '../../../components/rewardCard';
import { useRewardsHook } from '../home/rewards.hook';
import { useBenkiHook } from './benki.hook';
import * as S from './benki.styled';
import { BenkiType } from './benki.type';

export const BenkiTab = (props: BenkiType) => {
    const hook = useBenkiHook(props)

    return (
        <S.BenkiContainer>
            {hook.isLoadingRewards || hook.isRefetringRewards ?
                <LoadingComponent containerHeight={300}/> :
                <>
                
                    {hook?.rewardsData?.count === 0 &&
                        <span className='empty-card b3-regular'>Nenhuma recompensa encontrada</span>
                    }
                    {hook?.rewardsData?.rows.map((rewards: RewardsInterface) => (
                        <RewardCardComponent rewards={rewards} />
                    ))}
                </>
            }
        </S.BenkiContainer>
    );
}