import { useState } from "react";
import { ModalDeleteRecognitionCategoryHookType } from "./modalDeleteRecognitionCategory.type";
import { useMutation } from "react-query";
import { queryClient } from "../../../utils/query-client";
import { responseStatus } from "../../../api/api-request.service";
import { useRecognitionCategoryService } from "../../../services/recognition-category.service";

export function useModalDeleteRecognitionCategoryHook({ onClose, recognitionCategory, onReload}: ModalDeleteRecognitionCategoryHookType) {
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const recognitionCategoryService =useRecognitionCategoryService();

    const { mutate, isLoading: isLoadingSubmit } = useMutation(
        () => handleSubmitForm(), {
        onSuccess: () => {
            queryClient.invalidateQueries('deleteRecognitionCategory');
        },
    });

    async function handleSubmitForm() {
        const {status} = await recognitionCategoryService.deleteRecognitionCategory({categoryId : recognitionCategory.id!});
        if(status === responseStatus.SUCCESS){
            setShowModalSuccess(true);
            setTimeout(()=>{
                onReload();
            }, 1000);
        }
    }

    async function onSubmit() {
        mutate();      
    }

    return{
        showModalSuccess,
        isLoadingSubmit,
        setShowModalSuccess,
        onSubmit
    }
}
