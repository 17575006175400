import * as S from './cardConfig.styled';
import * as Slider from '@radix-ui/react-slider';
import wallet from '../../../assets/icons/Wallet.svg'
import { ButtonComponent } from '../button/index';
import { CardConfigType } from './cardConfig.type';
import { useCardConfigHook } from './cardConfig.hook';
import { baseUrl } from '../../../api/api';

export const CardConf = ({ title, subtitle, maxValue, minValue, values, onChange, isLoading, width, onSelect }: CardConfigType) => {

    const cardConfHook = useCardConfigHook(values);

    return (
        <S.ContainerConf data-width={width}>
            {maxValue && minValue && values ?
                <>
                    <S.Header>
                        <p className='c1-regular '>{title}</p>
                        <p className='c1-regular subtitle'>{subtitle}</p>
                    </S.Header>

                    <S.Line />

                    {cardConfHook.newValues?.map((newValue, index) => (
                        <S.Limite>
                            <div className={`name-container ${newValue.id ? 'cursor-pointer' : ''}`} onClick={onSelect ? ()=>onSelect(newValue.id) : ()=>{}}>
                                {newValue.icon ? <img src={`${baseUrl}/${newValue.icon}`} alt='Icone da categoria' /> : <></>}
                                <p className='b3-regular'> {newValue.name}</p>
                            </div>


                            <div className='item'>

                                <Slider.Root
                                    className='SliderRoot'
                                    onValueChange={(value) => cardConfHook.handleChange(value, index)}
                                    defaultValue={[newValue.value || 0]}
                                    max={maxValue}
                                    min={minValue}
                                    step={1}
                                >
                                    <S.SlideRoot>
                                        <Slider.Track className="SliderTrack">
                                            <Slider.Range className="SliderRange" />
                                        </Slider.Track>
                                        <Slider.Thumb className="SliderThumb" aria-label="Volume" />
                                    </S.SlideRoot>
                                </Slider.Root>



                                <S.Points>
                                    <img src={wallet} alt="icone de Wallet" />
                                    <p className='b3-regular'>{newValue.value} pts</p>
                                </S.Points>
                            </div>

                        </S.Limite>
                    ))}

                    <S.Button>
                        <ButtonComponent disabled={isLoading} width={110} buttonStyle='primary' onClick={() => onChange(cardConfHook.newValues)}>
                            {isLoading ? "Salvando..." : "Salvar"}
                        </ButtonComponent>
                    </S.Button>
                </> :
                <></>
            }
        </S.ContainerConf>
    )

}