import { useEffect, useState } from "react";
import { useRewardCalendarService } from "../../../services/reward-calendar.service";
import { useQuery } from "react-query";
import { responseStatus } from "../../../api/api-request.service";
import { RewardCalendarInterface } from "../../../models/reward-calendar.interface";

export const useDateCalendarHook = (value? : number) => {

 
  const [modalVisible, setModalVisible] = useState(true);
  const [newValue, setNewValue] = useState(value);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [showCalendarPortal, setShowCalendarPortal] = useState(false);
  const rewardsCalendarService = useRewardCalendarService();

  const handleCloseCalendar = () => {
   
    setModalVisible(false);
  };



    const {
        data: rewardsCalendarData,
        isLoading: isLoadingrewardsCalendar,
        refetch,
    } = useQuery<RewardCalendarInterface>('getRewardsCalendar', getRewardsCalendar);

    const [dateRange, setDateRange] = useState<([Date | undefined | null,  Date | undefined | null])>();

    useEffect(()=>{
      if(rewardsCalendarData){
        const startActivationDay = new Date(`${rewardsCalendarData.startActivationDay}T00:00:00`);
        const endActivationDay = new Date(`${rewardsCalendarData.endActivationDay}T00:00:00`);
    
        setDateRange([startActivationDay, endActivationDay])
      }

    },[rewardsCalendarData]) 

    

   
    

    async function getRewardsCalendar() {
        const { status, data } = await rewardsCalendarService.getRewardsCalendar();
        if (status === responseStatus.SUCCESS) {
            return data;

        }

        

  



    }
    async function editRewardsDate() {
      if(dateRange){
        setIsLoadingSubmit(true);
        const { status, data } = await rewardsCalendarService.putRewardsDate({id: rewardsCalendarData?.id, startActivationDay:dateRange[0]!,  endActivationDay:dateRange[1]!}).finally(()=>setIsLoadingSubmit(false));
        if (status === responseStatus.SUCCESS) {
          setTimeout(() => {
            setShowCalendarPortal(false);
            refetch();
        }, 1000);
          return data;
        }
      }

      }
      // fieldesArray
   
    
    
  const handleEditData = async () => {
      await editRewardsDate();
      
  }



   return{
    dateRange,
    setDateRange,
    rewardsCalendarData,
    isLoadingrewardsCalendar,
    getRewardsCalendar,
    newValue, 
    setNewValue,
    editRewardsDate,
    setShowCalendarPortal,
    handleEditData ,
    isLoadingSubmit,
    handleCloseCalendar,
    modalVisible,
    refetch,
    showCalendarPortal
   }


    }
  


  