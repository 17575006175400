import * as S from "./modalNewEmployee.styled";

import { ButtonComponent } from "../button";
import { useModalNewEmployeeHook } from "./modalNewEmployee.hook";
import { ModalSuccess } from "../modalSuccess";
import { ModalNewEmployeeType } from "./modalNewEmployee.type";
import { baseUrl } from "../../../api/api";

import InputMask from "react-input-mask";

import profile_icon from "../../../assets/icons/profile.svg";
import { SectionContainer } from "../../modules/performance/config/confg.styled";

export const ModalNewEmployee = ({ onClose, employee, type }: ModalNewEmployeeType) => {
    const hook = useModalNewEmployeeHook({ onClose, initialData: employee });

    console.log(employee)
    return (
        <S.DialogContent>
            {hook.showModalSuccess ? (
                <ModalSuccess message={`${type === "MANAGER" ? "Gestor" : "Funcionário"} ${employee?.id ? "editado" : "cadastrado"} com sucesso!`} />
            ) : (
                <>
                    <form
                        onSubmit={hook.handleSubmit(
                            hook.onSubmit
                        )}
                    >
                        <S.Container>
                            <p className="b3-regular">Informações</p>

                            <S.NomeContairner>
                                <label htmlFor="profile-image">
                                    <img
                                        src={
                                            hook.file ?
                                                hook.file :
                                                employee?.profilePic?.content ?
                                                    `${baseUrl}/${employee?.profilePic?.content}` :
                                                    profile_icon
                                        }
                                        alt="Perfil do usuário"
                                    />

                                    <input
                                        className="profile-input"
                                        id="profile-image"
                                        accept='image/jpg, image/png'
                                        type="file"
                                        onChange={hook.handleProfileImageChange}
                                    />
                                </label>
                                <div className="nome">
                                    <p className="c1-regular">Nome</p>
                                    <input
                                        placeholder="Nome"
                                        type="text"
                                        className="b2-regular"
                                        {...hook.register("name")}
                                    />
                                    <span className="c1-regular error">
                                        {hook.errors.name?.message}
                                    </span>
                                </div>
                            </S.NomeContairner>
                            <S.Row>
                                <S.DadosContainer>
                                    <p className="c1-regular">CPF</p>
                                    <InputMask
                                        mask="999.999.999-99"
                                        type="text"
                                        className="b2-regular"
                                        placeholder="CPF"
                                        {...hook.register("cpf")}
                                    />
                                    <span className="c1-regular error">
                                        {hook.errors.cpf?.message}
                                    </span>
                                </S.DadosContainer>

                                <S.DadosContainer>
                                    <p className="c1-regular">Admissão</p>
                                    <input
                                        type="date"
                                        className="b2-regular"
                                        placeholder="Data de Admissão"
                                        {...hook.register("admissionDate")}
                                    />
                                    <span className="c1-regular error">
                                        {hook.errors.admissionDate?.message}
                                    </span>
                                </S.DadosContainer>
                            </S.Row>

                            <S.Row>

                                <S.DadosContainer>
                                    <p className="c1-regular">Setor</p>
                                    <select
                                        id="sectorId"
                                        className={`b2-regular`}
                                        defaultValue={employee?.sector?.id || ''}
                                        {...hook.register("sectorId")}
                                    >
                                        <option hidden disabled value=''></option>
                                        {hook.sectorsData?.rows.map(sector => (
                                            <option value={sector.id} selected={sector.id === employee?.sector?.id}>{sector.name}</option>
                                        ))}

                                    </select>
                                    <span className="c1-regular error">
                                        {hook.errors.sectorId?.message}
                                    </span>
                                </S.DadosContainer>

                                <S.DadosContainer>
                                    <p className="c1-regular">Cargo</p>
                                    <input
                                        type="text"
                                        className="b2-regular"
                                        placeholder="Cargo"
                                        {...hook.register("position")}
                                    />
                                    <span className="c1-regular error">
                                        {hook.errors.position?.message}
                                    </span>
                                </S.DadosContainer>

                            </S.Row>

                            <S.Subtitulo>
                                <p className="b3-regular subtitulo">Acesso</p>
                            </S.Subtitulo>

                            <S.DadosContainer>
                                <p className="c1-regular">E-mail Corporativo</p>
                                <input
                                    placeholder="E-mail Corporativo"
                                    type="text"
                                    className="b2-regular"
                                    {...hook.register("email")}
                                />
                                <span className="c1-regular error">
                                    {hook.errors.email?.message}
                                </span>
                            </S.DadosContainer>

                            <S.DadosContainer>
                                <p className="c1-regular">Senha</p>
                                <input
                                    placeholder={employee?.id ? "Opcional" : "Senha"}
                                    type="password"
                                    className="b2-regular"
                                    {...hook.register("password")}
                                />
                                <span className="c1-regular error">
                                    {hook.errors.password?.message}
                                </span>
                            </S.DadosContainer>

                        </S.Container>


                        <ButtonComponent
                            buttonStyle="primary"
                            type="submit"
                            disabled={hook.isLoadingSubmit}
                        >
                            {hook.isLoadingSubmit ? "Salvando..." : employee?.id ? "Editar Funcionário" : "Adicionar Funcionário"}
                        </ButtonComponent>

                        {!employee?.groups.find((group:any) => group.name === "MANAGER") &&
                            <ButtonComponent
                                buttonStyle="secondary"
                                type="button"
                                disabled={hook.isLoadingSubmit}
                                onClick={()=>hook.handleTransformManager(employee!)}
                            >
                                {hook.isLoadingSubmit ? "Salvando..." : "Tornar gestor"}
                            </ButtonComponent>
                        }
                    </form>
                </>
            )}
        </S.DialogContent>
    );
};
