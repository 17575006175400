
import { RewardsInterface } from '../../../../models/rewards.interface';
import { LoadingComponent } from '../../../components/loading';
import { RewardCardComponent } from '../../../components/rewardCard';
import { useRewardsHook } from './reconhecer-card.hook';
import * as S from './reconhecer-card.styled';
import { ReconhecerCardType } from './reconhecer-card.type';

export const ReconhecerCardTab = (props: ReconhecerCardType) => {
    const hook = useRewardsHook(props)
    return (
        <S.ReconhecerCardContainer>
            {hook.isLoadingRewards || hook.isRefetringRewards ?
                <LoadingComponent containerHeight={300}/> :
                <>
                    {hook?.rewardsData?.count === 0 &&
                        <span className='empty-card b3-regular'>Nenhuma recompensa encontrada</span>
                    }
                    {hook?.rewardsData?.rows.map((rewards: RewardsInterface) => (
                        <RewardCardComponent rewards={rewards} />
                    ))}
                </>
            }

        </S.ReconhecerCardContainer>
    );
}