import styled from "styled-components";

export const HankingContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 22px;

    .empty-container{
        display: flex;
        width: 100%;
        height: 200px;
        align-items: center;
        justify-content: center;
    }
`;

export const HankingFilter = styled.div`
    display: inline-flex;
    align-items: center;
    gap: 16px;
`;

export const HankingCardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`;

export const HankingEmployeeContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;
    overflow: scroll;

    border-radius: 4px;
    background: var(--minimal-color);
`;