import { useState } from "react";
import { useAppSelector } from "../../../hooks/hooks";
import { CardEmployeeRewardHookType } from "./cardEmployeeReward.type";

export function useCardEmployeeRewardHook({recognition} : CardEmployeeRewardHookType){
    const [showProgress, setShowProgress] = useState(false);
    const [showReject, setShowReject] = useState(false);
    const userAuth = useAppSelector(state=>state.user);

    const isConfirmable = ((recognition.status === "PENDING" && recognition.author?.managerTeam?.fkSecondaryManagerId?.toString() === userAuth.user_id.toString()) ||
    (recognition.status === "CONFIRMED" && userAuth.isManager)) || (recognition.status === "PENDING" && userAuth.isManager && recognition.author?.managerTeam?.userSecondaryManager?.sector?.id === 10);

    return{
        isConfirmable,
        showProgress,
        setShowProgress,
        showReject,
        setShowReject
    }
}