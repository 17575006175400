import { QueryClient } from "react-query";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            
        },
    }
});

export const FETCH_LIMIT_TABLE = 10;

export function getOffset(page: number) {
    return (page - 1) * FETCH_LIMIT_TABLE;
}

export function getDefaultQuery(props: { page?: number, filters?: any[] }) {
    let query = '';

    const formattedFilters = props.filters?.map(filter => {
        const key = Object.keys(filter)[0];
        const value = filter[key];
        return value !== undefined && `${key}=${value}`;
    }).filter(Boolean);

    query += formattedFilters?.join('&');

    if (props.page) {
        const offset = props.filters ? props.filters.length === 0 ? '' : '&' : '?';
        query += `${offset}offset=${getOffset(props.page)}&limit=${FETCH_LIMIT_TABLE}`;
    }

    if (query) {
        query = `?${query}`;
    }

    return query;
}
