import styled from "styled-components";

export const RewardEmployeeModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    flex-shrink: 0;

    .button-continue{
        width: 100%;
    }
`;

export const RewardEmployeeModalContent = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 4px;
    background: var(--minimal-color);
`;