import { FETCH_LIMIT_TABLE } from '../../../utils/query-client';
import { ArrowComponent } from '../arrow';
import { usePaginationHook } from './hook';
import * as S from './styled';
import { PaginationType } from './type';

export const PaginationComponent = ({page, setPage, totalItems} : PaginationType)=>{

    const paginagionHook = usePaginationHook({page, setPage, totalItems});

    return(
        <S.PaginationContainer>
            <span className='b3-bold'>{page} página <span className="b3-regular">de {Math.ceil(totalItems / FETCH_LIMIT_TABLE)}</span></span>
            <S.PaginationAction>   
                <ArrowComponent className='small' direction={'left'} color={'black'} onClick={paginagionHook.handlePreviusPage}/>
                <ArrowComponent className='small' direction={'right'} color={'black'} onClick={paginagionHook.handleNextPage}/>
            </S.PaginationAction>
        </S.PaginationContainer>
    );
};