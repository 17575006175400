import styled from "styled-components";

interface ArrowContainerProps {
    direction: 'down' | 'up' | 'left' | 'right'
}

export const ArrowContainer = styled.div<ArrowContainerProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        transform: rotate(${props=>
            props.direction === 'up' ?
            180 :
            props.direction === 'left' ?
            90 :
            props.direction === 'right' ?
            270 :
            0
        }deg);
    }
`;