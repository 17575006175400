import styled from "styled-components";

interface HomeHistorycCardProps{
    height: number;
}

export const HomeHistorycCard = styled.div<HomeHistorycCardProps>`
    display: flex;
    width: 173px;
    height: ${props=>props.height}px;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;

    border-radius: 8px;
    background: var(--minimal-color);

    img{
        display: flex;
        width: 32px;
        height: 32px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;

        border-radius: 4px;
        background: var(--blue-color-20);
    }

    span{
        color:var(--grey-color-80);
        text-align: center;
    }

    div{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        align-self: stretch;
    }
`;

export const HomeManagerContainer = styled.section`
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
`;

export const HomeManagerContent = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 16px;
    flex-wrap: wrap;
`;

export const HomeSearchBar = styled.section`
    display: flex;
  
    align-items: space-between;
    gap: 8px;

    .filter-image{
        display: flex;
        width: 40px;
        height: 40px;
        padding: 12px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        border-radius: 4px;
        background: var(--minimal-color);
    }
`;






export const DialogTitle = styled.h2`
  margin: 0;

  
  
  
`;


export const Button = styled.button`
    cursor: pointer;
    display: flex;
    gap: 8px;
    padding: 17px 16px;
    align-items: center;
    justify-content: center;
    height: 36px;
    width:100%;
    border: none;
    border-radius: 8px;
    margin-top:24px;
    background-color: var(--blue-color-100);
 
    color:var(--white-color);
    &:disabled {
      background-color: var(--blue-color-40);
      
   
  }
  
  
`;

