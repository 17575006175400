import styled from "styled-components";


export const CardNotificationContainer = styled.div`
    width: 100%;
    height: 100%;
    
    .empty-notifications{
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
`;

export const TitleDescription = styled.div`
    display: flex;
    flex-direction:row;
    justify-content:space-between;
    border:none;
    .active{
        background: var(--blue-color-20)
    }
   
`;
export const CardNotification = styled.div`
   width:350px;
   align-items: flex-start;
   gap: 4px;
   align-self: stretch;
   padding:12px;
   cursor:pointer;
   border:none;
   
`;