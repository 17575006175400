import { AllHistory } from '../../../components/allHistory';
import { UpdateCardComponent } from '../../../components/updateCard';
import * as S from './history-all.styled';
import { HistoryAllType } from './history-all.type';

export const HistoryAllTab = ({ recognitions, updateRecognitions }: HistoryAllType) => {
    return (
        <S.HistoryAllContainer>
            {recognitions?.map((recognition) => (
                <UpdateCardComponent width={338} recognition={recognition} updateRecognitions={() => updateRecognitions()} />
            ))}
        </S.HistoryAllContainer>
    );

}