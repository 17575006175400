export function formatPrice(value: number | string) {
    const options = {
        style: 'currency',
        currency: 'BRL' 
    };

    return value.toLocaleString('pt-BR', options);
}

export function isEmailValid(email: string) {
    // Expressão regular para validar e-mail
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function isCPFValid(cpf : string) {
    // Remove caracteres especiais e verifica se tem 11 dígitos
    cpf = cpf.replace(/[^\d]+/g,'');
    if (cpf.length !== 11) return false;

    // Verifica se todos os dígitos são iguais, o que não é válido para um CPF
    if (/^(\d)\1+$/.test(cpf)) return false;

    // Validação do primeiro dígito verificador
    let add = 0;
    for (let i = 0; i < 9; i++) {
        add += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(9))) return false;

    // Validação do segundo dígito verificador
    add = 0;
    for (let i = 0; i < 10; i++) {
        add += parseInt(cpf.charAt(i)) * (11 - i);
    }
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(10))) return false;

    return true;
}
