import styled from "styled-components";


export const CardHistory = styled.div`
    position: relative;
    display: flex;
    width: 425px;
    height:75px;
    padding: 8px 24px;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
    background: var(--white-color);
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Title = styled.div`
    display: flex;
    flex-direction: row;
    gap:130px;
    align-items: flex-start;
    align-self: stretch;

    span{
        justify-self: flex-end;
    }
`;

export const ReconhecimentoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch; 
    width: 344px;

    .seta{
        cursor: pointer;
    }

    .trigger{
        width: 24px;
        height: 24px;
        background-color: transparent;
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
    }
    
`;

export const ReconhecimentoContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;

    .category-icone{
        width: 21px;
        height: 21px;
    }

    .image-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px;
        height: 21px;
        width: 21px;

        background-color: var(--blue-color-100);
        border-radius: 50%;
    }

    div{
        padding: 2px 4px !important;
        border-radius: 4px;
        background: var(--minimal-color);
        display: flex;
        padding: 2px;
        gap: 4px;
        align-items: center;
        justify-content: center;
        min-height: 25px;
    }

`;

export const DialogOverlay = styled.div`
    background-color: var(--black-a9);
    position: fixed;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    pointer-events: none;
`;

export const DialogContent = styled.div`
    border-radius: 12px;
    background: var(--White, #FFF);
    box-shadow: 0px 4px 32px 0px rgba(61, 70, 112, 0.08);

    
    position: absolute;
    top:50%;
    z-index:10;
    
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95vw;
    max-width: 450px;
    max-height:90vh;
    margin-top :24px;
    padding: 25px;
    gap:24px;

    .button{
        margin-top:24px;
    }

    & :focus {
        outline: none;
    }

    .divHeader{
        all:unset;
        display: flex;
        padding: 4px;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
  
    
        img{
            all:unset;
            height: 24px;
            width: 24px;
        }

    }


`;

export const Modal = styled.div`
    position:absolute;
    z-index:99;
    top:10%;
    left:30%;
    background:var(--white-color);
    

    padding:16px;

    .button {
        margin-top:12px;
    }

`;