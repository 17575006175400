import { useState } from "react";
import { LocalToken } from "../../../utils/local-token";

export function useExitModalHook(){
    const [isLoading, setIsLoading] = useState(false);
    function handleExit(){
        setIsLoading(true);
        LocalToken.clearStoredToken();
        window.location.reload();
    }

    return{
        isLoading,
        handleExit
    }
}