import { useQuery } from "react-query";
import { useRewardsService } from "../../../../services/rewards.service";
import { RewardsDataType } from "../../../../models/rewards.interface";
import { responseStatus } from "../../../../api/api-request.service";
import { BenkiHookType } from "./benki.type";
import { useEffect } from "react";

export function useBenkiHook(props: BenkiHookType) {

    const rewardsService = useRewardsService();
   

    const {
        data: rewardsData,
        isLoading: isLoadingRewards,
        isRefetching: isRefetringRewards,
        refetch
    } = useQuery<RewardsDataType>('getRewardsBenki', getRewards);


    async function getRewards() {
        let order, orderBy;
        let [item, value] = props.selectedFilter!.split('-');
        let isActive;

        if(item === 'ISACTIVE'){
            isActive = value === 'TRUE';
        }else{
            order = value;
            orderBy = item;
        }

        const { status, data } = await rewardsService.getRewardsByCategoryName({rewardsName : "BENKI", search: props.search, orderBy, isActive, order});
        if (status === responseStatus.SUCCESS) {
            return data;

        }

    }

    useEffect(()=>{
        refetch()
    }, [props.selectedFilter, props.reload]);

    return {
        rewardsData,
        isLoadingRewards,
        isRefetringRewards,
        
    }
}



