import { useQuery } from "react-query";
import { EmployeeDataType } from "../../../models/employee.interface";
import { responseStatus } from "../../../api/api-request.service";
import { useEmployeeService } from "../../../services/employee.service";
import { EmployeeListHookType } from "./employee-list.type";
import { useEffect, useState } from "react";

export function useEmployeeListHook(props : EmployeeListHookType){
    const employeeService = useEmployeeService();
    const [page, setPage] = useState(1);

    const {
        data : employeesData, 
        isLoading : isLoadingEmployeesData,
        isRefetching : isRefetchingEmployeesData,
        refetch: refetchEmployee
    } = useQuery<EmployeeDataType>('getEmployees', getAllEmployees);

    async function getAllEmployees(){
        const [orderBy, order] = props.selectedFilter.split('-');
        const {status, data} = await employeeService.getAllEmployees({search: props.search, orderBy, order, page });
        if(status === responseStatus.SUCCESS){
            return data;
        }
    }

    useEffect(()=>{
        refetchEmployee();
    }, [props.reload, page, props.selectedFilter]);

    return {
        employeesData,
        isLoadingEmployeesData,
        isRefetchingEmployeesData,
        refetchEmployee,
        page,
        setPage
    }

    
}
