import { ConfigType } from "./config.type";
import { useConfigHook } from "./config.hook";
import * as S from './confg.styled';
import { CardConf } from "../../../components/cardConfig";
import { LoadingComponent } from "../../../components/loading";
import { DialogComponent } from "../../../components/dialog";
import { ModalNewRecognition } from "./Components/modalNewRecognition";

export const ConfigTab = ({ reload }: ConfigType) => {
    const configHook = useConfigHook()

    return (
        <S.ConfigContainer>
            {configHook.isLoading ?
                <LoadingComponent containerHeight={300} /> :
                <CardConf
                    width={645}
                    title="Setor"
                    subtitle="Valor"
                    maxValue={5000}
                    minValue={100}
                    onChange={configHook.onChangePointsCategory}
                    values={configHook.recognitionCategoriesData?.rows.map(category => ({ id: category.id, name: category.name, value: category.points, icon: category.svg?.content }))}
                    isLoading={configHook.isLoadingSubmit}
                    onSelect={configHook.handleSelectItem}
                />
            }

            <DialogComponent
                title={'Editar Reconhecimento'}
                isOpen={configHook.isOpenModal}
                onOpen={() => configHook.setIsOpenModal(true)}
                onClose={() => configHook.setIsOpenModal(false)}
                width={418}
                content={<ModalNewRecognition recognition={configHook.selectedItem} onClose={configHook.handleClose} />}
            >
                <></>

            </DialogComponent>

        </S.ConfigContainer>

    )



}