import { useQuery } from 'react-query'
import { responseStatus } from '../../../../api/api-request.service';
import { useRecognitionService } from '../../../../services/recognition.service';
import { RecognitionDataType } from '../../../../models/recognition.interface';
import { useManagerService } from '../../../../services/manager.service';
import { EmployeeDataType, EmployeeInterface } from '../../../../models/employee.interface';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../hooks/hooks';



export function useHomeHook() {
    const today = new Date();
    const userAuth = useAppSelector(state=>state.user);

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenModalEmployee, setIsOpenModalEmployee] = useState<EmployeeInterface | null>(null);
    const [searchManager, setSearchManager] = useState('');
    const [reload, setReload] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState('ASC');
    
    const recognitionService = useRecognitionService();
    const managerService = useManagerService();
    const navigate = useNavigate();

    const filters= [
        {value: 'DESC', label: 'Mais Reconhecimentos'},
        {value: 'ASC', label: 'Menos Reconhecimentos'}
    ];

    const {
        data: recognitionData,
        isLoading: isLoadingRecognition,
        isRefetching: isRefetchingRecognition,
        refetch: refetchRecognitions
    } = useQuery<RecognitionDataType>('getAllUpdateCardHome', userAuth.isManager ? getRecognitionByStatus : getRecognitions);

    const {
        data: managerData,
        isLoading: isLoadingManager,
        isRefetching: isRefetchhingManager,
        refetch: refetchManagers,
    } = useQuery<EmployeeDataType | undefined>('getAllManagerHome', getManagerCard);

    async function getRecognitionByStatus() {
        if(userAuth.isManager !== null){
            const { status, data } = await recognitionService.getRecognitionByStatus({status : "CONFIRMED", fkSectorId: 10});
            if (status === responseStatus.SUCCESS) {
                return data;
            }
        }

    }

    async function getRecognitions() {
        if(userAuth.isManager !== null){
            const { status, data } = await recognitionService.getRecognitions({authorId : userAuth.user_id, status: 'PENDING'});
            if (status === responseStatus.SUCCESS) {
                return data;
            }
        }

    }

    async function getManagerCard() {
        if(userAuth.isManager){
            const { status, data } = await managerService.getAllManagers({search : searchManager, order: selectedFilter, orderBy: 'RECOGNITIONS'});
            if (status === responseStatus.SUCCESS) {
                return data;
            }
        }
    }

    function handleSearchReload(){
        if(userAuth.isManager){
            refetchManagers()
        }else{
            setReload(!reload);
        }
    }

    useEffect(()=>{
        refetchRecognitions();
        refetchManagers();
    }, [userAuth.isManager]);

    useEffect(()=>{
        refetchManagers();
    }, [selectedFilter]);

    return {
        recognitionData,
        isLoadingRecognition: isLoadingRecognition || isRefetchingRecognition,
        managerData,
        isLoadingManager,
        isRefetchhingManager,
        today,
        isOpenModal,
        isOpenModalEmployee,
        searchManager, 
        isManager: userAuth.isManager,
        reload,
        filters,
        selectedFilter,
        setSelectedFilter,
        setSearchManager,
        navigate,
        setIsOpenModal,
        setIsOpenModalEmployee,
        refetchRecognitions,
        refetchManagers,
        handleSearchReload
    }
}
