import { useState } from "react";
import { SliderType } from "./cardConfig.type";

export function useCardConfigHook(values? : SliderType){
    const [newValues, setNewValues] = useState(values);

    function handleChange(newValue: number[], valueIndex: number){
        setNewValues(newValues!.map((value, index)=>valueIndex === index ? ({...value, value: newValue[0]}) : value))
    }

    return{
        newValues,
        setNewValues,
        handleChange
    }
}