import { useQuery } from 'react-query'
import { NotificationDataType } from '../../../models/notification.interface';
import { responseStatus } from '../../../api/api-request.service';

import { useAppSelector } from '../../../hooks/hooks';
import { useUserLoginService } from '../../../services/user-login.service';
import { UserLoginInterface } from '../../../models/user-login.interface';
import { useEffect } from 'react';

export function useUserLoginHook() {
    const user = useAppSelector((state) => state.user);
    const sidebarItem = useAppSelector(state => state.general.sideBarItem);
    const userLoginService = useUserLoginService();

    const {
        data: userLoginData,
        isLoading: isLoadingUserLogin,
        refetch: refetchUserLogin
    } = useQuery<UserLoginInterface>('getAllUserLogin', getAllUserLogin);

    async function getAllUserLogin() {
        if (user.user_id) {
            const { status, data } = await userLoginService.getUserLogin(user.user_id);
            if (status === responseStatus.SUCCESS) {
                return data;
            }
        }
    }

    useEffect(() => {
        if (user.user_id) {
            refetchUserLogin()
        }
    }, [user.user_id])

    return {
        userLoginData, 
        isLoadingUserLogin, 
        sidebarItem 
    }
}