import * as S from './manager-card.styled';
import profile_imagem from '../../../assets/images/dev/profile.svg';
import { baseUrl } from '../../../api/api';

import { ManagerCardType } from './manager-card.type';
import { useState } from 'react';


import profile_icon from  '../../../assets/icons/profile.svg';
import { TeamProfilesComponent } from '../teamProfiles';

export const ManagerCardComponent = ({manager, onClick} : ManagerCardType)=>{
    return(
        <S.ManagerCardContainer onClick={onClick}>
            <S.ManagerCardDetails>
                <img src={
                        manager.profilePic?.content ? 
                        `${baseUrl}/${manager.profilePic?.content}` :
                        profile_icon
                    } 
                    alt="Perfil do Gestor" 
                />

                <S.ManagerCardContent>
                    <span className="b1-regular">{manager.name}</span>
                    <TeamProfilesComponent imageWidth={24} team={manager?.managerTeam?.employeers}/>
                </S.ManagerCardContent>

            </S.ManagerCardDetails>

            <p className='b3-regular'>Últimos reconhecimentos</p>

            <S.ManagerCardRewards>
                {manager.recognitionCategoriesCount?.map(recognitionCategory=>(
                    <S.ManagerCardRewardsItem>
                        <img 
                            className={recognitionCategory.count > 0 ? 'active' : ' '} 
                            src={`${baseUrl}/${recognitionCategory.category.svg?.content}`} alt="Icone da categoria" 
                        />
                        <span className="b3-regular ">{recognitionCategory.count}</span>
                    </S.ManagerCardRewardsItem>
                ))}
            </S.ManagerCardRewards>

        </S.ManagerCardContainer>
    );
};