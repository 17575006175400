import { useEffect, useRef, useState } from "react";

export function useCardEditHook(){
    const [isOpen, setIsOpen] = useState(false);

    const cardEditRef = useRef<any>(null);

    useEffect(() => {
        const handleClickOutside = (event : MouseEvent) => {
            if (cardEditRef.current && !cardEditRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setIsOpen]);

    return{
        isOpen,
        cardEditRef,
        setIsOpen
        
    }
}