import { useState } from "react";
import { responseStatus } from "../../../api/api-request.service";
import { RewardsInterface } from "../../../models/rewards.interface";
import { useRewardsService } from "../../../services/rewards.service";
import { CardEditOptionType } from "../cardEdit/cardEdit.type";

export function useRewardCardHook() {

    const rewardsService = useRewardsService();
    const [showEditModal, setShowEditModal] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const editOptions : CardEditOptionType[] = [
        {title: 'Editar Informações', action: ()=>setShowEditModal(true)},
        {title: 'Excluir recompensa', action: ()=>setShowRemoveModal(true)}
    ];

    async function handlerActive(isActive: boolean, rewards: RewardsInterface) {
        rewards = { ...rewards, isActive }
        const { status, data } = await rewardsService.putRewards({ ...rewards })
        return { rewards, status, data }
    }

    return { 
        editOptions,
        handlerActive,
        showEditModal,
        showRemoveModal,
        setShowEditModal,
        setShowRemoveModal

    }


}