import { z } from "zod";
import { EmployeeInterface } from "../../../models/employee.interface";

export type ModalNewManagerType = {
    onClose: Function;
    manager?: EmployeeInterface;
}


export type ModalNewManagerHookType = {
    onClose: Function;
    initialData?: EmployeeInterface;
}

export function getManagerSchema(id?: number) {
    return z.object({
        name: z.string().min(1, { message: "O campo 'Nome' é obrigatório." }),
        cpf: z.string().min(1, { message: "O campo 'CPF' é obrigatório." }),
        position: z.string().min(1, { message: "O campo 'Cargo' é obrigatório." }),
        admissionDate: z.string().min(1, { message: "O campo 'Admissão' é obrigatório." }),
        fkSectorId: z.string().min(1, { message: "O campo 'Setor' é obrigatório." }),
        email: z.string().min(1, { message: "O campo 'E-mail corporativo' é obrigatório." }).email('Digite um email válido'),
        password: id ? z.string().optional() : z.string().min(1, { message: "O campo 'Senha' é obrigatório." }),
        fkManagerIds: id ?
            z.array(z.object({
                managerId: z.number().optional(),
                id: z.number().optional()
            })).optional() :
            z.array(z.object({
                managerId: z.number().optional(),
                id: z.number().optional()
            }))
                .refine(arr =>arr.length > 0 && arr[0].managerId, {
                    message: "O campo 'Gestor' é obrigatório.",
                })
                .default([{}]),
        employees:
            z.array(z.object({
                employeeId: z.number().optional(),
                id: z.number().optional()
            })).optional()
    });
}
