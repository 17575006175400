import * as S from './arrow.styled';
import arrow_down_icon from  '../../../assets/icons/arrow_down.svg';
import arrow_down_black_icon from  '../../../assets/icons/arrow_down_black.svg';
import { ArrowType } from './arrow.type';

export const ArrowComponent = ({className, direction, color, onClick} : ArrowType)=>{
    return (
        <S.ArrowContainer className={className} direction={direction} onClick={onClick ? ()=>onClick() : ()=>{}}>
            {color === 'blue' &&
                <img src={arrow_down_icon} alt="Icone Seta"/>
            }

            {color === 'black' &&
                <img src={arrow_down_black_icon} alt="Icone Seta"/>
            }
        </S.ArrowContainer>
    );
};