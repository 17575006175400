import styled from "styled-components";

export const HistoryPendingContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 24px;
    flex-wrap: wrap;
    
    .empty-text{
        width: 100%;
        text-align: center;
    }
`;