

import * as SA from '../../../../app.styled';

import { ButtonComponent } from '../../../components/button';
import { TabBarComponent } from '../../../components/tabBar';
import { useEmployeeHook } from './employee.hook';
import { HeaderPageComponent } from '../../../components/headerPage';
import { DialogComponent } from '../../../components/dialog';
import { ModalNewManager } from '../../../components/modalNewManager';
import { ModalNewEmployee } from '../../../components/modalNewEmployee';

import add_icon from '../../../../assets/icons/add.svg';
import { ModalSuccess } from '../../../components/modalSuccess';

export const EmployeePage = () => {

    const hook = useEmployeeHook();

    return (
        <SA.AppContainerHome>
            <SA.AppTopBar>
                <HeaderPageComponent
                    title='Funcionários'
                    onSearch={hook.selectedTab !== 'Configurações' ? (value: string) => { hook.setSearch(value) } : null}
                    refetch={hook.onReload}
                    filters={hook.filters}
                    selectedFilter={hook.selectedFilter}
                    setSelectedFilter={hook.setSelectedFilter}
                />

                {hook.selectedTab !== 'Configurações' &&
                    <div className='actions'>
                        <ButtonComponent disabled={hook.loadingUpload} buttonStyle='secondary' width={174} onClick={hook.handleSelectFile}>
                            <span className='b3-bold'>
                                {hook.loadingUpload ? 
                                    'Importando...' : 
                                    `Importar ${hook.selectedTab === 'Gestores' ? 'Gestores' : 'Funcionários'}`
                                }
                            </span>
                        </ButtonComponent>
                        <input type='file' hidden id='upload-file' accept=".xls,.xlsx,.xlsm" onChange={hook.handleChangeFile}/>
    

                        <DialogComponent
                            title={'Novo funcionário'}
                            isOpen={hook.isOpenModal}
                            onOpen={() => hook.setIsOpenModal(true)}
                            onClose={() => hook.setIsOpenModal(false)}
                            width={418}
                            content={hook.selectedTab === 'Gestores' ?
                                <ModalNewManager onClose={hook.handleClose} /> :
                                <ModalNewEmployee onClose={hook.handleClose} />
                            }
                        >
                            <ButtonComponent buttonStyle='primary' width={174} onClick={() => hook.setIsOpenModal(true)}>
                                <img src={add_icon} alt="Icone de adicionar" />
                                <span className='b3-bold'>Novo {hook.selectedTab === 'Gestores' ? 'Gestor' : 'Funcionário'}</span>
                            </ButtonComponent>

                        </DialogComponent>


                        <DialogComponent
                            isOpen={hook.isOpenSuccessModal}
                            onOpen={() => hook.setIsOpenSuccessModal(true)}
                            onClose={() => hook.setIsOpenSuccessModal(false)}
                            width={418}
                            content={<ModalSuccess message={`${hook.selectedTab} cadastrados com sucesso!`}/>}
                        />
                    </div>
                }
            </SA.AppTopBar>

            <TabBarComponent tabs={hook.tabsList} selectedTab={hook.selectedTab} setSelectedTab={hook.setSelectedTab} />

            {hook.tabs[hook.selectedTab]}
        </SA.AppContainerHome>
    );
}
