import styled from "styled-components";

interface DropdownOptionsContainerProps{
    width?: number;
}

export const DropdownOptionsContainer = styled.div<DropdownOptionsContainerProps>`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: ${props => props.width ? `${props.width}px` : '100%'};
    top: 46px;
    background-color: var(--white-color);
    border-radius: 8px;
    padding: 8px;
    border: 1px solid var(--card-border-color);
    gap: 8px;
    max-height: 300px;
    overflow-y: scroll;

    box-shadow: 1px 1px 4px rgba(0,0,0,0.5);

    .active{
        background-color: var(--blue-color-100);
        color: var(--white-color);

        &:hover{
            color: var(--black-color);
        }
    }
`;

export const DropdownOptionsItem = styled.div`
    padding: 12px 16px;
    border-radius: 8px;
    cursor: pointer;
    background-color: var(--white-color);

    &:hover{
        background-color: var(--blue-color-60);
        font-weight: 600;
    }
`