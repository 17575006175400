import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './utils/query-client';
import { Provider } from 'react-redux'
import { store } from './store';
import { ResponsabilityTermPage } from './views/public/responsabilityTerm';
import { HelpPage } from './views/public/help';
import { DownloadAndroidPage } from './views/public/downloadAndroid';
import { ForgoutPasswordPage } from './views/modules/auth/forgoutPassword';
import { ResetPasswordPage } from './views/modules/auth/resetPassword';


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
               <App/>
            </QueryClientProvider>
        </Provider>
    </React.StrictMode>
);