import { useEffect, useRef } from 'react';
import { DropdownOptionsService } from './dropdown-options.service';
import * as S from './dropdown-options.styled';
import { DropdownOptionsType } from './dropdown-options.type';
import { InputSearchComponent } from '../inputSearch';
import { LoadingComponent } from '../loading';


export const DropdownOptionsComponent = (props: DropdownOptionsType) => {

    const dropdownOptionsService = DropdownOptionsService.getInstance();
    const dropdownRef = useRef(null);

    useEffect(() => {
        dropdownOptionsService.handleAddClickOutside({ dropdownRef, setShowOptions: props.setShowOptions });
        return () => {
            dropdownOptionsService.handleRemoveClickOutside({ dropdownRef, setShowOptions: props.setShowOptions });
        };
    }, [dropdownOptionsService, dropdownRef, props.setShowOptions]);

    return (
        <S.DropdownOptionsContainer ref={dropdownRef} width={props.width}>
            {props.onSearch &&
                <InputSearchComponent placeholder='Buscar' setSearch={props.onSearch} refetch={props.onRefetchSearch} />
            }

            {props.isLoading ?
                <LoadingComponent containerHeight={100} /> :
                <>
                    {props.options?.map(option => (
                        <S.DropdownOptionsItem
                            className={option.key === props.value ? 'active' : ''}
                            onClick={() => dropdownOptionsService.handleSelectItem({ key: option.key, onSelect: props.onSelect, setShowOptions: props.setShowOptions })}
                        >
                            {option.value}
                        </S.DropdownOptionsItem>
                    ))}
                </>
            }


        </S.DropdownOptionsContainer>
    );
};