import * as S from './styled';

import { ButtonComponent } from '../../../components/button';
import { useForm } from 'react-hook-form';
import { useForgoutPasswordHook } from './hook';
import { zodResolver } from '@hookform/resolvers/zod';
import { ForgoutPasswordSchemaType, forgoutPasswordSchema } from './type';

import logo_small_image from '../../../../assets/images/logo_small.png';


export const ForgoutPasswordPage = () => {

    const { register, handleSubmit, formState: { errors } } = useForm<ForgoutPasswordSchemaType>({ resolver: zodResolver(forgoutPasswordSchema) });
    const { onSubmit, isLoading } = useForgoutPasswordHook();

    return (
        <S.ForgoutPasswordContainer>
            <S.ForgoutPasswordContent onSubmit={handleSubmit(onSubmit)}>
                <img src={logo_small_image} alt="Logo" />

                <div className="info">
                    <span className='title'>Esqueci a senha</span>
                    <span>Insira seu e-mail cadastrado para receber o link de recuperação de senha</span>
                </div>

                <S.ForgoutPasswordFormContainer>
                    <div>
                        <input required autoComplete="username" type="text" id="email" placeholder='Entre com Email' className="b2-regular" {...register('email')} />
                        <span className='c1-regular error'>{errors?.email?.message}</span>
                    </div>
                </S.ForgoutPasswordFormContainer>

                <S.ForgoutPasswordActionContainer>
                    <ButtonComponent buttonStyle='primary' height={36} type='submit' disabled={isLoading}>
                        <span className="b3-bold">{isLoading ? "Enviando..." : "Enviar link de recuperação"}</span>
                    </ButtonComponent>
                </S.ForgoutPasswordActionContainer>
            </S.ForgoutPasswordContent>
        </S.ForgoutPasswordContainer>
    );
};
