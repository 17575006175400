import * as S from './loading.styled';
import { tailspin } from 'ldrs';
import { LoadingType } from './loading.type';

export const LoadingComponent = ({containerHeight, size} : LoadingType) => {
    tailspin.register()
    return (
        <S.LoadingContainer data-height={containerHeight}>
            <l-tailspin
                size={size || 40}
                stroke="5"
                speed="0.9"
                color="#1c7fc2"
            ></l-tailspin>
        </S.LoadingContainer>
    );
}