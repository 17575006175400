import * as S from "./dateCalendar.styled";
import "react-datepicker/dist/react-datepicker.css";

import { ptBR } from "date-fns/locale";
import { useState } from "react";
import { ButtonComponent } from "../button";
import { useDateCalendarHook } from "./date-calendar.hook";
import { DateCalendarType } from "./date-calendar.type";

import DatePicker from "react-datepicker";

import arrowleft from '../../../assets/icons/Arrowleft.svg'
import arrowright from '../../../assets/icons/arrowright.svg'

export function DateCalendarComponent({ value }: DateCalendarType) {
    const hook = useDateCalendarHook(value);

    const CustomHeader = ({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }: any) => (


        <><div className="customHeader">
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                <img src={arrowright} alt="icone de seta para esquerda " />
            </button>
            <span id="month">
                {new Date(date).toLocaleString("pt-BR", { month: "long", year: "numeric" })
                    .replace(/\bde\b/, '')}
            </span>
            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>

                <img src={arrowleft} alt="icone de seta para esquerda " />
            </button>

        </div>
            <S.Edit>

                <ButtonComponent type="submit" disabled={hook.isLoadingSubmit} width={348} buttonStyle="primary" onClick={() => {

                    hook.handleEditData();

    
                }} >
                    {hook.isLoadingSubmit ? "Salvando..." : "Editar datas de resgate"}
                </ButtonComponent>

            </S.Edit>
        </>

    )


    return (
        <S.DatePickerContainer>
                <DatePicker
                    locale={ptBR}
                    showIcon
                    icon={
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.5 5.52006V4.93673C0.5 3.32848 1.80842 2.02006 3.41667 2.02006H4V1.43673C4 1.11473 4.26075 0.853394 4.58333 0.853394C4.90592 0.853394 5.16667 1.11473 5.16667 1.43673V2.02006H9.83333V1.43673C9.83333 1.11473 10.0941 0.853394 10.4167 0.853394C10.7392 0.853394 11 1.11473 11 1.43673V2.02006H11.5833C13.1916 2.02006 14.5 3.32848 14.5 4.93673V5.52006H0.5ZM14.5 6.68673V11.9367C14.5 13.545 13.1916 14.8534 11.5833 14.8534H3.41667C1.80842 14.8534 0.5 13.545 0.5 11.9367V6.68673H14.5ZM7.5 11.9367C7.5 11.6147 7.23925 11.3534 6.91667 11.3534H4C3.67742 11.3534 3.41667 11.6147 3.41667 11.9367C3.41667 12.2587 3.67742 12.5201 4 12.5201H6.91667C7.23925 12.5201 7.5 12.2587 7.5 11.9367ZM11.5833 9.60339C11.5833 9.28139 11.3226 9.02006 11 9.02006H4C3.67742 9.02006 3.41667 9.28139 3.41667 9.60339C3.41667 9.92539 3.67742 10.1867 4 10.1867H11C11.3226 10.1867 11.5833 9.92539 11.5833 9.60339Z" fill="#1C7FC2" />
                        </svg>
                    }
                    toggleCalendarOnIconClick
                    dateFormat=" dd MMM"
                    selectsRange={true}
                    startDate={hook.dateRange?.[0]}
                    endDate={hook.dateRange?.[1]}
                    onChange={(update : any) => {
                        hook.setDateRange(update);
                    }}
                    onCalendarOpen={()=>hook.setShowCalendarPortal(true)}
                    open={hook.showCalendarPortal}
                    customInput={<S.ContainerInput />}
                    calendarContainer={S.ContainerDate}
                    withPortal={hook.showCalendarPortal}
                    renderCustomHeader={(headerProps : any) => <CustomHeader {...headerProps} />}
                    shouldCloseOnSelect={false}
                    showYearDropdown
                />
            
        </S.DatePickerContainer>


    );
}
