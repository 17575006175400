import home_icon from '../../../assets/icons/home.svg';
import home_active_icon from '../../../assets/icons/home_active.svg';
import employeers_icon from '../../../assets/icons/employeers.svg';
import employeers_active_icon from '../../../assets/icons/employeers_active.svg';
import performance_icon from '../../../assets/icons/performance.svg';
import performance_active_icon from '../../../assets/icons/performance_active.svg';
import rewards_icon from '../../../assets/icons/rewards.svg';
import rewards_active_icon from '../../../assets/icons/rewards_active.svg';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/hooks';
import { useAppDispatch } from '../../../store';
import { pageTransition, setSideBarItem } from '../../../features/general-slice';

export function useSidebarHook(){
    const [hoveredItem, setHoveredItem] = useState<number | null>(null);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const navigate = useNavigate();
    const path = window.location.pathname.split('/')[1];
    const isManager = useAppSelector(state=>state.user.isManager);
    const dispatch = useAppDispatch();

    const sidebarItems = [
        {
            name: 'Home',
            icon: home_icon,
            activeIcon: home_active_icon,
            link: '',
            paths: ['', 'historico'],
            isActive: false,
            showManager: true
        },
       
        {
            name: 'Funcionários',
            icon: employeers_icon,
            activeIcon: employeers_active_icon,
            link: 'funcionarios',
            paths: ['funcionarios'],
            isActive: false,
            showManager: false
        },
        {
            name: 'Desempenho',
            icon: performance_icon,
            activeIcon: performance_active_icon,
            link: 'desempenho',
            paths: ['desempenho'],
            isActive: false,
            showManager: true
        },
        {
            name: 'Recompensas',
            icon: rewards_icon,
            activeIcon: rewards_active_icon,
            link: 'recompensas',
            paths: ['recompensas'],
            isActive: false,
            showManager: true
        },

    ];


    function handleMouseEnter(index: number) {
        setHoveredItem(index);
    }

    function handleMouseLeave() {
        setHoveredItem(null);
    }

    function handleNavigate(link : string){
        dispatch(pageTransition(false));
        setTimeout(()=>{
            navigate(link);
            dispatch(pageTransition(true));
        }, 400);
    }

    useEffect(()=>{
        dispatch(setSideBarItem(sidebarItems.find(item=>item.paths.includes(path))!));
    }, [path])


    return {
        sidebarItems,
        hoveredItem,
        isOpenModal,
        path,
        isManager,
        handleNavigate,
        setIsOpenModal,
        handleMouseEnter,
        handleMouseLeave
    }
}
