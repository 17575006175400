import styled from "styled-components";

interface DropdownSelectProps{
    minWidth? : number,
    minHeight?: number,
    bgColor?: string,
    borderColor?: string
}

export const DropdownContainer = styled.div`
    position: relative;
`;

export const DropdownSelect = styled.label<DropdownSelectProps>`
    display: flex;
    max-height: 36px;
    padding: 17px 16px;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    cursor: pointer;
    gap: 39px;

    min-width: ${props=>props.minWidth ? `${props.minWidth}px` : 'auto'};
    min-height: ${props=>props.minHeight ? `${props.minHeight}px` : 'auto'};
    background-color: ${props=>props.bgColor ? `${props.bgColor}` : 'var(--minimal-color)'};

    border-radius: 8px;
    border: 1px solid ${props=>props.borderColor ? props.borderColor : 'var(--card-border-color)'};

    input{
        border: none;
        outline: none;
        width: 100%;
        background-color: transparent;
    }

    .arrow-up{
        rotate: 180deg;
    }

    .placeholder-style{
        color: var(--grey-color-40)
    }
`;