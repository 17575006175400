import { z } from "zod";
import { EmployeeInterface } from "../../../models/employee.interface";


export type ModalNewEmployeeType = {
    onClose: Function;
    employee?: EmployeeInterface;
    type?: "EMPLOYEE" | "MANAGER";
}

export type ModalNewEmployeeHookType = {
    onClose: Function;
    initialData?: EmployeeInterface;
}

export function getEmployeeSchema(id?: number) {
    return z.object({
        name: z.string().min(1, { message: "O campo 'Nome' é obrigatório." }),
        cpf: z.string().min(1, { message: "O campo 'CPF' é obrigatório." }),
        position: z.string().min(1, { message: "O campo 'Cargo' é obrigatório." }),
        admissionDate: z.string().min(1, { message: "O campo 'Admissão' é obrigatório." }),
        sectorId: z.string().min(1, { message: "O campo 'Setor' é obrigatório." }),
        email: z.string().min(1, { message: "O campo 'E-mail corporativo' é obrigatório." }).email('Digite um email válido'),
        password: id ? z.string().optional() : z.string().min(1, { message: "O campo 'Senha' é obrigatório." })
    });
}