import styled from "styled-components";

interface HankingCardRewardsItemProps{
    "data-active" : boolean;
}

export const HankingCardContainer = styled.div`
    display: flex;
    width: 349px;
    cursor: pointer;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    border-radius: 4px;
    background: var(--minimal-color);
`;

export const HankingCardTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    .position{
        color: var(--blue-color-100);
    }

    .left-content{
        display: flex;
        align-items: center;
        gap: 16px;
    }
`;

export const HankingCardRewards = styled.div`
    display: flex;
    gap: 8px;
    width: 100%;
    overflow-y: scroll;
    padding-bottom: 2px;
`;

export const HankingCardRewardsItem = styled.div<HankingCardRewardsItemProps>`
    display: flex;
    padding: 2px 4px;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;

    border-radius: 4px;
    background: var(--minimal);

    img{
        display: flex;
        width: 20px;
        height: 20px;
        padding: 3px;
        justify-content: center;
        align-items: center;
        gap: 10px;

        border-radius: 128px;
        background: ${props=> props['data-active'] ? 'var(--blue-color-100)' : 'var(--grey-color-40)'};
    }

    .active{
        background-color: var(--blue-color-100);
    }
`;