import * as S from './styled';

import { ButtonComponent } from '../../../components/button';
import { useForm } from 'react-hook-form';
import { useResetPasswordHook } from './hook';
import { zodResolver } from '@hookform/resolvers/zod';
import { ResetPasswordSchemaType, resetPasswordSchema } from './type';

import logo_small_image from '../../../../assets/images/logo_small.png';
import { useEffect } from 'react';


export const ResetPasswordPage = () => {

    const { register, handleSubmit, setError, formState: { errors }, watch } = useForm<ResetPasswordSchemaType>({ resolver: zodResolver(resetPasswordSchema) });
    const { onSubmit, isLoading } = useResetPasswordHook();

    const watchPassword = watch('password');
    const watchConfirmPassword = watch('confirmPassword');

    useEffect(()=>{
        if(watchPassword?.length >= 8 && watchConfirmPassword?.length >=8 && watchPassword !== watchConfirmPassword){
            setError('confirmPassword', {message : 'As senhas não são iguais'});
        }else{
            setError('confirmPassword', {message : ''});
        }
    }, [watchPassword, watchConfirmPassword])

    return (
        <S.ResetPasswordContainer>
            <S.ResetPasswordContent onSubmit={handleSubmit(onSubmit)}>
                <img src={logo_small_image} alt="Logo" />

                <S.ResetPasswordFormContainer>
                    <div>
                        <input required autoComplete="username" type="text" id="email" placeholder='Entre com Email' className="b2-regular" {...register('email')} />
                        <span className='c1-regular error'>{errors?.email?.message}</span>
                    </div>

                    <div>
                        <input required autoComplete="password" type="password" id="password" placeholder='Entre com a nova senha' className="b2-regular" {...register('password')} />
                        <span className='c1-regular error'>{errors?.password?.message}</span>
                    </div>

                    <div>
                        <input required autoComplete="confirmPassword" type="password" id="confirmPassword" placeholder='Confirme a nova senha' className="b2-regular" {...register('confirmPassword')} />
                        <span className='c1-regular error'>{errors?.confirmPassword?.message}</span>
                    </div>
                </S.ResetPasswordFormContainer>

                <S.ResetPasswordActionContainer>
                    <ButtonComponent buttonStyle='primary' height={36} type='submit' disabled={isLoading}>
                        <span className="b3-bold">{isLoading ? "Confirmando..." : "Confirmar alteração de senha"}</span>
                    </ButtonComponent>
                </S.ResetPasswordActionContainer>
            </S.ResetPasswordContent>
        </S.ResetPasswordContainer>
    );
};
