
import { useHandleRequest } from "../api/api-request.service";
import { RewardsInterface } from "../models/rewards.interface";
import { getDefaultQuery } from "../utils/query-client";

interface RewardsByCategoryNameProps{
    rewardsName: string;
    search?: string;
    isActive?: boolean;
    orderBy?: string;
    order?: string;
}

export function useRewardsService() {
    const handleRequestHook = useHandleRequest();

    async function getRewardsByCategoryName({rewardsName, search, isActive, orderBy, order}: RewardsByCategoryNameProps) {
        const filters : any[] = [{rewardTypeName: rewardsName}, {about: search}, {orderBy}, {order}];
        if(isActive !== undefined){
            filters.push({isActive});
        }
        const query = getDefaultQuery({filters});
        return handleRequestHook.handleRequest(`/rewards/${query}`, 'GET');
    }

    async function putRewards(data: RewardsInterface) {
        return handleRequestHook.handleRequest(`/rewards/${data.id}`, 'PUT', data);
    }

    async function createRewards(data: RewardsInterface): Promise<{ status: string; data: RewardsInterface }> {
        return handleRequestHook.handleRequest('/rewards', 'POST', data);
    }

    async function deleteRewards(data: RewardsInterface): Promise<{ status: string; data: RewardsInterface }> {
        return handleRequestHook.handleRequest(`/rewards/${data.id}`, 'DELETE');
    }

    async function rewardsType() {
        return handleRequestHook.handleRequest(`/rewards-type/`, 'GET');
    }

    return {
        getRewardsByCategoryName, putRewards, createRewards, rewardsType, deleteRewards
    }
}