import { DropdownOptionsClickOutsideType, DropdownOptionsSelectItemType } from "./dropdown-options.type";

export class DropdownOptionsService{
    static thFilterServiceInstance : DropdownOptionsService;

    static getInstance(){
        if(!this.thFilterServiceInstance){
            this.thFilterServiceInstance = new DropdownOptionsService();
        }
        return this.thFilterServiceInstance;
    }

    private constructor(){
        
    }

    public handleSelectItem({key, onSelect, setShowOptions} : DropdownOptionsSelectItemType){
        if(onSelect){
            onSelect(key);
        }
        setShowOptions(false);
    }

    public handleClickOutside({event, dropdownRef, setShowOptions} : DropdownOptionsClickOutsideType){
        if (dropdownRef.current && !dropdownRef.current.contains(event!.target)) {
            setShowOptions(false);
        }
    };

    public handleAddClickOutside({dropdownRef, setShowOptions} : DropdownOptionsClickOutsideType){
        document.addEventListener("mousedown", (event)=>this.handleClickOutside({event, dropdownRef, setShowOptions}));
    }

    public handleRemoveClickOutside({dropdownRef, setShowOptions} : DropdownOptionsClickOutsideType){
        document.removeEventListener("mousedown", (event)=>this.handleClickOutside({event, dropdownRef, setShowOptions}));
    }
}