import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import * as S from './app.styled';
import { useAppSelector } from './hooks/hooks';
import { RoutesPath } from './routes';
import { useAuthService } from './services/auth.service';
import { LocalToken } from './utils/local-token';
import { SidebarComponent } from './views/components/sidebar';
import { ToastComponent } from './views/components/toast';
import { TopBarComponent } from './views/components/topbar';
import { LoginPage } from './views/modules/auth/login';
import { useEffect } from 'react';
import { ResponsabilityTermPage } from './views/public/responsabilityTerm';
import { HelpPage } from './views/public/help';
import { DownloadAndroidPage } from './views/public/downloadAndroid';
import { ForgoutPasswordPage } from './views/modules/auth/forgoutPassword';
import { ResetPasswordPage } from './views/modules/auth/resetPassword';

function App() {
    const { user, general } = useAppSelector((state) => state);
    const token = LocalToken.getStoredToken();
    const authService = useAuthService();

    useEffect(() => {
        if (token && !user.user_id) {
            authService.getUserData();
        }
    }, [token, authService, user]);


    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path='/termo-de-uso' element={<ResponsabilityTermPage />} />
                    <Route path='/ajuda' element={<HelpPage />} />
                    <Route path='/android' element={<DownloadAndroidPage />} />
                    <Route path='/esqueci-a-senha' element={<ForgoutPasswordPage />} />
                    <Route path='/recuperar-senha' element={<ResetPasswordPage />} />
                    <Route path='/*' element={
                        <>
                            {!token ?
                                <LoginPage /> :
                                <S.AppContainer className="App">
                                    <SidebarComponent />
                                    <S.AppMain>
                                        <TopBarComponent />
                                        <S.AppContent showPage={general.showPage}>
                                            <RoutesPath />
                                        </S.AppContent>
                                    </S.AppMain>
                                </S.AppContainer>
                            }

                        </>
                    } />
                </Routes>
            </BrowserRouter>
            <ToastComponent message={general.toastMessage} />
        </>
    );
}

export default App;
