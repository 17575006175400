import styled from "styled-components";

interface SwitchContainerProps{
    "data-enabled" : boolean
}

export const SwitchContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const SwitchContent = styled.div<SwitchContainerProps>`
    display: flex;
    width: 35px;
    height: 20px;
    padding: 0px 4px;
    align-items: center;
    gap: 10px;

    cursor: pointer;

    border-radius: 32px;
    background: ${props=>props['data-enabled'] ? 'var(--blue-color-100)' : 'var(--grey-color-40)'};
    justify-content: ${props=>props['data-enabled'] ? 'flex-end' : 'flex-start'};

    
`;

export const SwitchCircle=  styled.div`
    width: 8px;
    height: 8px;
    flex-shrink: 0;

    border-radius: 16px;
    background-color: var(--minimal-color);
`;