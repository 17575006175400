import { useQuery } from "react-query";
import { EmployeeDataType } from "../../../../models/employee.interface";
import { responseStatus } from "../../../../api/api-request.service";
import { useManagerService } from "../../../../services/manager.service";
import { ManagerListHookType } from "./manager-list.type";
import { useEffect, useState } from "react";

export function useManagerListHook(props : ManagerListHookType){
    const employeeService = useManagerService();
    const [page, setPage] = useState(1);

    const {
        data : managerData, 
        isLoading : isLoadingManagersData,
        isRefetching : isRefetchingManagersData,
        refetch: refetchManager
    } = useQuery<EmployeeDataType>('getManagers', getAllManagers);

    async function getAllManagers(){
        const [orderBy, order] = props.selectedFilter.split('-');
        const {status, data} = await employeeService.getAllManagers({search: props.search, orderBy, order, page });
        if(status === responseStatus.SUCCESS){
            return data;
        }
    }

    useEffect(()=>{
        refetchManager();
    }, [props.reload, page, props.selectedFilter]);

    return {
        managerData,
        isLoadingManagersData,
        isRefetchingManagersData,
        refetchManager,
        page,
        setPage
    }

    
}
