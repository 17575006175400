import { z } from 'zod';
import { MouseEventHandler } from "react";


export type EmployeeConfirmModalType = {
    className? : string;
    onClose?: Function;
    userId: number;
}

export const recognitionSchema = z.object({
    reason: z.string().min(1, { message: "O campo 'Motivo do reconhecimento' é obrigatório." }),
    points: z.number().optional(),
    fkCategoryIds : z.array(z.object({
        categoryId: z.number().optional(),
        id: z.number().optional()
    })).min(1, {message : "O campo 'Tipo de reconhecimento' é obrigatório."})
});


export type RecognitionSchemaType = z.infer<typeof recognitionSchema>;