import * as S from "./listEmployee.styled"
import * as Dialog from "@radix-ui/react-dialog"

import { formatDate } from 'date-fns';
import { CardNameEmployee } from "../cardNameEmployee"
import { ListEmployeeType } from "./listEmployee.type";
import { baseUrl } from "../../../api/api";

import calendario_icon from '../../../assets/icons/calendario.svg';
import wallet_icon from '../../../assets/icons/Wallet.svg';
import profile_imagem from '../../../assets/icons/profile.svg'


import { EmployeeInfoModal } from "../employeeInfoModal";
import { DialogComponent } from "../dialog";
import { useListEmployeeHook } from "./listEmployee.hook";

export const ListEmployee = ({ user, position, type, showAmountPoinst, onReload }: ListEmployeeType) => {
    const listEmployeeHook = useListEmployeeHook();
    return (
        <S.ListEmploeeContainer>
            <DialogComponent
                className="w-100"
                title="Informações do Funcionário"
                isOpen={listEmployeeHook.isOpenModal === user.id}
                onOpen={() => listEmployeeHook.setIsOpenModal(user.id!)}
                onClose={() => listEmployeeHook.setIsOpenModal(null)}
                width={1083}
                content={<EmployeeInfoModal user={user} type={type} onClose={onReload} />}
            >
                <S.Container onClick={() => listEmployeeHook.setIsOpenModal(user.id!)}>
                    <S.Profile >
                        {position && (
                            <span className="b1-regular position">#{position}</span>
                        )}
                        <CardNameEmployee url={user.profilePic?.content} nome={user.name!} />
                    </S.Profile>

                    <S.Icons>
                        {user.recognitionCategoriesCount?.map(recognition => (
                            <S.Icon key={`reconhecimento-${recognition.category.id}`} data-active={recognition.count > 0}>
                                <div className={'image-icon'}>
                                    <img src={`${baseUrl}/${recognition.category.svg?.content}`} alt={`Icone da categoria ${recognition.category.name}`} />
                                </div>
                                <p className="b3-regular">{recognition.count}</p>
                            </S.Icon>
                        ))}

                    </S.Icons>

                    <S.Points>
                        {type === "EMPLOYEE" && <img src={wallet_icon} alt="icone de wallet" />}
                        <label className='b3-regular'>
                            {showAmountPoinst ? user.totalAccumulatedPoints : user.wallet?.ammount} pts</label>
                    </S.Points>

                    {type === "MANAGER" &&
                        <S.Points>
                            <label className='b3-regular'>
                                {user?.totalRemainingPoints} pts</label>
                        </S.Points>
                    }

                    <S.Gestor>
                        {type === 'EMPLOYEE' ?
                            <p>{user.myTeam?.userManager?.name || '--'}</p> :
                            <p>{user.managerTeam?.userSecondaryManager?.name || '--'}</p>
                        }
                    </S.Gestor>

                    <S.Data>
                        <img src={calendario_icon} alt="calendario" />
                        <span className='b3-regular'>{user.myRecognitions && user.myRecognitions.length > 0 ? formatDate(user.myRecognitions[0].createdAt!, 'dd/MM/yyyy') : 'Nenhuma'}</span>
                    </S.Data>

                </S.Container>
            </DialogComponent>
        </S.ListEmploeeContainer>
    )
}