import styled from 'styled-components';

export const SelectContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction:column;
  flex-shrink: 0; 

  .icon-image{
        width: 20px;
        height: 20px;
        padding: 4px;
        background-color: var(--blue-color-100);
        border-radius: 50%;
    }

    button {
        display:flex;
        width:335px;
        
        height:44px;
        border:none;
        background-color:var(--white-color);
        justify-content:space-between;
        align-items:center;
        border-radius:8px;
        padding: 16px 8px;

        div{
        display: flex;
        gap: 9px;
        }
    }

  
`;
export const ItemsContainer = styled.div`
    position:absolute;
    background: var(--white-color);
    width: 100%;
    border-radius: 4px;
    margin-top: 50px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    z-index: 100;

    div {
        display:flex;
        justify-content:flex-start;
        gap:8px;
        padding:8px;
        align-items:center;
        cursor: pointer;

        
    }

    :hover{
        background: var(--blue-color-20);
    }

`