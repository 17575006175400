import * as S from './tab-bar.styled';

import {TabBarType} from './tab-bar.type';

export const TabBarComponent = ({tabs, selectedTab, setSelectedTab, alert} : TabBarType)=>{

    return (
        <S.TabBarContainer>
            {tabs.map((tabName, index)=>(
                <S.TabBarItem 
                    onClick={()=>setSelectedTab(tabName)}
                    className={`b3-regular ${selectedTab===tabName ? "active" : ""}`}
                >
                    {tabName}
                    {alert && alert.index === index &&
                        <span className='alert'>{alert.value}</span>
                    }
                </S.TabBarItem>
            ))}
        </S.TabBarContainer>
    );
};