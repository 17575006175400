import { useState } from "react";

export function useCardHistoryRewardHook(){
    const [isOpenModal, setIsOpenModal] = useState<number | null>(null);

    return{
        isOpenModal,
        setIsOpenModal
    }
}
