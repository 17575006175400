import { useState } from "react";
import { GivenTab } from "../given";
import { HankingTab } from "../hanking";
import { PerformanceTabsType } from "./performance.type";
import { ConfigTab } from "../config";
import { useAppSelector } from "../../../../hooks/hooks";

export function usePerformanceHook(){
    const [selectedTab, setSelectedTab] = useState<keyof PerformanceTabsType>('Reconhecimentos atribuídos');
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [reload, setReload] = useState(false);
    const isManager = useAppSelector(state=>state.user.isManager);

    const tabs : PerformanceTabsType = {
        'Reconhecimentos atribuídos': <GivenTab reload={reload}/>,
        'Ranking de Funcionários': <HankingTab reload={reload}/>,
    };
    
    if (isManager) {
        tabs['Configurações'] = <ConfigTab reload={reload}/>;
    }
    const tabsList = Object.keys(tabs);

    function handleUpdate(){
        setReload(!reload);
    }

    
    function handleClose(){
        setIsOpenModal(false);
        handleUpdate();
    }

    return{
        selectedTab,
        tabs,
        tabsList,
        reload,
        isOpenModal,
        setIsOpenModal,
        setSelectedTab,
        handleUpdate,
        handleClose
        
    }
}
