import styled from "styled-components";

export const RewardsDate = styled.div`
    display: flex;
    height: 32px;
    padding: 4px 8px;
    justify-content: between;
    align-items: center;
    gap: 4px;
    cursor: pointer;
   

    border-radius: 4px;
    background: var(--minimal-color);
  
   
`

export const RewardsActions = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items:center;
    
   
    

`;

export const RewardsTopBar = styled.div`
width: 100%;
    display: flex;
    justify-content: space-between;
    align-items:center;
`;










