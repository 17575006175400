import { z } from "zod";
import { RecognitionCategoryInterface } from "../../../../../../models/recoginition-category";

export type ModalNewRecognitionType = {
    recognition?: RecognitionCategoryInterface | null;
    onClose: Function;
}

export type ModalNewRecognitionHookType = {
    onClose: Function;
    initialData? : RecognitionCategoryInterface | null;
}

export const recognitionCategorySchema = z.object({
    id: z.number().optional(),
    name: z.string().min(1, { message: 'O campo "Nome" é obrigatório' }),
    file: z.instanceof(FileList)
    .refine((e) => !(e.length === 0), { message: "Selecione um Icone" }).optional()
});

export type RecognitionCategorySchemaType = z.infer<typeof recognitionCategorySchema>;
