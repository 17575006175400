import { styled } from "styled-components";


export const ContainerInput = styled.input`


    display: flex;
    height: 32px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 140px !important;
    border-radius: 4px;
    cursor: pointer;
    border:none;
    outline:none;
    background:var(--minimal-color);


`

export const ContainerDate = styled.div`
display: flex;

flex-direction: column;
justify-content: center;
align-items:center;
border-radius: 16px;
background: var(--minimal-color);

width:396px;












box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.10);
backdrop-filter: blur(16px);



`

export const DatePickerContainer = styled.div`



.react-datepicker__header {
    border-bottom:none;
    background:transparent;
    align-items:center;
    position:relative;
    
    
   

    & div {
        font-size:12px;
        color:var(--grey-color-20);
        margin-top:10px;
        
    }
  
 .customHeader{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items: center;
    padding:24px;

  
   

   
 
  

    span{
     
        font-size: 20px;
      
        font-weight: 700;
       
        color: var(--grey-color-100)
       
        

       
    } button{
            background:transparent;
            border:none;
            cursor:pointer;
            
        }

 }

}

.react-datepicker__day{

    border-radius:32px !important ; 
    font-size: 16px;
  
    }
   

.react-datepicker__month{
    height:250px;
 


   
   


}

 .react-datepicker__day--range-end{
    background-color:var(--blue-color-100);
    color:var(--white-color);
 } 
 .react-datepicker__day--in-range{
    
    background-color:var(--blue-color-100);
    color:var(--white-color);
 } 

 .react-datepicker__day--today{
    border:none;
    background-color:var(--blue-color-20);
    color:var(--white-color);
    outline:none;
    
    

}

    .react-datepicker{
        padding-bottom:90px;
    }

`
export const Edit = styled.div`
 
position:absolute;
transform: translateY(350px);
padding-left:24px;


`





