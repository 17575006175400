import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    .active{
        .line, .number{
            background: var(--blue-color-100, #D2E5F3);
        }

        span{
            color: var(--black-color);
        }
    }

    .empty{
        height: 40px;
    }
    
`;

export const ProgressItem = styled.div`
    display: flex;
    width: 240px;
    align-items: flex-start;
    gap: 16px;
    
`;

export const ProgressNumber = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;

    .number{
        display: flex;
        width: 32px;
        height: 32px;
        padding: 6px 12px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        border-radius: 90px;
        background: var(--blue-color-20, #D2E5F3);
        color: var(--white-color)
    }

    .line{
        width: 2px;
        background-color: var(--blue-color-20, #D2E5F3);
        height: 100%;
    }
`;

export const ProgressStatus = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;

    span{
        color: var(--grey-color-20);
    }


    .date{
        margin-top: 8px;
        margin-bottom: 20px;
    }
`;

export const Content = styled.div`
    display: flex;
    width: 459px;
    padding: 24px 32px;
    justify-content: center;
    gap: 4px;
    flex-direction: column;

    border-radius: 8px;
    background: var(--minimal-color, #F7F7F7);

    .history{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
    
`;

export const FinalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;

    .top{
        display: flex;
        align-items: center;
        gap: 16px;
        align-self: stretch;
    }

    .top-right{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;
    }

    .top-right-details{
        display: flex;
        width: 100%;
        align-items: center;
        gap: 8px;

        .category{
            color: var(--grey-color-20);
        }

        .icon{
            display: flex;
            padding: 2px 10px;
            justify-content: center;
            align-items: center;
            gap: 4px;

            border-radius: 4px;
            background: var(--blue-color-20, #D2E5F3);

            img{
                padding: 4px;
                background-color: var(--blue-color-100);
                border-radius: 50%;
            }
        }
    }

    .bottom{
        display: flex;
        padding: 4px 16px 4px 45px;
        align-items: center;
        gap: 4px;
        align-self: stretch;

        span{
            color: var(--blue-color-100);
            font-size: 20px;
            line-height: 32px;
        }

        img{
            margin-top: 5px;
        }
    }
`