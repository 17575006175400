import * as S from './hanking.styled';

import { DropdownComponent } from '../../../components/dropdown';
import { ListEmployee } from '../../../components/listEmployee';
import { HankingCardComponent } from '../../../components/hankingCard';
import { useHankingHook } from './hanking.hook';
import { LoadingComponent } from '../../../components/loading';
import { HankingType } from './hanking.type';


export const HankingTab = ({ reload }: HankingType) => {

    const hook = useHankingHook({ reload })

    return (
        <S.HankingContainer>
            {hook.isLoadingEmployeesData || hook.isRefetchingEmployeesData ?
                <LoadingComponent containerHeight={300} /> :
                <>
                    <S.HankingFilter>
                        <span className="b2-bold">Filtros</span>
                        <DropdownComponent
                            minWidth={200}
                            placeholder='Gestor'
                            onSelect={hook.setSelectedManager}
                            value={hook.selectedManager}
                            options={hook.managerOptions}
                            onSearch={hook.setManagerSearch}
                            onRefetchSearch={hook.refetchManagersData}
                            isLoading={hook.isLoadingManagersData}
                        />
                    </S.HankingFilter>

                    {hook.employeesData?.count === 0 ?
                        <span className='empty-container'>Nenhum funcionário encontrado</span> :
                        <>

                            <S.HankingCardsContainer>
                                {hook.employeesData?.rows.slice(0, 3).map((employee, index) => (
                                    <HankingCardComponent employee={employee} position={index + 1} />
                                ))}
                            </S.HankingCardsContainer>

                            {(hook.employeesData?.rows.slice(3) && hook.employeesData.rows.slice(3).length > 0) &&
                                <S.HankingEmployeeContainer>
                                    {hook.employeesData?.rows.slice(3).map((employee, index) => (
                                        <ListEmployee position={index + 4} user={employee} type='EMPLOYEE' showAmountPoinst={true} onReload={hook.refetchEmployeesData} />
                                    ))}
                                </S.HankingEmployeeContainer>
                            }
                        </>
                    }
                </>
            }
        </S.HankingContainer>
    );
}
