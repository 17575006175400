import * as S from './topbar.styled';
import * as Popover from '@radix-ui/react-popover';

import { NotificationComponent } from '../notification';
import { useUserLoginHook } from './topbar.hook';
import { baseUrl } from '../../../api/api';


import notification_icon from  '../../../assets/icons/notification.svg';
import home_active_icon from  '../../../assets/icons/home_active.svg';
import profile_icon from '../../../assets/icons/profile.svg';


export const TopBarComponent = () => {
    const hook = useUserLoginHook()

    return (
        <S.TopBarContainer>
            <S.TopBarTitle>
                <img src={hook.sidebarItem.activeIcon} alt="Icone da pagina ativa" />
                <span className='b3-bold'>{hook.sidebarItem.name}</span>
            </S.TopBarTitle>

            <S.TopBarContent>
                <S.TopBarNotifications>
                    <Popover.Root >
                        <Popover.Trigger asChild>
                            <img src={notification_icon} alt="Icone de notificações" />
                        </Popover.Trigger>
                        <Popover.Portal >
                            
                        <S.PopoverContent>
                                <Popover.Content sideOffset={5} >
                                    <S.FieldserPopover>
                                        <p className='title b1-bold'>Notificações</p>
                                        <NotificationComponent />
                                    </S.FieldserPopover>

                                    <S.PopoverArrow>
                                        <Popover.Arrow />
                                    </S.PopoverArrow>
                            </Popover.Content>
                            </S.PopoverContent>
                        </Popover.Portal>
                    </Popover.Root>
                </S.TopBarNotifications>
                <S.TopBarProfile className='b3-regular'>

                    <><S.TopBarProfilePic>
                        <img
                            src={hook.userLoginData?.profilePic?.content ?
                                `${baseUrl}/${hook.userLoginData?.profilePic?.content}` :
                                profile_icon
                            }
                            alt="Foto de perfil do usuário"
                        />
                    </S.TopBarProfilePic><span>{hook.userLoginData?.name}</span></>

                </S.TopBarProfile>

            </S.TopBarContent>
        </S.TopBarContainer>
    );
};