import styled from "styled-components";

export const PerformanceFilterContainer = styled.div`
    display: flex;
    
    .active{
        background-color: var(--blue-color-100);
        color: var(--white-color);
    }

    span{
        display: flex;
        width: 94.333px;
        padding: 12px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        cursor: pointer;
        border: 1px solid var(--blue-color-100);

        &:hover{
            background-color: var(--blue-color-40);
        }

        &:first-child{
            border-radius: 8px 0 0 8px;
        }

        &:last-child{
            border-radius: 0 8px 8px 0;
        }
    }
    
`;