import * as S from './reward-employee-modal.styled';

import { SelectEmployeeComponent } from '../../../components/selectEmployee';
import { useRewardEmployeeModalHook } from './reward-employee-modal.hook';
import { ButtonComponent } from '../../../components/button';
import { DialogComponent } from '../../../components/dialog';
import { EmployeeConfirmModalComponent } from '../../../components/employeeConfirmModal';
import { RewardEmployeeModalType } from './reward-employee-modal.type';

export const RewardEmployeeModal = ({onClose} : RewardEmployeeModalType) => {
    const rewardEmployeeModalHook = useRewardEmployeeModalHook({onClose});
    return (
        <S.RewardEmployeeModalContainer>
            <S.RewardEmployeeModalContent>
                <SelectEmployeeComponent 
                    selectedEmployee={rewardEmployeeModalHook.selectedEmployee}
                    setSelectedEmployee={rewardEmployeeModalHook.setSelectedEmployee}
                    type='EMPLOYEE'
                    openFixed={true}
                    withoutManager={false}
                    withoutTeam={false}
                    managerId={rewardEmployeeModalHook.userAuth.isManager ? undefined : rewardEmployeeModalHook.userAuth.user_id}
                    id={'listEmployee'}
                />
            </S.RewardEmployeeModalContent>

            <DialogComponent 
                className='button-continue'
                title='Recompensar Funcionário' 
                isOpen={rewardEmployeeModalHook.isOpenModal}
                onClose={()=>rewardEmployeeModalHook.setIsOpenModal(false)}
                onOpen={()=>rewardEmployeeModalHook.setIsOpenModal(true)}
                content={
                    <EmployeeConfirmModalComponent 
                        userId={rewardEmployeeModalHook.selectedEmployee?.id!}
                        onClose={rewardEmployeeModalHook.handleClose}
                    />
                } 
                width={418}
            >
                <ButtonComponent 
                    height={36} buttonStyle='primary' 
                    disabled={!rewardEmployeeModalHook.selectedEmployee}
                    onClick={()=>rewardEmployeeModalHook.setIsOpenModal(true)}
                >
                    <span>Continuar</span>
                </ButtonComponent>
            </DialogComponent>

        </S.RewardEmployeeModalContainer>
    )
}
