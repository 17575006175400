import * as S from './employee-card.styles';

import { CardNameEmployee } from '../cardNameEmployee';
import { EmployeeCardType } from './employee.type';

import wallet_icon from '../../../assets/icons/Wallet.svg';



export const EmployeeCard = ({ onSelect, user }: EmployeeCardType) => {

    return (
        <S.EmployeeCardContainer onClick={onSelect}>
            <CardNameEmployee url={user.profilePic?.content} nome={user.name!} />
    
            <S.EmployeeCardPoints>
                <img src={wallet_icon} alt="icone de wallet" />
                <label className='b3-regular'>{user.wallet?.ammount} pts</label>
            </S.EmployeeCardPoints>
        </S.EmployeeCardContainer>
    );
};
