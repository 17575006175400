import { useState } from "react";
import { RecognitionCategoryDataType, RecognitionCategoryInterface } from "../../../../models/recoginition-category";
import { useQuery } from "react-query";
import { useRecognitionCategoryService } from "../../../../services/recognition-category.service";
import { responseStatus } from "../../../../api/api-request.service";
import { SliderType } from "../../../components/cardConfig/cardConfig.type";

export function useConfigHook() {

    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState<RecognitionCategoryInterface | null>(null);
    const recognitionCategoryService = useRecognitionCategoryService();

    const {
        data: recognitionCategoriesData,
        isLoading: isLoadingrecognitionCategoriesData,
        isRefetching: isRefetchingrecognitionCategoriesData,
        refetch: refetchrecognitionCategoriesData
    } = useQuery<RecognitionCategoryDataType>('getRecognitionCategories', getAllRecognitionCategories);

    async function getAllRecognitionCategories(){
        const {status, data} = await recognitionCategoryService.getAllRecognitionCategories();
        if(status === responseStatus.SUCCESS){
            return data;
        }
    }


    function handleClose() {
        setIsOpenModal(false);

    }

    async function onChangePointsCategory(values: SliderType){
        setIsLoadingSubmit(true);
        const categories = recognitionCategoriesData!.rows;
        const {status} = await recognitionCategoryService.bulkRecognitionCategories(categories.map((category, index)=>({...category, points : values[index].value})));
        if(status === responseStatus.SUCCESS){
            refetchrecognitionCategoriesData();
        }
        setIsLoadingSubmit(false);
    }

    function handleSelectItem(id: number){
        const category = recognitionCategoriesData?.rows.find(category=>category.id === id);
        setSelectedItem(category!);
        setIsOpenModal(true);
    }

    return {
        isOpenModal,
        selectedItem,
        recognitionCategoriesData,
        isLoading: isLoadingrecognitionCategoriesData || isRefetchingrecognitionCategoriesData,
        setIsOpenModal,
        handleClose,
        onChangePointsCategory,
        handleSelectItem,
        isLoadingSubmit
    }
}