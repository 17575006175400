import * as S from "./modalNewManager.styled";

import { ButtonComponent } from "../button";
import { useModalNewManagerHook } from "./modalNewManager.hook";
import { ModalSuccess } from "../modalSuccess";
import { baseUrl } from "../../../api/api";
import { SelectEmployeeComponent } from "../selectEmployee";
import { DialogComponent } from "../dialog";
import { SelectTeam } from "../selectTeam";
import { EmployeeInterface } from "../../../models/employee.interface";
import { ModalNewManagerType } from "./modalNewManager.type";

import InputMask from 'react-input-mask';

import profile_icon from '../../../assets/icons/profile.svg';

export const ModalNewManager = ({ onClose, manager }: ModalNewManagerType) => {
    const hook = useModalNewManagerHook({ onClose, initialData: manager });
    return (
        <S.DialogContent>
            {hook.showModalSuccess ?
                <ModalSuccess message={`Gestor ${manager?.id ? "editado" : "cadastrado"} com sucesso!`} /> :
                <>
                    <form onSubmit={hook.handleSubmit(hook.onSubmit)}>
                        <S.Container>
                            {/* <S.DadosContainer> */}
                            {/* <p className="c1-regular">Ecolha o Funcionário</p>
                                <S.InputBusca>

                                    <input
                                        onFocus={hook.handleBlurSearchEmployee}
                                        onBlur={hook.handleBlurSearchEmployee}
                                        placeholder="Nome do Funcionário" type="text" className="b2-regular"
                                        value={hook.searchEmployee || hook.selectedEmployee?.name}
                                        onChange={hook.handleSearchEmployee}
                                    />

                                    <img src={search} alt="icone de busca" />

                                    {hook.showMSelectManager &&
                                        <S.InputBuscaOptions>
                                            {hook.managerData?.rows.map(manager => (
                                                <CardNameEmployee
                                                    onClick={() => hook.handleSelectManager(manager)}
                                                    nome={manager.name}
                                                    url={manager.profilePic?.content}
                                                />
                                            ))}
                                        </S.InputBuscaOptions>
                                    }
                                </S.InputBusca>
                                <span className='c1-regular error'>{hook.errors.fkManagerIds?.message}</span>
                            </S.DadosContainer>

                            <S.Divider>
                                <div className="line"></div>
                                <span className="b2-regular">Ou</span>
                                <div className="line"></div>
                            </S.Divider> */}

                            <p className="b3-regular">Informações</p>

                            <S.NomeContairner>
                                <label htmlFor="profile-image">
                                    <img src={hook.file || profile_icon} alt="icone de adicionar Imagem" />
                                    <input
                                        className="profile-input"
                                        id="profile-image"
                                        accept='image/jpg, image/png'
                                        type="file"
                                        onChange={hook.handleProfileImageChange}
                                    />
                                </label>
                                <div className="nome">
                                    <p className="c1-regular">Nome</p>
                                    <input placeholder="Nome" type="text" className="b2-regular" {...hook.register('name')} />
                                    <span className='c1-regular error'>{hook.errors.name?.message}</span>
                                </div>
                            </S.NomeContairner>

                            <S.Row>
                                <S.DadosContainer>
                                    <p className="c1-regular">CPF</p>
                                    <InputMask
                                        mask="999.999.999-99"
                                        type="text"
                                        className="b2-regular"
                                        placeholder="CPF"
                                        {...hook.register("cpf")}
                                    />
                                    <span className="c1-regular error">
                                        {hook.errors.cpf?.message}
                                    </span>
                                </S.DadosContainer>

                                <S.DadosContainer>
                                    <p className="c1-regular">Admissão</p>
                                    <input type="date" className="b2-regular" placeholder="Data de Admissão" {...hook.register('admissionDate')} />
                                    <span className='c1-regular error'>{hook.errors.admissionDate?.message}</span>
                                </S.DadosContainer>
                            </S.Row>

                            <S.Row>
                                <S.DadosContainer>
                                    <p className="c1-regular">Setor</p>
                                    <select
                                        id="sectorId"
                                        className={`b2-regular`}
                                        defaultValue={manager?.sector?.id || ''}
                                        {...hook.register("fkSectorId")}
                                    >
                                        <option hidden disabled value=''></option>
                                        {hook.sectorsData?.rows.map(sector => (
                                            <option value={sector.id}>{sector.name}</option>
                                        ))}

                                    </select>
                                    <span className="c1-regular error">
                                        {hook.errors.fkSectorId?.message}
                                    </span>
                                </S.DadosContainer>


                                <S.DadosContainer>
                                    <p className="c1-regular">Cargo</p>
                                    <input type="text" className="b2-regular" placeholder="Cargo" {...hook.register('position')} />
                                    <span className='c1-regular error'>{hook.errors.position?.message}</span>
                                </S.DadosContainer>


                            </S.Row>

                            <S.Subtitulo>
                                <p className="b3-regular subtitulo">Acesso</p>
                            </S.Subtitulo>

                            <S.DadosContainer>
                                <p className="c1-regular">E-mail Corporativo</p>
                                <input placeholder="E-mail Corporativo" type="text" className="b2-regular" {...hook.register('email')} />
                                <span className='c1-regular error'>{hook.errors.email?.message}</span>
                            </S.DadosContainer>


                            <S.DadosContainer>
                                <p className="c1-regular">Senha</p>
                                <input placeholder="Senha" type="password" className="b2-regular" {...hook.register('password')} />
                                <span className='c1-regular error'>{hook.errors.password?.message}</span>
                            </S.DadosContainer>

                            <S.Subtitulo>
                                <p className="b3-regular subtitulo">Equipe</p>
                            </S.Subtitulo>

                            <S.SelectManager>
                                <SelectEmployeeComponent
                                    selectedEmployee={hook.selectedManager}
                                    setSelectedEmployee={hook.handleSelectManager}
                                    type="MANAGER"
                                    openTop={true}
                                    id='selectManager'
                                    withoutManager={false}
                                    withoutTeam={false}
                                />
                                <span className='c1-regular error'>{hook.errors.fkManagerIds?.message}</span>
                            </S.SelectManager>

                            <S.TeamContainer>
                                {hook.selectedTeam.map(employee => (
                                    <img
                                        className="profile-image"
                                        src={employee.profilePic?.content ? `${baseUrl}/${employee.profilePic.content}` : profile_icon}
                                        alt="Perfil do funcionário"
                                        title={employee.name}
                                    />
                                ))}
                            </S.TeamContainer>
                            <DialogComponent
                                title="Selecionar equipe"
                                isOpen={hook.isOpenModal}
                                onOpen={() => hook.setIsOpenModal(true)}
                                onClose={() => hook.setIsOpenModal(false)}
                                width={418}
                                content={<SelectTeam
                                    onSubmit={(team: EmployeeInterface[]) => hook.setSelectedTeam(team)}
                                    onClose={() => hook.setIsOpenModal(false)}
                                    selecteds={hook.fields}
                                    onAdd={hook.appendEmployee}
                                    onRemove={hook.removeEmployee}
                                    initialData={manager?.managerTeam?.employeers}
                                />}
                            >
                                <ButtonComponent buttonStyle="secondary" width={340} onClick={() => hook.setIsOpenModal(true)}>
                                    Selecionar equipe
                                </ButtonComponent>
                            </DialogComponent>
                            <span className='c1-regular error'>{hook.errors.employees?.message}</span>

                        </S.Container>

                        <ButtonComponent buttonStyle="primary" type="submit" disabled={hook.isLoadingSubmit}>
                            {hook.isLoadingSubmit ? "Salvando..." : manager?.id ? "Editar Gestor" : "Adicionar Gestor"}
                        </ButtonComponent>
                    </form>
                </>
            }
        </S.DialogContent>
    )

}