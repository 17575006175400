import { useQuery } from "react-query";
import { GeneralSettingsInterface } from "../../../../models/general-settings.interface";
import { useGeneralSettingsService } from "../../../../services/general-settings.service";
import { responseStatus } from "../../../../api/api-request.service";
import { useState } from "react";
import { useSectorService } from "../../../../services/sector.service";
import { SectorDataType } from "../../../../models/sector.interface";
import { SliderType } from "../../../components/cardConfig/cardConfig.type";

export function useCardConfigPageHook(){
    const generalSettingsService = useGeneralSettingsService();
    const sectorsService = useSectorService();
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

    const {
        data : sectorsData, 
        isLoading : isLoadingSectorsData,
        isRefetching : isRefetchingSectorsData,
        refetch: refetchsectorsData
    } = useQuery<SectorDataType>('getAllSectors', getAllSectors);

    const {
        data : generalSettingsData, 
        isLoading : isLoadingGeneralSettingsData,
        isRefetching : isRefetchingGeneralSettingsData,
        refetch: refetchGeneralSettingsData
    } = useQuery<GeneralSettingsInterface | undefined>('getUniqueGeneralSettings', getUniqueGeneralSettings);

    async function getUniqueGeneralSettings(){
        const {status, data} = await generalSettingsService.getUniqueGeneralSettings();
        if(status === responseStatus.SUCCESS){
            return data;
        }
    }

    async function getAllSectors(){
        const {status, data} = await sectorsService.getSector();
        if(status === responseStatus.SUCCESS){
            return data;
        }
    }

    async function onChangeLimitManagerRewards(values: SliderType){
        setIsLoadingSubmit(true);
        const {status, data} = await generalSettingsService.putGeneralSettings({...generalSettingsData!, limitManagerRewards : values[0].value});
        if(status === responseStatus.SUCCESS){
            refetchGeneralSettingsData();
        }
        setIsLoadingSubmit(false);
    }

    async function onChangeLimitSectors(values: SliderType){
        setIsLoadingSubmit(true);
        const sectors = sectorsData!.rows;
        const {status} = await sectorsService.bulkSector(sectors.map((sector, index)=>({...sector, limitEmployeeRewards : values[index].value})));
        if(status === responseStatus.SUCCESS){
            refetchsectorsData();
        }
        setIsLoadingSubmit(false);
    }

    return {
        isLoadingSubmit,
        generalSettingsData,
        isLoading : isLoadingGeneralSettingsData || isRefetchingGeneralSettingsData || isLoadingSectorsData || isRefetchingSectorsData,
        sectorsData,
        onChangeLimitManagerRewards,
        onChangeLimitSectors,
        
    }
}