import { useHandleRequest } from "../api/api-request.service";
import { RecognitionInterface } from "../models/recognition.interface";
import { getDefaultQuery } from "../utils/query-client";

interface GetRecognitions{
    authorId: string;
    status? : string;
    search?: string;
    userName?: string;
    page?: number;
}

interface GetRecognitionByStatusType{
    status?: string;
    page?: number;
    search?: string;
    userName?: string;
    fkSectorId?: number;
}

export function useRecognitionService() {
    const handleRequestHook = useHandleRequest();

    async function getRecognitionByStatus({status, search, userName, fkSectorId, page} : GetRecognitionByStatusType) {
        const filters = [{search}, {status}, {fkSectorId}, {userName}];
        const query = getDefaultQuery({filters, page});
        return handleRequestHook.handleRequest(`/recognitions/${query}`, 'GET');
    }

    async function getRecognitions({authorId, status, search, userName, page} : GetRecognitions) {
        const filters = [{fkAuthorId : authorId}, {fkSecondaryManagerId: authorId},{userName}, {distinctBetweenAuthorAndSecondManagerFks: true}, {status}, {search}];
        const query = getDefaultQuery({filters, page});
        return handleRequestHook.handleRequest(`/recognitions/${query}`, 'GET');
    }


    async function getRecognitionByUserId(userId: number) {
        return handleRequestHook.handleRequest(`/recognitions/by/user/${userId}`, 'GET');
    }

    async function getRecognitionByAuthorId(authorId: number) {
        return handleRequestHook.handleRequest(`/recognitions/by/author/${authorId}`, 'GET');
    }

    async function createRecognition(data: RecognitionInterface): Promise<{ status: string; data: RecognitionInterface }>{
        return handleRequestHook.handleRequest(`/recognitions`, 'POST', data);
    }

    async function approveRecognition(data: RecognitionInterface): Promise<{ status: string; data: RecognitionInterface }>{
        return handleRequestHook.handleRequest(`/recognitions/${data.id}/approve-to-employee`, 'PUT', data);
    }

    async function putRecognition(data: RecognitionInterface): Promise<{ status: string; data: RecognitionInterface }>{
        return handleRequestHook.handleRequest(`/recognitions/${data.id}`, 'PUT', data);
    }

    return {
        getRecognitionByStatus,
        getRecognitionByUserId,
        createRecognition,
        getRecognitionByAuthorId,
        putRecognition,
        approveRecognition,
        getRecognitions
    }
}