
import { baseUrl } from '../../../api/api';
import * as S from './profile.styled';

import { ProfileType } from './profile.type';
import profile_icon from '../../../assets/icons/profile.svg';


export const Profile = ({ height, width, user, type }: ProfileType) => {

    return (
        <S.ProfileContainer
            height={height}
            width={width}
        >
            <img
                src={
                    user?.profilePic?.content ?
                        `${baseUrl}/${user?.profilePic?.content}` :
                        profile_icon
                }
                alt="Imagem de perfil"
            />

            <div className="name">
                <p className="b2-regular">{user?.name}</p>
                <p className="c1-regular">{type === "EMPLOYEE" ? "Funcionário" : "Gestor"}</p>
            </div>

        </S.ProfileContainer>
    )
}