import * as S from './styled';
import logo from '../../../assets/images/logo_small.png';

export const ResponsabilityTermPage = () => {
  return (
    <>
      <S.Logo src={logo} alt="Logo" />
      <S.ResponsabilityTermContainer>
        <S.Content>
            <h1>Termos e Condições de Uso</h1>
            <br/>

            <p>Este Termo se aplica aos Usuários do Site e do Aplicativo Reconhecer da Chemtrade, de razão social
            CHEMTRADE BRASIL LTDA (CHEMTRADE) e tem as seguintes finalidades:</p>
            
            <br/>

            - Regular a relação contratual entre a CHEMTRADE os clientes que utilizam o Site e Aplicativo
            (“Usuários”);
            <br/>
            <br/>
            <p className="tab">
                – Estabelecer as regras de utilização da Plataforma Digital Reconhecer da CHEMTRADE e as
                responsabilidades da CHEMTRADE e dos Usuários, em conformidade com a legislação
                brasileira, incluindo, mas não se limitando às disposições das seguintes leis:
                <br/>
                <br/>
                <ul className='tab'>
                    <li> Código de Defesa do Consumidor;</li>
                    <li> Marco Civil da Internet;</li>
                    <li> Decreto no 8.771/2016.</li>
                </ul>
            </p>

            <br/>

            <p>Caso você não concorde com este Termo ou algum ponto não tenha ficado claro, recomendamos que
            você NÃO UTILIZE este Aplicativo. Você poderá ainda, a qualquer tempo, retornar ao site, clicar em
            Termos de Uso e reler este documento quantas vezes desejar.</p>

            <br/>

            <p className='tab'><b>
                <p>CNPJ: 03.461.875/0001-89</p>
                <p>Endereço: ES-010, S/N - KM 61,5 Barra do Riacho, Aracruz. CEP 29197-554</p>
            </b></p>

            <br/>

            <p>A <b>CHEMTRADE</b> é uma indústria que desenvolve produtos químicos para outras indústrias e através do
            <b>APP RECONHECER</b>, reconhece os seus funcionários através do atingimento de pontos de acordo com
            os pilares da empresa e os premia através de Vouchers em empresas parceiras (“Parceiros”) concedendo
            benefícios exclusivos para os colaboradores.</p>

            <br/>
            <p>O Aplicativo da CHEMTRADE é destinado, única e exclusivamente, a Usuários:</p>
            <ol className='tab'>
                <li>Com 18 (dezoito) anos de idade ou mais;</li>
                <li>Residentes no Brasil;</li>
                <li>Que possuam inscrição ativa no Cadastro de Pessoas Físicas (CPF) junto à Receita Federal;</li>
                <li>Funcionários da <b>CHEMTRADE.</b></li>
            </ol>
            <br/>

            <p>Ao concordar com este Termo, o Usuário se compromete a:</p>
            <ul className='tab'>
                <li> Não reproduzir, republicar, duplicar ou copiar qualquer conteúdo do App;</li>
                <li> Não executar e/ou exibir publicamente qualquer conteúdo;</li>
                <li> Não utilizar a Plataforma Digital de forma a danificar e/ou afetar o acesso de outros;</li>
                <li> Utilizar a Plataforma Digital respeitando as regras, leis e regulamentos relevantes do seu país de
                residência;</li>

                <li>Não realizar a mineração de dados ou qualquer outra atividade semelhante durante o uso da
                Plataforma Digital;</li>
                <li>Não utilizar este site para se envolver em qualquer forma de publicidade ou marketing
                empresarial;</li>
                <li>Fornecer dados pessoais verdadeiros, precisos, completos e atualizados;</li>
                <li>Manter a confidencialidade de suas informações fornecidas na Plataforma Digital e resguardar sua
                senha de acesso;</li>
                <li>Utilizar senha de acesso de difícil identificação, não a compartilhar com terceiros e trocá-la
                periodicamente;</li>
                <li>Manter o seu endereço de e-mail de cadastro atualizado;</li>

                <li>Informar à <b>CHEMTRADE</b> qualquer uso não autorizado de sua senha ou de sua conta, bem como
                qualquer quebra de sigilo ocorrida.
                O <b>App RECONHECER CHEMTRADE</b> disponibiliza por meio de sua Plataforma Digital informações
                sobre o atingimento de metas e créditos de reconhecimento para a compra de Vouchers e Benefícios, com
                as seguintes regras:</li>
                <li>O Usuário obterá créditos na Plataforma Digital e poderá adquirir Vouchers de produtos e serviços
                diretamente dos Parceiros;</li>
                <li>Após o fim do prazo de validade dos Vouchers, o Usuário não poderá requerer do Parceiro o
                produto ou serviço nas mesmas condições ofertada;</li>
                <li>Manter a Plataforma Digital em pleno funcionamento, garantindo o acesso ao App e ao Site;</li>
                <li>Assegurar a privacidade das informações fornecidas pelos Usuários.</li>
                <li>O <b>App CHEMTRADE</b> não se responsabiliza pelas ofertas existentes na Plataforma Digital, sendo
                realizadas no nome do Parceiro;</li>
                <li>Qualquer Usuário que desrespeitar a legislação aplicável ou as obrigações aqui previstas estará
                sujeito ao cancelamento de seu cadastro na Plataforma Digital e à perda do direito de uso do Site e
                do App, sem prejuízo de responder civil e criminalmente pelas consequências de seus atos e/ou
                omissões;</li>
            </ul>
                <br/>
                <p>O <b>App CHEMTRADE</b> se reserva no direito de investigar e excluir todos os perfis pertencentes a
                Usuários infratores da lei ou das regras aqui previstas, mantendo, em seu banco de dados, as
                informações constantes nos perfis de seus Usuários, mesmo após o cancelamento, a fim de instruir
                eventual investigação e/ou processo judicial, e a seu critério, notificar, suspender ou cancelar o
                cadastro do Usuário, a qualquer tempo, definitiva ou temporariamente, nos seguintes casos:</p>
            <ul className='tab'>
            <li>Descumprimento de qualquer disposição deste Termo;</li>
            <li>Não cumprimento de quaisquer de suas obrigações;</li>
            <li>Verificação de cadastro duplicado;</li>
            <li>Constatação de fraude ou tentativa de fraude;</li>
            <li>Fornecimento incorreto de informações solicitadas, inverídicas ou que se negar a prestar eventuais
            informações adicionais solicitadas;</li>
            <li>Tentativa de violar os sistemas ou o banco de dados.</li>
            </ul>

            <p>Os elementos ou ferramentas encontradas no Site e no Aplicativo são de titularidade ou licenciados pelo
            App, sujeitos à proteção dos direitos de propriedade intelectual de acordo com as leis brasileiras e tratados
            e convenções internacionais dos quais o Brasil seja signatário. Apenas a título exemplificativo, entendem-
            se como tais: textos, softwares, scripts, imagens gráficas, fotos, sons, músicas, vídeos, recursos interativos
            e similares, marcas, marcas de serviços, logotipos etc.</p><br/>

            <p>A Plataforma Digital pode conter links para sites ou aplicativos operados por terceiros que não são
            controlados pela CHEMTRADE, não sendo este responsável pela sua disponibilidade e/ou pelo seu
            conteúdo.</p><br/>

            <p>Este Termo será regido e interpretado de acordo com as leis brasileiras. Você, ao concordar com este
            Termo, submete-se incondicionalmente à jurisdição brasileira para a resolução de quaisquer disputas;</p><br/>
            <p>Este Termo poderá ser revisado a qualquer momento. Por isso, é fundamental que os Usuários estejam
            atentos a essas alterações;</p><br/>
            <p>A Plataforma Digital poderá alterar seus Termos sem qualquer notificação ou consentimento prévio
            necessário dos Usuários;</p><br/>
            <p>Este Termo, e quaisquer outras políticas divulgadas, estabelecem o pleno e completo acordo e
            entendimento entre a Plataforma Digital e o Usuário, superando e revogando todos e quaisquer
            entendimentos, propostas, acordos, negociações e discussões havidos anteriormente entre as partes;</p><br/>

            <p>Áreas específicas da Plataforma Digital (App ou Site) podem ser restritas ao acesso do Usuário, podendo
            se estender ainda mais essa restrição a qualquer momento e a seu exclusivo critério;
            A Plataforma Digital faz uso de cookies. Ao acessar nosso site, você concorda em usar cookies de acordo
            com nossas políticas.</p><br/>
            <p>Caso a Plataforma Digital não exerça ou faça cumprir qualquer direito ou cláusula previsto neste Termo,
            isto não representará uma renúncia do direito, tratando-se de mera liberalidade, podendo o direito ser
            exigido em outra oportunidade;</p><br/>
            <p>Na hipótese de que qualquer item, termo ou disposição deste Termo ser declarado nulo ou não aplicável,
            tal nulidade ou inexequibilidade não afetará quaisquer outros itens, termos ou disposições aqui contidos,
            os quais permanecerão em pleno vigor.</p><br/>
            <p>Para perguntas e suporte o usuário poderá entrar em contato com a CHEMTRADE por meio do endereço de e-mail tvizioli@chemtradelogistics.com.</p><br/>
            <p>As partes elegem o Foro do Município de Aracruz, no Estado do Espírito Santo, como sendo o único
            competente para dirimir quaisquer litígios e/ou demandas, que venham a envolver as partes em relação ao
            uso e acesso da Plataforma Digital através do Site e do Aplicativo.</p><br/>
            <br/>
           <p>Estes Termo é válido a partir de 22/04/2024.</p>
        </S.Content>
      </S.ResponsabilityTermContainer>
    </>
  );
};
