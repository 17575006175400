import * as S from './update-card.styled';
import coin_icon from '../../../assets/icons/coin.svg';
import { ButtonComponent } from '../button';
import { ModalSuccess } from '../modalSuccess';
import { useState } from 'react';
import { UpdateCardType } from './update-card.type';
import { baseUrl } from '../../../api/api';
import { useUpdateCardHook } from './update-card.hook';
import { DialogComponent } from '../dialog';
import { RefuseRecognitionModal } from '../refuseRecognition';
import { CardEmployeeReward } from '../cardEmployeeReward';
import { LoadingComponent } from '../loading';
import { CardRewardProgress } from '../cardRewardProgress';
import { RecognitionsStatus, RecognitionsStatusType } from '../../../utils/config';

export const UpdateCardComponent = ({ recognition, updateRecognitions, width }: UpdateCardType) => {

    const hook = useUpdateCardHook({ updateRecognitions, recognition });

    return (
        <S.UpdateCardContainer data-width={width}>
            <S.UpdateCardContent>
               
                {/* <img src={`${baseUrl}/${recognition.category?.svg?.content}`} alt={"Icone da categoria"} /> */}
                {/* <span className="b2-bold">{recognition.category?.name}</span> */}
                <img src={RecognitionsStatus[recognition.status as RecognitionsStatusType].icon} alt={"Icone da status"} />
                <span className='b2-bold'>{RecognitionsStatus[recognition.status as RecognitionsStatusType].status}</span>
                <span className="b3-regular">{recognition.user?.name}</span>

                <S.UpdateCardPoints>
                    <span className="s4-regular">+{recognition?.points}pts</span>
                    <img src={coin_icon} alt="Icone de pontuação" />
                </S.UpdateCardPoints>

                <span className="b3-regular adm-name">{recognition.author?.name}</span>

            </S.UpdateCardContent>

            <S.UpdateCardActions>
                <DialogComponent
                    isOpen={hook.isOpenModal}
                    onClose={hook.closeModal}
                    width={376}
                    title={hook.buttonConfig?.title}
                    content={hook.showModalSuccess ? 
                        <ModalSuccess message='Recompensa Confirmada' /> :
                        <CardEmployeeReward recognition={recognition} onClose={hook.closeModal} onSubmit={hook.onSubmit} isLoading={hook.isLoadingSubmit}/>
                    }
                >
                    <ButtonComponent
                        buttonStyle={'primary'}
                        onClick={()=>{hook.showModalDetails()}}
                        type='submit'
                        disabled={hook.isLoadingSubmit}
                        width={244}
                    >
                        Ver detalhes
                    </ButtonComponent>
                </DialogComponent>

            </S.UpdateCardActions>



        </S.UpdateCardContainer>

    );
}
