import { ButtonComponent } from "../button";
import { ModalSuccess } from "../modalSuccess";
import { useModalDeleteRecognitionCategoryHook } from "./modalDeleteRecognitionCategory.hook";
import { ModalDeleteRecognitionCategoryType } from "./modalDeleteRecognitionCategory.type";
import * as S from './modalDeleteRecognitionCategory.styled';

export const ModalDeletRecognitionCategory = ({ onClose, onReload, recognitionCategory }: ModalDeleteRecognitionCategoryType) => {
    const modalDeletRecognitionCategoryHook = useModalDeleteRecognitionCategoryHook({ onClose, onReload, recognitionCategory });
    return (
        <S.DialogContent>
            {modalDeletRecognitionCategoryHook.showModalSuccess ? (
                <ModalSuccess message={`Reconhecimento excluído com sucesso!`} />
            ) : (
                <>

                    <span className="c3-regular">{`Tem certeza que deseja excluir o reconhecimento`}  <b>{recognitionCategory.name}</b>?</span>
                    <S.DialogActions>
                        <ButtonComponent buttonStyle="primary" type="submit" onClick={modalDeletRecognitionCategoryHook.onSubmit}>
                            Excluir Reconhecimento
                        </ButtonComponent>

                        <ButtonComponent buttonStyle="secondary" type="submit" onClick={()=>onClose()}>
                            Cancelar
                        </ButtonComponent>
                    </S.DialogActions>
                </>
            )}
        </S.DialogContent >
    );
};
