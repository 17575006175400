export class LocalToken {
    
    static storeToken(access: string) {
        localStorage.setItem('access', access);
    }

    static clearStoredToken() {
        localStorage.removeItem('access');
    }

    static getStoredToken() {
        return localStorage.getItem('access') ?? '';
    }

}