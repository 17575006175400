import { useHandleRequest } from "../api/api-request.service";
import { GeneralSettingsInterface } from "../models/general-settings.interface";

export function useGeneralSettingsService() {
    const handleRequestHook = useHandleRequest();

    async function getUniqueGeneralSettings() : Promise<{ status: string; data: GeneralSettingsInterface }>{
        return handleRequestHook.handleRequest(`/general-settings`, 'GET');
    }

    async function putGeneralSettings(data: GeneralSettingsInterface) : Promise<{ status: string; data: GeneralSettingsInterface }>{
        return handleRequestHook.handleRequest(`/general-settings/${data.id}`, 'PUT', data);
    }

    return {
        getUniqueGeneralSettings,
        putGeneralSettings
    }
}