import styled from "styled-components";

export const InputSearch = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    .input-container{
        display: flex;
        width: 256px;
        height: 40px;
        padding: 8px;
        align-items: center;
        gap: 10px;

        border-radius: 4px;
        background: var(--minimal-color);

        input{
            height: 100%;
            width: 100%;
            border: none;
            background-color: transparent;
            outline: none;
        }

        img{
            width: 16px;
        }
    }

    .filter-container{
        
        position: relative;

        
        .active{
            background-color: var(--blue-color-20);
        }

        img{
            display: flex;
            width: 40px;
            height: 40px;
            padding: 10px;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            border-radius: 4px;
            background: var(--minimal-color);

            &:hover{
                background-color: var(--blue-color-20);
            }
        }
    }

    .selected{
        background-color: var(--blue-color-100);
        color: var(--white-color);
        border-radius: 8px;
    }

    .filter-popover{
        position: absolute;
        top: 10px;
        left: 45px;
        display: inline-flex;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        max-width: 500px;
        gap: 2px;

        border-radius: 4px;
        background: #FEFEFE;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);


        span{
            width: 100%;
            white-space: nowrap;
            padding: 10px 8px;
            border-bottom: 1px solid #E0E0E0;
            cursor: pointer;

            &:last-child{
                border-bottom: none;
            }

            &:hover{
                background-color: var(--blue-color-20);
                color: var(--blue-color-100);
                border-radius: 8px;
            }
            
        }
    }
`;