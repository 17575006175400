import { ChangeEvent, useState } from "react";
import base64 from 'base64-encode-file';
import { useMutation, useQuery } from "react-query";
import { GeneralSettingsInterface } from "../../../../../../models/general-settings.interface";
import { responseStatus } from "../../../../../../api/api-request.service";
import { useGeneralSettingsService } from "../../../../../../services/general-settings.service";
import { ModalNewRecognitionHookType, RecognitionCategorySchemaType, recognitionCategorySchema } from "./modalNewRecognition.type";
import { RecognitionCategoryInterface } from "../../../../../../models/recoginition-category";
import { useRecognitionCategoryService } from "../../../../../../services/recognition-category.service";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { queryClient } from "../../../../../../utils/query-client";

export function useModalNewRecoginition({ initialData, onClose }: ModalNewRecognitionHookType) {
    const generalSettingsService = useGeneralSettingsService();
    const recognitionCategoryService = useRecognitionCategoryService();

    const [rewardsState, setRewardsState] = useState('');
    const [file, setFile] = useState<unknown>();
    const [points, setPoints] = useState(initialData?.points || 50);
    const [enable, setEnable] = useState<boolean>(initialData?.managerCanRecognize !== undefined ? initialData.managerCanRecognize : true);
    const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    const { mutate, isLoading: isLoadingSubmit } = useMutation(
        (data: RecognitionCategorySchemaType) => handleCreateEmployee(data)
    );

    const {
        data: generalSettingsData,
    } = useQuery<GeneralSettingsInterface | undefined>('getUniqueGeneralSettings', getUniqueGeneralSettings);

    async function getUniqueGeneralSettings() {
        const { status, data } = await generalSettingsService.getUniqueGeneralSettings();
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }

    const { register, handleSubmit, formState: { errors } } = useForm<RecognitionCategorySchemaType>({
        resolver: zodResolver(initialData?.id ? recognitionCategorySchema.omit({file : true}) : recognitionCategorySchema ),
        defaultValues: {
            ...initialData,

        } as RecognitionCategorySchemaType
    });

    async function handleCreateEmployee(payload: RecognitionCategorySchemaType) {
        delete payload.file;

        let category: RecognitionCategoryInterface = {
            ...payload,
            points,
            automaticDate: null,
            isActive: true,
            managerCanRecognize: enable
        };


        if (file) {
            
            category = {
                ...category,
                svg: {
                    content: file.toString(),
                    filetype: 'svg',
                    type: 'base64'
                }
            };
        }

        const { status } = initialData?.id ?
            await recognitionCategoryService.editRecognitionCategory({ data: category }) :
            await recognitionCategoryService.createRecognitionCategory({ data: category });
        if (status === responseStatus.SUCCESS) {
            setShowModalSuccess(true);
            queryClient.refetchQueries('getRecognitionCategories');
            if (onClose) {
                setTimeout(() => {
                    onClose();
                }, 2000);
            }
        }
    }

    function onSubmit(data: RecognitionCategorySchemaType) {
        mutate(data);
    }

    async function handleImageChange(event: ChangeEvent<HTMLInputElement>) {
        const file = event.target.files?.[0];
        if (file) {
            const newFile = await base64(file);
            setFile(newFile);
        }
    };

    function onReload(){
        queryClient.refetchQueries('getRecognitionCategories');
        setShowDeleteModal(false);
        onClose();
    }

    return {
        generalSettingsData,
        rewardsState, 
        setRewardsState,
        file, 
        setFile,
        handleImageChange,
        points, 
        setPoints,
        enable, 
        setEnable,
        onSubmit,
        register, 
        handleSubmit, 
        errors,
        isLoadingSubmit,
        showModalSuccess,
        showDeleteModal,
        setShowDeleteModal,
        onReload
    }
}