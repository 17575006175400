import styled from "styled-components";

interface ContainerConfProps{
  "data-width"? : number;
}


export const ContainerConf = styled.div<ContainerConfProps>`

display: flex;
width: ${props=>props["data-width"] ? props["data-width"] : 525}px;
padding: 16px;
flex-direction: column;
align-items: flex-start;
gap: 16px;
flex-shrink: 0;
border-radius: 4px;
background: var(--minimal-color);
margin-top:16px;
`

export const Header =styled.div`
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  width: 100%;

  .subtitle{
    width: 285px;
  }

`
export const Line = styled.div`
width: 100%;
height: 1px;
background: var(--grey-color-40);


`

export const Limite=styled.div`
  width: 100%;
  display:flex;
  justify-content:space-between;
  align-items:center;

 
  .item{
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .cursor-pointer{
    cursor: pointer;
  }
  
  .name-container{
    display: flex;
    align-items: center;
    gap: 8px;

    img{
      width: 22px;
      height: 22px;
      padding: 2px;
      background-color: var(--blue-color-100);
      border-radius: 50%;
    }
  }
 
`

export const SlideRoot = styled.div`

  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 180px;
  height: 24px;
  cursor:pointer;



  .SliderTrack {
    background-color: var(--blue-color-20);
    position: relative;
    flex-grow: 1;
    border-radius: 9999px;
    height: 3px;
  
  }
  
  .SliderRange {
    position: absolute;
    background-color: var(--blue-color-100);
    border-radius: 9999px;
    height: 100%;
  
  }
  
  .SliderThumb {
    display: flex;
    width: 20px;
    height: 20px;
    background-color: var(--blue-color-100);
    box-shadow: 0 2px 10px v;
    border-radius: 10px;
    
  }
  .SliderThumb:hover {
    background-color:  var(--blue-color-100);
  }
  .SliderThumb:focus {
    outline: none;
 
  }
  

`
export const Points = styled.div`
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  width: 80px;

`

export const Button =styled.div`


display:flex;
margin-left:auto;
margin-top:16px;

`