import * as S from './employee-list.styled';

import { CardSidebarTopPageEmployee } from '../cardSidebarTopPageEmployee';
import { ListEmployee } from '../listEmployee';
import { useEmployeeListHook } from './employee-list.hook';
import { LoadingComponent } from '../loading';
import { EmployeeListType } from './employee-list.type';
import { PaginationComponent } from '../pagination';


export const EmployeeListTab = (props: EmployeeListType) => {
    const hook = useEmployeeListHook(props);
    return (
        <S.EmployeeListContainer>
            <CardSidebarTopPageEmployee role='Funcionário' />
            {hook.isLoadingEmployeesData || hook.isRefetchingEmployeesData ?
                <LoadingComponent containerHeight={200}/> :
                <>
                    {hook.employeesData?.count === 0 ?
                        <span className="c3-regular empty-employee">Nenhum funcionário encontrado</span> :
                        <>
                            {hook.employeesData?.rows.map((employee) => (
                                <ListEmployee key={`funcionario-${employee.id}`} user={employee} type="EMPLOYEE" onReload={hook.refetchEmployee}/>
                            ))}
                        </>
                    }
                    <div className='pagination-container'>
                        <PaginationComponent page={hook.page} setPage={hook.setPage} totalItems={hook.employeesData?.count || 0}/>
                    </div>
                </>
            }

        </S.EmployeeListContainer>

    );
}