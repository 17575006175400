import styled from "styled-components";

export const Success = styled.div`
    display:flex;
    flex-direction:column;
    width: 100%;
    justify-content:center;
    align-items:center;
    height:120px;
    border-radius: 12px;
    background: var(--white-color);
    gap: 24px;

    p{
        width: 100%;
        text-align: center;
    }
`;