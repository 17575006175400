import styled from "styled-components";

interface LoadingContainerProps{
    "data-height"? : number;
}

export const LoadingContainer = styled.div<LoadingContainerProps>`
    width: 100%;
    height: ${props=>props["data-height"] ? `${props["data-height"]}px` : '100%'};
    display: flex;
    align-items: center;
    justify-content: center;

`;