import styled from "styled-components";

export const DialogContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const DialogActions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
