import { setUser } from "../features/user-slice";
import { LocalToken } from "../utils/local-token";
import { LoginSchemaType } from "../views/modules/auth/login/login.type"
import { useAppDispatch } from "../store";
import { useHandleRequest } from "../api/api-request.service";

interface ResetPasswordProps{
    email: string,
    password: string,
    confirmPassword: string,
    token: string
}

export function useAuthService(){
    const dispatch = useAppDispatch();
    const handleRequestHook = useHandleRequest();
    const login = (data : LoginSchemaType)=>{
        return handleRequestHook.handleRequest('/auth/user/authenticate/dash', 'POST', data);
    }

    const getUserData = async ()=>{ 
        const token = LocalToken.getStoredToken()
        const response= await handleRequestHook.handleRequest('/auth/verify', 'POST',{access: token});
        
        dispatch(setUser(response.data.user));
    }

    const requestPasswordReset = async (payload : {email : string})=>{ 
        const response= await handleRequestHook.handleRequest(`/auth/request-password-reset`, 'POST', payload);
        return response;
    }

    const passwordReset = async (payload : ResetPasswordProps)=>{ 
        const response= await handleRequestHook.handleRequest(`/auth/password-reset?token=${payload.token}`, 'POST',payload);
        return response;
    }

    return{
        login,   
        getUserData,
        requestPasswordReset,
        passwordReset
    }
}
