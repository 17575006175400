import { ButtonComponent } from '../button';
import { ModalSuccess } from '../modalSuccess';
import { Profile } from '../profile';
import { useRefuseRecognitionHook } from './refuse-recognition.hook';
import * as S from './refuse-recognition.styled';
import { RefuseRecognitionType } from './refuse-recognition.type';

export const RefuseRecognitionModal = ({ onClose, recognition, onCloseDetails }: RefuseRecognitionType) => {
    const hook = useRefuseRecognitionHook({ recognition, onClose, onCloseDetails });
    return (
        <S.RefuseRecognitionContainer>
            {hook.showModalSuccess ?
                <ModalSuccess message='Recompensa rejeitada com sucesso' /> :
                <>
                    <Profile type='EMPLOYEE' user={recognition.user} height={178} />
                    <form onSubmit={hook.handleSubmit(hook.onSubmit)}>
                        <S.RefuseRecognitionInputContainer>
                            <label htmlFor='rejectReason' className='b3-bold'>Por que a solicitação foi recusada?</label>
                            <textarea id="rejectReason" {...hook.register('rejectReason')}></textarea>
                            <span className='c1-regular error'>{hook.errors.rejectReason?.message}</span>
                        </S.RefuseRecognitionInputContainer>

                        <S.RefuseRecognitionActions>
                            <ButtonComponent buttonStyle='primary' type='submit' disabled={hook.isLoadingSubmit}>
                                {hook.isLoadingSubmit ? 'Confimando...' : 'Confirmar'}
                            </ButtonComponent>
                            <ButtonComponent buttonStyle='secondary' type='button' onClick={onClose} disabled={hook.isLoadingSubmit}>
                                Cancelar
                            </ButtonComponent>
                        </S.RefuseRecognitionActions>
                    </form>
                </>
            }
        </S.RefuseRecognitionContainer>
    )
}