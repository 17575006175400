
import * as S from './notification.styled';
import { formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale";
import { NotificationInterface } from '../../../models/notification.interface';
import { useNotificationHook } from './notification.hook';
import { LoadingComponent } from '../loading';


export const NotificationComponent = () => {

    const notificationHook = useNotificationHook()

    return (
        <S.CardNotificationContainer>
            {notificationHook.isLoadingNotifications ?
                <LoadingComponent /> :
                <>
                    {notificationHook.notificationsData?.count === 0 ?
                        <span className='c3-regular empty-notifications'>Não há notificações</span> :
                        <>

                            {notificationHook.notificationsData?.rows.map((notification: NotificationInterface) => (
                                <S.CardNotification key={notification.id}>
                                    <div className="active">
                                        <S.TitleDescription>
                                            <label className="b2-bold">{notification.title}</label>
                                            <span>
                                                {formatDistanceToNow(notification.date, {
                                                    locale: ptBR,
                                                    addSuffix: true,
                                                })}

                                            </span>
                                        </S.TitleDescription>
                                    </div>
                                    <p className="b3-regular">{notification.content}</p>
                                </S.CardNotification>
                            ))}
                        </>
                    }
                </>
            }
        </S.CardNotificationContainer>
    );
};
