import styled from "styled-components";


export const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;

    span{
        color: var(--grey-color-200);
    }
`;

export const PaginationAction = styled.div`
    
    display: flex;
    align-items: flex-start;
    gap: 8px;

    .small {
        background-color: var(--grey-color-20);
        width: 24px;
        height: 24px;

        &:hover{
            background-color: var(--blue-color-20);
        }

        img{
            width: 18px;
            height: 18px;
        }
    }

    div{
        cursor: pointer;
        display: flex;
        height: 24px;
        padding: 5px 4px 5px 4px;
        justify-content: center;
        align-items: center;

        border-radius: 3px;
        border: 1px solid var(--grey-color-50);
    }
`;