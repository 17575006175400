import { z } from 'zod'
import { isCPFValid, isEmailValid } from '../../../../utils/validations';

export type LoginType = {
    
}


export const loginSchema = z.object({
    emailOrCpf: z.string().refine((value) => {
        return isEmailValid(value) || isCPFValid(value);
    }, {
        message: "O campo deve ser um e-mail válido ou CPF válido",
    }),
    password: z.string(),
});


export type LoginSchemaType = z.infer<typeof loginSchema>;