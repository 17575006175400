import * as S from './styled';
import { PerformanceFilterType } from "./type";
import { usePerformanceFilterHook } from "./hook";

export const PerformanceFilterComponent = ({selectedItem, setSelectedItem} : PerformanceFilterType)=>{

    const hook = usePerformanceFilterHook();
    
    return (
        <S.PerformanceFilterContainer
            className="b3-regular"
        >
            {
                hook.listItems.map(item=>(
                    <span 
                        className={item === selectedItem ? 'active' : ''}
                        onClick={()=>setSelectedItem(item)}
                    >
                        {item}
                    </span>
                ))
            }
        </S.PerformanceFilterContainer>
    );
}